import React, { FunctionComponent, useEffect, useState } from "react";
import { Container, Figure, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useParams } from "react-router-dom";
import { LearnFooter, Media, MediaBody, MediaHead } from "../navigator";
import { materialService } from "../service";

enum PortraitType {
    NORMAL = "https://resource.codemage.cn/portrait-irene-normal.svg",
    HAPPY = "https://resource.codemage.cn/portrait-irene-happy.svg",
    SAD = "https://resource.codemage.cn/portrait-irene-sad.svg",
    NAUGHTY = "https://resource.codemage.cn/portrait-irene-naughty.svg",
}

export const CertificateDetail: FunctionComponent = () => {
    const { id } = useParams();
    const [name, setName] = useState<string>();

    useEffect(() => {
        const getCourses = async () => {
            const response = await materialService().get(`/enrollment?certificate=${id}`);

            switch (response.status) {
                case 200:
                    setName(response.data.alias as string);
                    break;

                default:
                    console.warn("unexpected code path");
                    break;
            }
        }
        getCourses();

    }, [id]);

    return (
        <Container>
            <Media className="mt-5">
                <MediaHead>
                    <Figure>
                        <Figure.Image
                            className="rounded-circle me-4"
                            height={150}
                            width={150}
                            src={name === undefined ? PortraitType.SAD : PortraitType.HAPPY}
                            alt="irene"
                        />
                    </Figure>
                </MediaHead>
                <MediaBody className="ms-4 mt-1">
                    <p>
                        你好！
                    </p>
                    <p>
                        {
                            name === undefined ?
                                `很遗憾，CodeMage未曾颁发这章有效证书。`
                                :
                                `经过验证，这的确是由CodeMage颁发给${name}的有效证书。`
                        }
                    </p>
                    <p>
                        笛卡尔说过，能找出的完美数很少，要找出完美的人并非易事。请继续努力吧！
                    </p>
                </MediaBody>
            </Media>

            {
                name === undefined ?
                    undefined
                    :
                    <Image fluid className="mt-4 mb-4"
                        src={`https://certificate.codemage.cn/${id}.jpg`}
                        alt={`证书`}
                    />
            }

            <p>
                任何问题或者咨询，请关注公众号并向后台发送消息联系。让我们一起在线学习世界领先水准的计算机科学，通过知识来提高自己、改变世界！
            </p>

            <Row className="justify-content-center">
                <Image fluid
                    src={`https://resource.codemage.cn/qrcode-for-codemage.jpg`}
                    alt={`服务号`}
                />
            </Row>

            <LearnFooter />
        </Container >
    );
}