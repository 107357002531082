import jwt_decode from "jwt-decode";
import { FunctionComponent, useReducer } from "react";
import { BrowserRouter } from "react-router-dom";
import { Assistant, AssistantContext, assistantReducer, DifficultyLevel } from "./assistant";
import { LocalStorageKey } from "./constant";
import { KernelContext, kernelReducer } from "./kernel";
import { Navigator } from "./navigator";
import { JwtTokenFormat, PassportContext, passportReducer, PassportStore } from "./passport";
import { ContentRouter } from "./router";
import { Security } from "./security";

import "./app.css";

export const App: FunctionComponent = () => {
    const [passport, dispatchPassport] = useReducer(passportReducer, {
        authenticated: false,
        expired: false,
        username: "游客",
        roles: [],
        token: undefined,
    }, (store: PassportStore) => {
        // Reconstruct passport store if local storage contains valid jwt token.

        const username = localStorage.getItem(LocalStorageKey.USERNAME);
        const token = localStorage.getItem(LocalStorageKey.ACCESSTOKEN);

        if (username && token) {
            const jwt = jwt_decode<JwtTokenFormat>(token);

            if (Date.now() < jwt.exp * 1000) {
                return {
                    ...store,
                    authenticated: true,
                    username: username,
                    roles: jwt.roles.split(","),
                    token: token,
                }
            }
        }

        return store;
    });

    const [kernel, dispatchKernel] = useReducer(kernelReducer, {
        visible: false,
        stomp: undefined,
        protocol: "",
        language: "",
        version: "",
        recipe: "",
        ready: false,
    });

    const [assistant, dispatchAssistant] = useReducer(assistantReducer, {
        toast: false,
        title: "",
        subtitle: "",
        content: "",
        sound: true,
        explanation: false,
        difficulty: DifficultyLevel.NORMAL,
    });

    return (
        <PassportContext.Provider value={{ passport, dispatch: dispatchPassport }}>
            <AssistantContext.Provider value={{ assistant, dispatch: dispatchAssistant }}>
                <KernelContext.Provider value={{ kernel, dispatch: dispatchKernel }}>
                    <BrowserRouter>
                        <Security />
                        <Navigator />
                        <Assistant />
                        <ContentRouter />
                    </BrowserRouter>
                </KernelContext.Provider>
            </AssistantContext.Provider >
        </PassportContext.Provider >
    );
}