import { FunctionComponent, useContext } from "react";
import { NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineBug } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { KernelInfoRequest } from "../message";
import { PassportContext } from "../passport";
import { KernelActionType, KernelContext } from "./context";

const KernelManager: FunctionComponent = () => {
    const navigate = useNavigate()
    const { kernel, dispatch } = useContext(KernelContext);
    const { passport } = useContext(PassportContext);

    const interrupt = () => {
        console.log("Interrupt the kernel for recipe", kernel.recipe);

        const request: KernelInfoRequest = {
            id: kernel.recipe,
            recipe: kernel.recipe,
            operation: "interrupt",
        };

        kernel.stomp?.publish({
            destination: "/notebook/kernel",
            body: JSON.stringify(request),
        });
    }

    const reset = () => {
        console.log("Reset the kernel for recipe", kernel.recipe);

        dispatch({
            type: KernelActionType.RESET,
            recipe: kernel.recipe,
        });

        const request: KernelInfoRequest = {
            id: kernel.recipe,
            recipe: kernel.recipe,
            operation: "reset",
        };

        kernel.stomp?.publish({
            destination: "/notebook/kernel",
            body: JSON.stringify(request),
        });
        navigate(`/recipe/${kernel.recipe}`);
    }

    return (
        passport.authenticated && kernel.visible ?
            <NavDropdown
                title={<AiOutlineBug size={24} style={{ color: "white" }} />}
                id="nav-dropdown-ai"
            >
                <NavDropdown.Item disabled>
                    内核
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                    <p className="mt-0 mb-0">
                        语言：{kernel?.language === "python" ? "Python" : "未知"}
                    </p>
                    <p className="mt-0 mb-0">
                        版本：{kernel?.version ? kernel?.version : "未知"}
                    </p>
                    {
                        kernel?.language ?
                            <p className="mt-0 mb-0">状态：健康</p>
                            :
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="">请尝试中断或者重置并刷新页面</Tooltip>}
                            >
                                <div>
                                    <p className="mt-0 mb-0">状态：异常</p>
                                </div>
                            </OverlayTrigger>
                    }
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={interrupt}>
                    中断
                </NavDropdown.Item>
                <NavDropdown.Item onClick={reset}>
                    重置
                </NavDropdown.Item>
            </NavDropdown>
            :
            <></>
    );
}

export { KernelManager };
