import { FunctionComponent } from "react";
import { Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChapterInfo } from "../service";

interface ChapterParams {
    chapter: ChapterInfo;
};

const KnowledgeChapter: FunctionComponent<ChapterParams> = ({ chapter }) => {
    return (
        <Container>
            <h2 className="mt-4 mb-2">
                {chapter.title}
            </h2>
            <CardGroup>
                <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                    {
                        chapter.sections.sort((a, b) => {
                            return a.sequence - b.sequence;
                        }).map(knowledge =>
                            <Col key={knowledge.id}>
                                <Card key={knowledge.id} className="mt-4">
                                    <Card.Img variant="top" src={knowledge.thumbnail} style={{ objectFit: "cover" }} />
                                    <Card.Body>
                                        <Card.Title>
                                            <Link to={`/recipe/${knowledge.id}`} style={{ textDecoration: "none" }}>
                                                {knowledge.title}
                                            </Link>
                                        </Card.Title>
                                        <Card.Text>{knowledge.summary}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
            </CardGroup>
        </Container>
    )
}

export { KnowledgeChapter };
