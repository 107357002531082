import { createContext, Dispatch, Reducer } from "react";

export enum CodeActionType {
    UPDATE,
    RESET,
}

interface CodeUpdateAction {
    type: typeof CodeActionType.UPDATE;
    code: string;
}

interface CodeResetAction {
    type: typeof CodeActionType.RESET;
}

export type CodeAction = CodeUpdateAction | CodeResetAction;

export const codeReducer: Reducer<CodeStore, CodeAction> = (state, action) => {
    switch (action.type) {
        case CodeActionType.UPDATE:
            return {
                ...state,
                current: action.code,
            };

        case CodeActionType.RESET:
            return {
                ...state,
                current: state.origin,
            };
    }
}

export interface CodeStore {
    id: string;
    language: string;
    current: string;
    origin: string;
}

interface ContextParams {
    code: CodeStore;
    dispatch: Dispatch<CodeAction>
}

export const CodeContext = createContext<ContextParams>({
    code: {
        id: "",
        language: "",
        current: "",
        origin: "",
    },
    dispatch: () => undefined,
});
