import assert from "assert";
import moment from "moment";
import { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { Accordion, Card, Col, Container, Image, Row } from "react-bootstrap";
import { BsBookmark } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { LearnFooter, Media, MediaBody, MediaHead } from "../navigator";
import { PassportContext } from "../passport";
import { CircleInfo, LessonInfo, materialService, TutorInfo } from "../service";

interface LessonParams {
    lessons: LessonInfo[];
}

const LessonList: FunctionComponent<LessonParams> = ({ lessons }) => {
    return (
        <>
            <h2 className="mt-5 mb-5">
                课程
            </h2>
            {
                lessons.sort((a, b) => a.time < b.time ? -1 : 1).map(lesson =>
                    <Accordion className="mb-3" key={lesson.id}>
                        <Card key={lesson.id}>
                            <Card.Header>
                                <Row>
                                    <Col>
                                        <BsBookmark color="black" size={20} className="me-2 mb-1" />
                                        <Link to={`/lesson/${lesson.id}`} style={{ textDecoration: "none" }}>
                                            {lesson.name}
                                        </Link>
                                    </Col>
                                    <Col xs lg="2" className="text-end">{moment(lesson.time).format("YYYY/MM/DD")}</Col>
                                </Row>
                                <p >
                                    {lesson.summary}
                                </p>
                            </Card.Header>
                        </Card>
                    </Accordion>
                )
            }
        </>
    );
}

const CircleDetail: FunctionComponent = () => {
    const { passport } = useContext(PassportContext);
    const { id } = useParams();
    const [circle, setCircle] = useState<CircleInfo>();
    const [lessons, setLessons] = useState<LessonInfo[]>();
    const [tutor, setTutor] = useState<TutorInfo>();

    assert(id, "id should not be undefined");

    const getCircle = useCallback(async (id: string) => {
        const response = await materialService().get(`/circle/${id}`, {
            params: {
                user: passport.username
            }
        });

        switch (response.status) {
            case 200:
                setCircle(response.data as CircleInfo);
                break;

            default:
                console.warn("Unexpected behavior, please contact site admin");
                break;
        }
    }, [passport.username]);

    const getLessons = useCallback(async (id: string) => {
        const response = await materialService().get(`/lesson?circle=${id}`);

        switch (response.status) {
            case 200:
                setLessons(response.data as LessonInfo[]);
                break;
            case 401:
                console.log("Invalid or expired token, should have been handled by above query");
                break;
            default:
                console.warn("Unexpected behavior, please contact site admin");
                break;
        }
    }, []);

    const getTutor = useCallback(async (id: string) => {
        const response = await materialService().get(`/tutor?circle=${id}`);

        switch (response.status) {
            case 200:
                setTutor(response.data as TutorInfo);
                break;
            case 401:
                console.log("Invalid or expired token, should have been handled by above query");
                break;
            default:
                console.warn("Unexpected behavior, please contact site admin");
                break;
        }
    }, []);

    useEffect(() => {
        console.log("Loading circle", id);

        getCircle(id);
        getLessons(id);
        getTutor(id);
    }, [id, getCircle, getLessons, getTutor]);

    return (
        <Container>
            <Media className="mt-5">
                <MediaHead>
                    <Image className="rounded-circle me-4"
                        height={150}
                        width={150}
                        src={tutor?.icon}
                        alt={tutor?.name}
                    />
                </MediaHead>

                <MediaBody>
                    <h5>同学，</h5>
                    <p>
                        你好！欢迎参加《{circle?.name}》！我是这个学习小组的导师{tutor?.name}，也可以叫我{tutor?.english}。{tutor?.intro}

                    </p>
                    <p>
                        在此谨代表全体教员欢迎你的加入，希望我们能够在这个学期中共同学习一起进步！
                    </p>
                </MediaBody>
            </Media>

            <LessonList lessons={lessons || []} />

            <LearnFooter />
        </Container>
    )
}

export { CircleDetail };
