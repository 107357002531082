import { Property } from "csstype";

export const DEFAULT_SCENE_WIDTH = 960;
export const DEFAULT_SCENE_HEIGHT = 540;

export const rectSize = ((rect: number[], index: number, sceneSize: number) => {
    const num = rect.length < index ? -1 : rect[index];
    const isAbsolute = Math.abs(num) > 2;
    return { value: isAbsolute ? (num < 0 ? undefined : num) : (num * sceneSize), isAbsolute: isAbsolute, original: num };
})

export class StoryPreferenceType {
    fullScreen?: boolean;
    magnifiedRatio: number = 1;
    magnifiedRect?: { left: number, top: number, width: number, height: number };
    sceneOffset?: { left?: number, top?: number };
    defaultSceneWidth: number = DEFAULT_SCENE_WIDTH;
    defaultSceneHeight: number = DEFAULT_SCENE_HEIGHT;
    effectiveSceneWidth: number = DEFAULT_SCENE_WIDTH;
    effectiveSceneHeight: number = DEFAULT_SCENE_HEIGHT;
    effectiveSceneWidthUnit: string = "px";
    effectiveSceneHeightUnit: string = "px";
    static create = (source: StoryPreferenceType) => {
        return new StoryPreferenceType(
            source.fullScreen,
            source.magnifiedRatio,
            source.magnifiedRect,
            source.sceneOffset,
            source.defaultSceneWidth,
            source.defaultSceneHeight,
            source.effectiveSceneWidth,
            source.effectiveSceneHeight,
            source.effectiveSceneWidthUnit,
            source.effectiveSceneHeightUnit,
        );
    }
    public constructor(
        fullScreen?: boolean,
        magnifiedRatio?: number,
        magnifiedRect?: { left?: number, top?: number, width?: number, height?: number },
        sceneOffset?: { left?: number, top?: number },
        defaultSceneWidth: number = DEFAULT_SCENE_WIDTH,
        defaultSceneHeight: number = DEFAULT_SCENE_HEIGHT,
        effectiveSceneWidth: number = DEFAULT_SCENE_WIDTH,
        effectiveSceneHeight: number = DEFAULT_SCENE_HEIGHT,
        effectiveSceneWidthUnit: string = "px",
        effectiveSceneHeightUnit: string = "px",
    ) {
        this.fullScreen = fullScreen;
        this.magnifiedRatio = magnifiedRatio ?? 1;
        this.magnifiedRect = {
            left: magnifiedRect?.left ?? 0,
            top: magnifiedRect?.top ?? 0,
            width: magnifiedRect?.width ?? 1,
            height: magnifiedRect?.height ?? 1,
        };
        this.sceneOffset = { ...sceneOffset };
        this.defaultSceneWidth = defaultSceneWidth;
        this.defaultSceneHeight = defaultSceneHeight;
        this.effectiveSceneWidth = effectiveSceneWidth;
        this.effectiveSceneHeight = effectiveSceneHeight;
        this.effectiveSceneWidthUnit = effectiveSceneWidthUnit;
        this.effectiveSceneHeightUnit = effectiveSceneHeightUnit;
    }
    public calcRectSize(rect: number[]) {
        const {
            effectiveSceneWidth: sceneWidth,
            effectiveSceneHeight: sceneHeight,
            defaultSceneWidth: defaultWidth,
            defaultSceneHeight: defaultHeight
        } = this;
        const hSize = rectSize(rect, 2, sceneWidth);
        const vSize = rectSize(rect, 3, sceneHeight);
        let w = hSize.value ?? defaultWidth;
        let h = vSize.value ?? defaultHeight;
        if (hSize.isAbsolute && vSize.isAbsolute) {
            w *= sceneWidth / defaultWidth;
            h *= sceneHeight / defaultHeight;
            // if (this.fullScreen) {
            //     const sceneRatio = defaultWidth / defaultHeight;
            //     const viewRatio = window.innerWidth / window.innerHeight;
            //     if (viewRatio > sceneRatio) {
            //         h *= viewRatio / sceneRatio;
            //     } else {
            //         w *= sceneRatio / viewRatio;
            //     }
            // }
        }
        return {
            left: rectSize(rect, 0, sceneWidth).value,
            top: rectSize(rect, 1, sceneHeight).value,
            width: w,
            height: h,
        }
    };
}
export type StoryCharacterMotionType = Record<
    "always" | "mouseoverfigure" | "mouseoversubtitle",
    { index: number, duration?: number, randomOffset?: number }[]
>;
export type StoryCharactersDict = Record<string, {
    figure: Array<{
        type: string;
        source: string;
        size: number[];
        data?: string;
        offsetCenter?: number[];
    }>;
    type?: "person" | "prop";
    motion?: StoryCharacterMotionType[];
}>;
export type StorySceneType = {
    headline?: string;
    background: string;
    margin?: number[];
    /*
     * Deprecated
     */
    positions?: number[][]; // [dimension][index] = percentage
};
export type StorySceneDict = Record<string, StorySceneType>;
export type StoryPopupType = {
    rect?: number[];
    padding?: number[];
    maxWidth: number | -1;
    maxHeight: number | -1;
    direction?: "left" | "right" | "up" | "down" | "auto" | "fixed";
    textAlign?: "left" | "center" | "right";
    fontFamily?: string;
    fontColor?: Property.Color;
    fontSize?: Property.FontSize<number>;
    tailSize?: number;
    fullSize?: boolean;
    emoticon?: StoryEventActionEmoticonType;
    bgColor?: Property.Color;
    source?: string;
    bgImage?: string;
    bgImageSize?: string;
    bgImagePosition?: string;
};
export type StoryVoiceType = {
    sound: string;
};
export type StoryEventActionEmoticonType = {
    image: string,
    sound?: string,
    direction?: "left" | "right" | "up" | "down",
    transform?: string,
}
export type StoryEventActionTalkType = {
    text: string | "";
    voice?: number;
    popup?: number;
    offset?: number[];
};
export type StoryEventInteractionType = {
    start: string;
    duration: string;
    onResult?: number | number[];
    actor: number;
    figure?: number;
    position?: number[];
    transform?: string,
    motion?: number | StoryCharacterMotionType;
    content?: StoryEventActionTalkType;
    emoticon?: StoryEventActionEmoticonType;
};
export type StoryEventSceneType = {
    index: number | string | "";
    width?: Property.Width<number>;
    height?: Property.Height<number>;
    bgColor?: Property.Color;
    transform?: string | "";
};
export type StoryEventObjectType = {
    name: string;
    time: string[];
    /**
     * Deprecated
     * location of person:
     * [dimension] = coordinate index of StorySceneType.positions[dimension]
     */
    location?: number[];
    position?: number[];
    transform?: string | "";
};
export type StoryEventBoardExamType = {
    question: string;
    options: string[];
    answer: string[];
    submitButton?: string;
    colsPerRow?: number;
    fontFamily?: string;
    fontColor?: Property.Color;
    fontSize?: Property.FontSize<number>;
};
export type StoryEventBoardNotesType = {
    html: string;
};
export type StoryEventBoardPosterType = {
    rect?: number[];
    image: string;
    caption: string;
    magnify?: boolean;
    fontFamily?: string;
    fontColor?: Property.Color;
    fontSize?: Property.FontSize<number>;
    border?: Property.Border<(string & {}) | 0>;
};
export class StoryEventBoardType {
    /**
     * Rectangle of board:
     * [left, top, width, height] = percentage of the scene size
     */
    rect: number[];
    type: "exam" | "notes" | "poster";
    content: StoryEventBoardExamType | StoryEventBoardPosterType | StoryEventBoardPosterType[] | StoryEventBoardNotesType | undefined;
    voice: number | -1;
    effect: string | "";
    result: number | -1;
    constructor(board: any) {
        this.rect = [...board?.rect ?? []];
        this.type = board?.type ?? "poster";
        this.content = board?.content?.length ? [...board?.content] : { ...board?.content };
        this.voice = board?.voice ?? -1;
        this.effect = board?.effect ?? "";
        this.result = -1;
    }
    hasHotspot() {
        return (this.type === "poster") &&
            (Array.isArray(this.content) ? this.content as StoryEventBoardPosterType[] : [this.content as StoryEventBoardPosterType])
                .find(poster => poster.magnify === true);
    }
};
export class StoryEventType {
    id?: string;
    level: number;
    scene: number | string | StoryEventSceneType = "";
    duration: string = "";
    transition?: "auto" | "manual" | number;
    board?: StoryEventBoardType;
    objects?: Array<StoryEventObjectType>;
    interactions?: Array<StoryEventInteractionType>;
    constructor(event: any) {
        this.id = event.id;
        this.level = (event.level as number) ?? 0;
        this.scene = typeof (event.scene) === "undefined" || typeof (event.scene) === "number" || typeof (event.scene) === "string"
            ? event.scene : { ...event.scene };
        this.duration = event.duration ?? "";
        this.transition = event.transition ?? "manual";
        this.board = new StoryEventBoardType(event.board);
        this.objects = event.objects?.map((object: StoryEventObjectType) => {
            return { ...object };
        });
        this.interactions = event.interactions?.map((interaction: StoryEventInteractionType) => {
            return {
                ...interaction
            };
        });
    }
    public isObjectPresent(storyEventObject: StoryEventObjectType, currentMoment: number) {
        let timeStart = this.duration === "" || storyEventObject.time.length <= 0 ? 0 : toSeconds(storyEventObject.time[0]);
        let timeEnd = this.duration === "" || storyEventObject.time.length <= 1 ? currentMoment + 1 : toSeconds(storyEventObject.time[1]);
        return (timeStart <= currentMoment && currentMoment < timeEnd);
    }
    public isInteractionPresent(storyEventInteraction: StoryEventInteractionType, currentMoment: number) {
        const durationOfInteractionUnspecified = ["", "auto"].includes(storyEventInteraction.duration);
        const timeStart = storyEventInteraction.start === "" ? undefined : toSeconds(storyEventInteraction.start);
        const timeEnd = !timeStart ? undefined : timeStart + toSeconds(durationOfInteractionUnspecified ? this.duration : storyEventInteraction.duration);
        return !timeStart || !timeEnd || (timeStart <= currentMoment && currentMoment < timeEnd);
    }
    public getInteractionsPresent(currentMoment: number) {
        return ((this.duration === "") ? this.interactions ?? [] : this.interactions?.filter((interaction, /* index */) => {
            return this.isInteractionPresent(interaction, currentMoment);
        }) ?? []).sort((a, b) => toSeconds(a.start) - toSeconds(b.start));
    }
    public getExamRightResult() {
        let exam = (this.board?.content as StoryEventBoardExamType);
        if (!exam) {
            return -1;
        }
        let rightResult = 0;
        exam?.options?.forEach((x, i) => {
            if (exam.answer.includes(x)) {
                rightResult |= (2 ** i);
            }
        });
        return rightResult;
    }
};

export interface IStoryContent {
    id?: string;
    preference?: StoryPreferenceType;
    characters?: StoryCharactersDict;
    scenes?: StorySceneDict | StorySceneType[];
    popups?: Array<StoryPopupType>;
    voices: Array<StoryVoiceType>;
    events: Array<StoryEventType>;
    collection?: Array<string>;
    clone(): IStoryContent;
}

export class StoryContent implements IStoryContent {
    constructor(from?: IStoryContent) {
        let to = !from ? { id: "", collection: undefined, preference: {} as StoryPreferenceType, characters: {}, scenes: {}, popups: [], voices: [], events: [] } : {
            ...from,
            preference: from.preference,
            characters: !from.characters ? undefined : { ...from.characters },
            scenes: !from.scenes ? undefined : Array.isArray(from.scenes) ? [...from.scenes] : { ...from.scenes },
            popups: !from.popups ? undefined : from.popups?.map((popup) => {
                return { ...popup };
            }),
            voices: from.voices?.map((voice) => {
                return { ...voice };
            }),
            events: !from.events ? undefined : from.events.map((event) => new StoryEventType(event)),
            collection: from.collection as string[],
        };
        this.id = to.id;
        this.collection = to.collection;
        this.preference = to.preference;
        this.characters = to.characters;
        this.scenes = to.scenes;
        this.popups = to.popups;
        this.voices = to.voices ?? [];
        this.events = to.events ?? [];
    }
    clone(): IStoryContent {
        return new StoryContent(this);
    };
    id?: string;
    collection?: string[];
    preference?: StoryPreferenceType;
    characters?: StoryCharactersDict;
    scenes?: StorySceneDict | StorySceneType[];
    popups?: Array<StoryPopupType>;
    voices: Array<StoryVoiceType>;
    events: Array<StoryEventType>;
}

export function createStoryContent(value: any): IStoryContent {
    return new StoryContent(value);
}

export const toSeconds = (timeStr: string) => {
    let a = timeStr.split(":");
    let seconds = 0;
    if (a.length > 0) {
        seconds += parseInt(a[0]);
        if (a.length > 1) {
            seconds *= 60;
            seconds += parseInt(a[1]);
            if (a.length > 2) {
                seconds *= 60;
                seconds += parseInt(a[2]);
            }
        }
    }
    return seconds;
}

export const getPosition = (positions: number[][], positionIndex: number[], dimension: number) => {
    if (positions.length <= dimension || positionIndex.length <= dimension || positions[dimension].length < positionIndex[dimension]) {
        return 0;
    } else {
        return positions[dimension][positionIndex[dimension]];
    }
}

export const numberOf = (value: number | string) => {
    if (typeof (value) === "undefined" || typeof (value) === "number") {
        return value;
    }
    let str = value + "";
    let num = parseFloat(value);
    if (str.endsWith("%")) {
        return num / 100;
    } else {
        return num;
    }
}

export default IStoryContent;