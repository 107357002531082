import { FunctionComponent, ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FcExternal } from "react-icons/fc";

interface Params {
    children: ReactNode[];
    href?: string;
};

export const AnchorBlock: FunctionComponent<Params> = ({ children, href }) => {
    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip id="button-tooltip" >
                    点击访问外部网站
                </Tooltip>
            }
        >
            <a className="px-1" style={{ textDecoration: "none" }} href={href} target="_blank" rel="noreferrer noopener">
                <>
                    {children}
                    <FcExternal />
                </>
            </a>
        </OverlayTrigger>
    );
}
