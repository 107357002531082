import { FunctionComponent } from "react";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import remarkMath from "remark-math";
import { AnchorBlock } from "./anchorblock";
import { BlockQuote } from "./blockquote";
import { CodeBlock } from "./codeblock";
import { Heading } from "./heading";
import { ImageBlock } from "./imageblock";
import { ParagraphBlock } from "./paragraphblock";

interface Params {
    source: Array<string>;
}

export const MarkdownCell: FunctionComponent<Params> = ({ source }) => {
    return (
        <ReactMarkdown
            children={source.join("")}
            components={{
                h1: Heading,
                h2: Heading,
                h3: Heading,
                h4: Heading,
                h5: Heading,
                h6: Heading,
                a: ({ ...props }) => <AnchorBlock {...props} />,
                code: ({ ...props }) => <CodeBlock {...props} />,
                blockquote: ({ ...props }) => <BlockQuote {...props} />,
                img: ({ ...props }) => <ImageBlock {...props} />,
                p: ({ ...props }) => <ParagraphBlock {...props} />,
            }}
            remarkPlugins={[gfm, remarkMath]}
            rehypePlugins={[rehypeRaw, rehypeKatex]}
        />
    );
}
