import axios from "axios";
import { LocalStorageKey } from "../constant";

const isLocalhost = (hostname: string) =>
    hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
    ;

const resolveUAT = (servicePort: number) => {
    const hostname = window.location.hostname;
    if (isLocalhost(hostname)) {
        return undefined;
    }
    return `http://${hostname}:3368/${servicePort}`;
}

export const passportService = () => {
    const service = axios.create({
        baseURL: process.env.NODE_ENV === "production" || process.env.REACT_APP_FORCE_PRODUCTION ?
            "https://account.codemage.cn" : resolveUAT(3030) ?? "http://localhost:3030",
        validateStatus: (status: number) => (status >= 200 && status < 300) || status === 401 || status === 409,
    });

    return service;
}

export const settingService = () => {
    const token = localStorage.getItem(LocalStorageKey.ACCESSTOKEN);

    const service = axios.create({
        baseURL: process.env.NODE_ENV === "production" || process.env.REACT_APP_FORCE_PRODUCTION ?
            "https://account.codemage.cn" : resolveUAT(3030) ?? "http://localhost:3030",
        headers: {
            "Authorization": token ? `Bearer ${token}` : "",
        },
    });

    return service;
}

export const notebookService = () => {
    const token = localStorage.getItem(LocalStorageKey.ACCESSTOKEN);

    const service = axios.create({
        baseURL: process.env.NODE_ENV === "production" || process.env.REACT_APP_FORCE_PRODUCTION ?
            "https://notebook.codemage.cn/" : resolveUAT(8080) ?? "http://localhost:8080/",
        headers: {
            "Authorization": token ? `Bearer ${token}` : "",
        },
    });

    return service;
}

export const materialService = () => {
    const token = localStorage.getItem(LocalStorageKey.ACCESSTOKEN);

    const service = axios.create({
        baseURL: process.env.NODE_ENV === "production" || process.env.REACT_APP_FORCE_PRODUCTION ?
            "https://material.codemage.cn/" : resolveUAT(6060) ?? "http://localhost:6060/",
        validateStatus: (status: number) => (status >= 200 && status < 300) || (status === 401) || (status === 404),
        headers: {
            "Authorization": token ? `Bearer ${token}` : "",
        },
    });

    return service;
}
