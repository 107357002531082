import { Client } from "@stomp/stompjs";
import { createContext, Dispatch, Reducer } from "react";

export enum KernelActionType {
    STOMP,
    SHOW,
    HIDE,
    RESET,
    INFO,
}

export interface KernelStompAction {
    type: typeof KernelActionType.STOMP,
    stomp?: Client;
}

export interface KernelShowAction {
    type: typeof KernelActionType.SHOW,
}

export interface KernelHideAction {
    type: typeof KernelActionType.HIDE,
}

export interface KernelResetAction {
    type: typeof KernelActionType.RESET,
    recipe: string;
}

export interface KernelInfoAction {
    type: typeof KernelActionType.INFO;
    language: string;
    version: string;
    protocol: string;
}

export type KernelAction = KernelStompAction | KernelShowAction | KernelHideAction | KernelResetAction | KernelInfoAction;

export interface KernelStore {
    readonly visible: boolean;
    readonly stomp?: Client;
    readonly language: string;
    readonly version: string;
    readonly protocol: string;
    readonly recipe: string;
    readonly ready: boolean;
}

export const kernelReducer: Reducer<KernelStore, KernelAction> = (state, action) => {
    switch (action.type) {
        case KernelActionType.SHOW:
            return {
                ...state,
                visible: true,
            }

        case KernelActionType.HIDE:
            return {
                ...state,
                visible: false,
            }

        case KernelActionType.RESET:
            return {
                ...state,
                protocol: "",
                language: "",
                version: "",
                recipe: action.recipe,
                ready: false,
            };

        case KernelActionType.INFO:
            return {
                ...state,
                protocol: action.protocol,
                language: action.language,
                version: action.version,
                ready: true,
            };

        case KernelActionType.STOMP:
            return {
                ...state,
                stomp: action.stomp,
            }

        default:
            return state;
    }
}

export const KernelContext = createContext({} as {
    kernel: KernelStore;
    dispatch: Dispatch<KernelAction>
});
