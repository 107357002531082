import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Media, MediaBody, MediaHead } from "../navigator";
import { materialService, TutorInfo } from "../service";

interface Params {
    id?: number;
}

const LessonTutor: FunctionComponent<Params> = ({ id }) => {
    const [tutor, setTutor] = useState<TutorInfo>();

    const getTutor = useCallback(async (id?: number) => {
        const response = await materialService().get("/tutor", {
            params: {
                lesson: id,
            }
        });

        switch (response.status) {
            case 200:
                setTutor(response.data as TutorInfo);
                break;
            default:
                console.warn("unexpected behavior, please contact site admin");
                break;
        }
    }, []);

    useEffect(() => {
        if (id) {
            getTutor(id);
        }
    }, [id, getTutor]);

    return (
        <>
            <Media className="my-5">
                <MediaHead>
                    <Image className="rounded-circle me-4"
                        height={150}
                        width={150}
                        src={tutor?.icon}
                        alt={tutor?.name}
                    />
                </MediaHead>
                <MediaBody>
                    <h5>同学，</h5>
                    <p>
                        你好！欢迎加入这个班级共同学习和进步！我是你的导师{tutor?.name}，也可以叫我{tutor?.english}，非常高兴能够和你共度这段学习旅程！
                    </p>
                    <p>
                        上课时间和会议信息等可以在课程信息中找到，如果软件安装正确，直接点击链接就能加入会议。学习目标中罗列了计算思维和程序开发的知识点，请及时完成学习任务并确认完成。课程结束后两天之内，便可以在代码评审中找到需要掌握的重点知识以及导师对你的代码的评讲。少年易学老难成，一寸光阴不可轻。共勉！
                    </p>
                </MediaBody>
            </Media>
        </>
    );
}

export { LessonTutor };