import { Reducer } from "react";

export enum QueryActionType {
    START = "START",
    STOP = "STOP",
}

interface QueryStartAction {
    type: typeof QueryActionType.START;
    password: boolean;
    text: string;
}

interface QueryStopAction {
    type: typeof QueryActionType.STOP;
}

export type QueryAction = QueryStartAction | QueryStopAction;

export interface QueryStore {
    querying: boolean;
    password: boolean;
    text: string;
}

export const queryReducer: Reducer<QueryStore, QueryAction> = (state, action) => {
    switch (action.type) {
        case QueryActionType.START:
            return {
                ...state,
                querying: true,
                password: action.password,
                text: action.text,
            };

        case QueryActionType.STOP:
            return {
                ...state,
                querying: false,
            };
    }
}
