import { Client, StompSubscription } from "@stomp/stompjs";
import assert from "assert";
import { FunctionComponent, useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CodeMetadata, Notebook } from ".";
import { GraderStatus } from "../code";
import { LocalStorageKey } from "../constant";
import { KernelActionType, KernelContext } from "../kernel";
import { KernelInfoReply, KernelInfoRequest, LifeTime, Message, MessageType } from "../message";
import { Jumbotron, LearnFooter } from "../navigator";
import { PassportActionType, PassportContext, RoleName } from "../passport";
import { materialService, RecipeInfo } from "../service";
import { RecipeBreadcrumb } from "./breadcrumb";
import { AutoGrader } from "./autograder";
import { NotebookEdit } from "./notebook";
import { PrerequisiteList } from "./prerequisite";
import { RecipeProgress } from "./progress";
import { RelativeList } from "./relative";
import { GraderProgressAction, RecipeActionType, RecipeContext, RecipeLoadAction, recipeReducer } from "./context";

export const RecipeView: FunctionComponent = () => {
    const { id } = useParams();

    assert(id, "id should not be undefined");

    const { dispatch: dispatchKernel } = useContext(KernelContext);
    const { passport, dispatch: dispatchPassport } = useContext(PassportContext);

    const [disconnected, setDisconnected] = useState<boolean>(false);
    const stomp = useRef<Client>();
    const kernel = useRef<StompSubscription>();
    const control = useRef<StompSubscription>();
    const controller = useRef<AbortController>();
    const executable = useRef<boolean>(passport.roles.includes(RoleName.CODER));

    const [recipe, dispatchRecipe] = useReducer(recipeReducer, {
        id: parseInt(id),
        achieved: false,
        progresses: new Map(),
        score: 0,
    });

    const getRecipe = useCallback(async () => {
        try {
            controller.current = new AbortController();
            const response = await materialService().get(`/recipe/${id}`);

            console.log(`Loading notebook content ${id} from material service`);

            switch (response.status) {
                case 200:
                    const info = response.data as RecipeInfo;
                    var notebook = JSON.parse(info.content || "{}") as Notebook;

                    const action: RecipeLoadAction = {
                        type: RecipeActionType.LOAD,
                        id: info.id,
                        title: info.title,
                        next: info.next,
                        chapter_id: info.chapter_id,
                        chapter_title: info.chapter_title,
                        course_alias: info.course_alias,
                        course_title: info.course_title,
                        style: info.type,
                        notebook: JSON.parse(info.content || "{ 'cells' : [] }") as Notebook,
                        prerequisites: info.prerequisites,
                        relatives: info.relatives,
                    };
                    dispatchRecipe(action);

                    if (executable) {
                        // reset kernel status to yet to get ready
                        dispatchKernel({
                            type: KernelActionType.RESET,
                            recipe: id,
                        });

                        // show kernel status in the navigation bar
                        dispatchKernel({
                            type: KernelActionType.SHOW,
                        });

                        assert(stomp.current, "Stomp client should be ready");

                        // ensure a proper kernel is created for the user and the recipe
                        const request: KernelInfoRequest = {
                            id: id,
                            recipe: id,
                            operation: "create",
                        };
                        stomp.current.publish({
                            destination: "/notebook/kernel",
                            body: JSON.stringify(request),
                        });

                        console.log("Sent kernel request", request);

                        // initialize grader context store for each code cell
                        notebook.cells.forEach(cell => {
                            if (cell.cell_type === "code") {
                                const grader = (cell.metadata as CodeMetadata).grader;
                                if (grader) {
                                    const action: GraderProgressAction = {
                                        type: RecipeActionType.PROGRESS,
                                        identity: grader.identity,
                                        status: GraderStatus.UNKNOWN,
                                    };

                                    dispatchRecipe(action);
                                }
                            }
                        });
                    }
                    break;
            }
        } catch (error) {
            if (!controller.current?.abort) {
                console.log("Unexpected error found in material service request", error);
            }
        }
    }, [id, dispatchKernel]);

    useEffect(() => {
        console.log("Connecting to stomp service");

        // Recipe manages the lifecycle of a stomp client. Since the deactivate function of stomp client is async, it is not proper
        // to reuse the same stomp client for every recipe otherwise user will have to wait until stomp client deactivates. Therefore,
        // we'll need to recreate the stomp client when recipe mounts, and recycle when recipe unmounts.

        stomp.current = stomp.current ?? new Client();

        // initialize the stomp client
        stomp.current?.configure({
            brokerURL: process.env.NODE_ENV === "production" || process.env.REACT_APP_FORCE_PRODUCTION ?
                "wss://notebook.codemage.cn/stomp" : `ws://${window.location.hostname}:8080/stomp`,
            splitLargeFrames: true,
            connectHeaders: {
                "bearer": localStorage.getItem(LocalStorageKey.ACCESSTOKEN) || "",
            },
            onConnect: () => {
                console.log("Connected to stomp service");

                // store the stomp client to context store
                dispatchKernel({
                    type: KernelActionType.STOMP,
                    stomp: stomp.current,
                });

                // subscribe to get the kernel information using the same topic
                kernel.current = stomp.current?.subscribe("/user/queue/kernel", (message) => {
                    const reply = JSON.parse(message.body) as KernelInfoReply;

                    console.log("Received kernel reply:", reply);

                    dispatchKernel({
                        type: KernelActionType.INFO,
                        protocol: reply.protocol,
                        version: reply.version,
                        language: reply.language,
                    });

                    // notify the kernel to fresh lifetime for kernel
                    const lifetime: LifeTime = {
                        recipe: id,
                        lifetime: 15,
                    };
                    stomp.current?.publish({
                        destination: "/notebook/lifetime",
                        body: JSON.stringify(lifetime),
                    });
                });

                // subscribe to get the control channel using the same topic
                control.current = stomp.current?.subscribe("/user/queue/control", (message) => {
                    const reply = JSON.parse(message.body) as Message;

                    console.log("Received control reply:", reply);

                    if (reply.header.msg_type === MessageType.shutdown_reply) {
                        setDisconnected(true);
                    }
                });

                // restart grader information in context store
                dispatchRecipe({
                    type: RecipeActionType.RESTART,
                });

                // fetch recipe and setting from backend service
                getRecipe();
            },
            onDisconnect: () => {
                console.log("Disconnected from notebook service");
            },
            onStompError: (frame) => {
                console.log("Received error from notebook service:", frame.headers["message"]);

                // Logout the user as backend service may return unauthorized error. This may happen when user leaves the notebook
                // unclosed one day and returns back to the laptop the day after tomorrow. The token is invalid or expired then.

                if (frame.headers["message"].includes("AccessDeniedException")) {
                    dispatchPassport({
                        type: PassportActionType.EXPIRE,
                    });
                }
            },
        });

        // activate the stomp client
        stomp.current?.activate();

        setDisconnected(false);

        return () => {
            console.log("Disconnecting to stomp service");

            kernel.current?.unsubscribe();
            kernel.current = undefined

            control.current?.unsubscribe();
            control.current = undefined;

            // notify the kernel to be ready for release

            // disable the notification as for now

            // const lifetime: LifeTime = {
            //     recipe: id,
            //     lifetime: 1,
            // };
            // stomp.current?.publish({
            //     destination: "/notebook/lifetime",
            //     body: JSON.stringify(lifetime),
            // });

            stomp.current?.deactivate();
            stomp.current = undefined;

            // reset stomp client
            dispatchKernel({
                type: KernelActionType.STOMP,
                stomp: undefined,
            });

            // hide kernel information
            dispatchKernel({
                type: KernelActionType.HIDE,
            });
        }
    }, [dispatchKernel, dispatchRecipe, dispatchPassport, getRecipe, id]);

    return (
        <>
            <RecipeContext.Provider value={{ recipe: recipe, dispatch: dispatchRecipe }} >
                <Jumbotron>
                    <Container>
                        <RecipeBreadcrumb />
                        <h1>
                            {recipe.title}
                        </h1>
                    </Container>
                </Jumbotron>
                <Container>
                    <NotebookEdit />

                    <PrerequisiteList />

                    <RelativeList />

                    <RecipeProgress />

                    <AutoGrader />

                    <LearnFooter />

                    <Modal show={disconnected}>
                        <Modal.Header>
                            <Modal.Title>提示{disconnected}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>内核已经自动释放。点击确定重新申请内核，或者关闭网页以退出。</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="primary" onClick={() => window.location.reload()}>确定</Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </RecipeContext.Provider>
        </>
    )
}
