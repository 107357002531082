import { createContext, Dispatch, Reducer } from "react";
import { GraderInfo } from "../recipe";

export enum GraderStatus {
    UNKNOWN,
    PASSED,
    FAILED,
}

export enum ExecutionStatus {
    COMPUTE = "计算中",
    SUCCESS = "执行成功",
    ERROR = "执行失败",
    ABORTED = "执行终止",
}

export enum ExecutionActionType {
    SETUP,
    OUTPUT,
    STATUS,
    GRADE,
    RESET,
    SHOW,
}

interface OutputEntry {
    type: string;
    content: string;
    text: string;
}

interface ExecutionSetupAction {
    type: typeof ExecutionActionType.SETUP;
    payload?: GraderInfo;
}

interface ExecutionStatusAction {
    type: typeof ExecutionActionType.STATUS;
    counter: string;
    status: string;
    hint: string;
}

interface ExecutionOutputAction {
    type: typeof ExecutionActionType.OUTPUT;
    payload: OutputEntry;
}

interface ExecutionGradeSAction {
    type: typeof ExecutionActionType.GRADE;
}

interface ExecutionShowAction {
    type: typeof ExecutionActionType.SHOW;
}

interface ExecutionResetAction {
    type: typeof ExecutionActionType.RESET;
}

export type ExecutionAction = ExecutionSetupAction | ExecutionOutputAction | ExecutionStatusAction | ExecutionGradeSAction | ExecutionShowAction | ExecutionResetAction;

export interface ExecutionStore {
    counter: string;
    status: string;
    hint: string;
    outputs: OutputEntry[];
    grader?: GraderInfo;
    qualified: GraderStatus;
    show: boolean;
}

export const executionReducer: Reducer<ExecutionStore, ExecutionAction> = (state, action) => {
    switch (action.type) {
        case ExecutionActionType.SETUP:
            return {
                ...state,
                grader: action.payload,
            };

        case ExecutionActionType.OUTPUT:
            return {
                ...state,
                outputs: [...state.outputs || [], action.payload],
            };

        case ExecutionActionType.STATUS:
            return {
                ...state,
                counter: action.counter,
                status: action.status,
                hint: action.hint,
            };

        case ExecutionActionType.GRADE:
            const lines = state.outputs?.map(entry => entry.text).join("").trim() || "";

            if (state.grader && state.status === ExecutionStatus.SUCCESS) {

                // There are two graders in picture: junior grader and senior grader. For junior grader, the answer is compared with
                // the baseline letter by letter. For senior grader, student need to pass all test cases.

                switch (state.grader.type) {
                    case "senior":
                        if (state.outputs.length > 1) {
                            return {
                                ...state,
                                qualified: GraderStatus.FAILED,
                            }
                        } else {
                            const output = state.outputs[0].text;
                            const cases = JSON.parse(output) as [pass: boolean, time: number][];
                            const failed = cases.some(([pass, time]) => pass === false);

                            return {
                                ...state,
                                qualified: failed ? GraderStatus.FAILED : GraderStatus.PASSED,
                            }
                        }

                    case "junior":
                    default:
                        const answer = state.grader.answer;
                        const correct = typeof answer === "string" ? lines === answer.trim() : answer.includes(lines.trim());
                        const result = correct ? GraderStatus.PASSED : GraderStatus.FAILED;

                        return {
                            ...state,
                            qualified: result,
                        }
                }

            } else {
                return {
                    ...state,
                    qualified: GraderStatus.FAILED,
                }
            }

        case ExecutionActionType.SHOW:
            return {
                ...state,
                show: true,
            }

        case ExecutionActionType.RESET:
            return {
                ...state,
                counter: "*",
                status: ExecutionStatus.COMPUTE,
                hint: "",
                outputs: [],
                qualified: GraderStatus.UNKNOWN,
                show: false,
            };
    }
}

interface ContextParams {
    execution: ExecutionStore;
    dispatch: Dispatch<ExecutionAction>
}

export const ExecutionContext = createContext<ContextParams>({
    execution: {
        grader: undefined,
        counter: "",
        status: ExecutionStatus.COMPUTE,
        hint: "",
        outputs: [],
        qualified: GraderStatus.UNKNOWN,
        show: false,
    },
    dispatch: () => undefined,
});
