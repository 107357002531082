import { FunctionComponent, useContext, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsCheckCircle, BsExclamationTriangle, BsXCircle } from "react-icons/bs";
import { ExecutionContext } from ".";
import { ExecutionActionType, ExecutionStatus, GraderStatus } from "./executioncontext";

export const ExamStatus: FunctionComponent = () => {
    const { execution, dispatch: setExecution } = useContext(ExecutionContext);

    // If the code cell is an exam, we need to find a good timing to validate the answer. We cannot depend on idle
    // event as execution reply message may not have arrived via shell channel yet. Also we cannot reply on execution
    // reply since output may not have arrived via iopub channel yet. Therefore, we'll have to wait until both execution
    // reply and output are ready.

    useEffect(() => {
        if (execution.outputs && execution.grader && execution.status === ExecutionStatus.SUCCESS) {
            setExecution({
                type: ExecutionActionType.GRADE,
            });
        }
    }, [setExecution, execution.grader, execution.status, execution.outputs]);

    const hint = (status: GraderStatus) => {
        switch (status) {
            case GraderStatus.UNKNOWN:
                return "完成测试题才能通关";
            case GraderStatus.PASSED:
                return "答案正确 😃";
            case GraderStatus.FAILED:
                return "答案错误 😞";
        }
    }

    const glyph = (status: GraderStatus) => {
        switch (status) {
            case GraderStatus.UNKNOWN:
                return <BsExclamationTriangle color="orange" size="20" />
            case GraderStatus.PASSED:
                return <BsCheckCircle color="green" size="20" />
            case GraderStatus.FAILED:
                return <BsXCircle color="red" size="20" />
        }
    }

    return (
        !!execution.grader ?
            <OverlayTrigger
                placement={"top"}
                overlay={
                    <Tooltip id="overlay-hint">
                        {
                            hint(execution.qualified)
                        }
                    </Tooltip>
                }
            >
                <Button variant="link">
                    {
                        glyph(execution.qualified)
                    }
                </Button>
            </OverlayTrigger>
            :
            <></>
    );
}
