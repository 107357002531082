import { DEFAULT_SCENE_HEIGHT, DEFAULT_SCENE_WIDTH, StoryPreferenceType } from "./storydata";

export enum StoryPreferenceActionType {
    SET = "SET",
    RESET = "RESET",
    TOGGLE_FULLSCREEN = "TOGGLE_FULLSCREEN",
}

interface StoryPreferenceSetAction {
    type: typeof StoryPreferenceActionType.SET;
    value?: any;
}
interface StoryPreferenceResetAction {
    type: typeof StoryPreferenceActionType.RESET;
    value?: any;
}
interface StoryPreferenceToggleFullscreenAction {
    type: typeof StoryPreferenceActionType.TOGGLE_FULLSCREEN;
}

export type StoryPreferenceAction = StoryPreferenceSetAction | StoryPreferenceResetAction | StoryPreferenceToggleFullscreenAction;

export interface StoryPreferenceContextType {
    state: StoryPreferenceType
    dispatch: (action: StoryPreferenceAction) => void
}

export const adaptedSceneSize = (fullScreen: boolean, sceneWidth: number, sceneHeight: number) => {
    return {
        effectiveSceneWidth: sceneWidth,
        effectiveSceneHeight: sceneHeight,
        effectiveSceneWidthUnit: "px",
        effectiveSceneHeightUnit: "px",
    }
};
const updatedStoryPreference = (stateOriginal: StoryPreferenceType, updateValues: any) => {
    let stateUpdate = StoryPreferenceType.create(stateOriginal);
    if (updateValues !== undefined) {
        const fullScreen = updateValues.fullScreen ?? stateOriginal.fullScreen ?? false;
        const magnifiedRatio = updateValues.magnifiedRatio ?? stateOriginal.magnifiedRatio;
        const magnifiedRect = updateValues.magnifiedRect;
        const sceneOffset = updateValues.sceneOffset;
        const sceneSize = adaptedSceneSize(
            fullScreen,
            updateValues.effectiveSceneWidth ?? stateOriginal.effectiveSceneWidth ?? DEFAULT_SCENE_WIDTH,
            updateValues.effectiveSceneHeight ?? stateOriginal.effectiveSceneHeight ?? DEFAULT_SCENE_HEIGHT
        );
        Object.assign(stateUpdate, {
            fullScreen: fullScreen,
            magnifiedRatio: magnifiedRatio,
            magnifiedRect: magnifiedRect,
            sceneOffset: sceneOffset,
            ...sceneSize
        });
    }
    return stateUpdate;
};

export const initialStoryPreference = new StoryPreferenceType();
export const reducerStoryPreference = (state: StoryPreferenceType, action: StoryPreferenceAction): StoryPreferenceType => {
    switch (action.type) {
        case StoryPreferenceActionType.SET: {
            return updatedStoryPreference(state, action.value);
        }
        case StoryPreferenceActionType.RESET: {
            return updatedStoryPreference(initialStoryPreference, action.value);
        }
        case StoryPreferenceActionType.TOGGLE_FULLSCREEN: {
            const fullScreen = !(state.fullScreen ?? false);
            // const sceneSize = adaptedSceneSize(fullScreen, state.defaultSceneWidth ?? DEFAULT_SCENE_WIDTH, state.defaultSceneHeight ?? DEFAULT_SCENE_HEIGHT);
            return updatedStoryPreference(state, {
                fullScreen: fullScreen,
                // ...sceneSize,
            });
        }
        default:
            return state;
    }
}
const previewScale = 0.2;
export const initialPreviewPreference = new StoryPreferenceType(
    false,
    previewScale,
    { left: 0, top: 0, width: DEFAULT_SCENE_WIDTH, height: DEFAULT_SCENE_HEIGHT },
    { left: DEFAULT_SCENE_WIDTH * (previewScale - 1) / 2 / previewScale, top: DEFAULT_SCENE_HEIGHT * (previewScale - 1) / 2 / previewScale }
);
export const reducerPreviewPreference = (state: StoryPreferenceType, action: StoryPreferenceAction) => {
    switch (action.type) {
        case StoryPreferenceActionType.SET: {
            const fullScreen = action.value.fullScreen ?? false;
            const previewScale = action.value.magnifiedRatio;
            const sceneWidth = action.value.effectiveSceneWidth ?? (fullScreen ? 100 : (action.value.defaultSceneWidth ?? DEFAULT_SCENE_WIDTH));
            const sceneHeight = action.value.effectiveSceneHeight ?? (fullScreen ? 100 : (action.value.defaultSceneHeight ?? DEFAULT_SCENE_HEIGHT));
            const previewSize = {
                fullScreen: fullScreen,
                magnifiedRatio: previewScale,
                magnifiedRect: { left: 0, top: 0, width: sceneWidth, height: sceneHeight },
                sceneOffset: { left: sceneWidth * (previewScale - 1) / 2 / previewScale, top: sceneHeight * (previewScale - 1) / 2 / previewScale },
                effectiveSceneWidth: sceneWidth,
                effectiveSceneHeight: sceneHeight,
                effectiveSceneWidthUnit: action.value.effectiveSceneWidthUnit ?? (fullScreen ? "vw" : "px"),
                effectiveSceneHeightUnit: action.value.effectiveSceneHeightUnit ?? (fullScreen ? "vh" : "px"),
            };
            return Object.assign(state, previewSize);
        }
        default:
            return state;
    }
}
