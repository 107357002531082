import { FunctionComponent, ReactNode } from "react";

interface BlockQuoteParams {
    children: ReactNode[];
};

export const BlockQuote: FunctionComponent<BlockQuoteParams> = ({ children }) => {
    return (
        <>
            <blockquote style={{
                background: "#f9f9f9",
                borderLeft: "5px solid #ccc",
                padding: ".8em .8em .8em 1em "
            }}>
                {children}
            </blockquote>
        </>
    );
}
