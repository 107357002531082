import { createContext, Dispatch, Reducer } from "react";
import { Notebook } from ".";
import { GraderStatus } from "../code";
import { RecipeInfo, RecipeType } from "../service";

export enum RecipeActionType {
    LOAD,
    ACHIEVE,
    PROGRESS,
    RESTART,
}

export interface RecipeLoadAction {
    type: typeof RecipeActionType.LOAD;
    id: number;
    title: string;
    next?: number;
    chapter_id?: number;
    chapter_title?: string;
    course_alias?: string;
    course_title?: string;
    style: RecipeType;
    notebook: Notebook;
    prerequisites: RecipeInfo[];
    relatives: RecipeInfo[];
}

export interface RecipeAchieveAction {
    type: typeof RecipeActionType.ACHIEVE;
    achieved: boolean;
}

export interface GraderProgressAction {
    type: typeof RecipeActionType.PROGRESS;
    identity: string;
    status: GraderStatus;
}

export interface GraderRestartAction {
    type: typeof RecipeActionType.RESTART;
}

export type RecipeAction = RecipeLoadAction | RecipeAchieveAction | GraderProgressAction | GraderRestartAction;

export interface RecipeStore {
    // recipe metadata
    id?: number;
    title?: string;
    next?: number;
    chapter_id?: number;
    chapter_title?: string;
    course_alias?: string;
    course_title?: string;
    style?: RecipeType;
    notebook?: Notebook;
    prerequisites?: RecipeInfo[];
    relatives?: RecipeInfo[];

    // achievement
    achieved: boolean;

    // progress bar
    progresses: Map<string, GraderStatus>;
    score: number;
}

export const recipeReducer: Reducer<RecipeStore, RecipeAction> = (state, action) => {
    switch (action.type) {
        case RecipeActionType.LOAD:
            return {
                ...state,
                id: action.id,
                title: action.title,
                next: action.next,
                style: action.style,
                chapter_id: action.chapter_id,
                chapter_title: action.chapter_title,
                course_alias: action.course_alias,
                course_title: action.course_title,
                notebook: action.notebook,
                prerequisites: action.prerequisites,
                relatives: action.relatives,
            };

        case RecipeActionType.ACHIEVE:
            return {
                ...state,
                achieved: action.achieved,
            }

        case RecipeActionType.RESTART:
            return {
                ...state,
                progresses: new Map(),
                score: 0,
            };

        case RecipeActionType.PROGRESS:
            const results = new Map(state.progresses);
            results.set(action.identity, action.status);

            const passed = Array.from(results.values()).filter(value => value === GraderStatus.PASSED).length;
            const total = results.size;
            const score = Math.round(passed / total * 100);

            return {
                ...state,
                progresses: results,
                score: score,
            };

        default:
            return state;
    }
}

export const RecipeContext = createContext({} as {
    recipe: RecipeStore;
    dispatch: Dispatch<RecipeAction>
});