import assert from "assert";
import React, { forwardRef, FunctionComponent, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { materialService } from "../service";
import { ShowMode, StoryShow } from "./storyshow";
// import { Client, IFrame, IMessage, StompSubscription } from "@stomp/stompjs";
// import React, { FunctionComponent, useEffect, useReducer, useRef, useState } from "react";
// import { Breadcrumb, Button, Container, Jumbotron } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { LinkContainer } from "react-router-bootstrap";
// import { useHistory, useLocation, useParams } from "react-router-dom";
// import { LocalStorageKey } from "../constant";
// import { KernelInfoReply, KernelInfoRequest } from "../message";
// import { LearnFooter } from "../navigator";
// import { materialService, RecipeInfo, RecipeType, settingService } from "../service";
// import { AppStore, GraderActionType, GraderResetAction, GraderUpdateAction, KernelActionType, KernelHideAction, KernelInfoAction, KernelResetAction, KernelShowAction, KernelStompAction, PassportAction, PassportActionType } from "../store";
// import { UserSetting } from "../store/setting";
// import { CodeCell } from "./codecell";
// import { ConfirmButton } from "./confirm";
// import { GraderStatus } from "./grader";
// import { CodeMetadata, Notebook, RawMetadata } from "./index";
// import { MarkdownCell } from "./markdowncell";
// import { PrerequisiteList } from "./prerequisite";
// import { RecipeProgress } from "./progress";
// import { RelativeList } from "./relative";

enum TabType {
    SHOW = "SHOW",
    EDIT = "EDIT",
    JSON = "JSON",
}

const TextForm = forwardRef((props: {
    inputs: { textString: string },
    onSubmit: (inputs: { textString: string }) => void,
    className?: string,
}, ref) => {
    useImperativeHandle(ref, () => ({
        getInputs(): { textString: string } {
            return inputs;
        }
    }));

    const [inputs, setInputs] = useState({ textString: "" });
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        props.onSubmit({ ...inputs });
    }
    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.persist();
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
    }

    useEffect(() => {
        setInputs(inputs => ({ ...props.inputs }));
    }, [props.inputs]);

    return (
        <form className={props.className} onSubmit={handleSubmit}>
            <textarea name="textString" value={inputs.textString} onChange={handleTextAreaChange} />
            <Button type="submit">Submit</Button>
        </form>
    );
})

// interface RouteParams {
//     id: string
// }

// enum RecipeActionType {
//     LOAD = "LOAD",
// }

// interface RecipeLoadAction {
//     type: typeof RecipeActionType.LOAD;
//     id: number;
//     title: string;
//     next?: number;
//     chapter_id?: number;
//     chapter_title?: string;
//     course_id?: number;
//     course_title?: string;
//     style: RecipeType;
//     notebook: Notebook;
//     prerequisites: RecipeInfo[];
//     relatives: RecipeInfo[];
// }

// type RecipeAction = RecipeLoadAction;

// interface RecipeStore {
//     readonly id: number;
//     readonly title?: string;
//     readonly next?: number;
//     readonly chapter_id?: number;
//     readonly chapter_title?: string;
//     readonly course_id?: number;
//     readonly course_title?: string;
//     readonly style?: RecipeType;
//     readonly notebook?: Notebook;
//     readonly prerequisites?: RecipeInfo[];
//     readonly relatives?: RecipeInfo[];
// }

// const initialStore: RecipeStore = {
//     id: 0,
// }

// const recipeReducer: React.Reducer<RecipeStore, RecipeAction> = (state, action) => {
//     switch (action.type) {
//         case RecipeActionType.LOAD:
//             return {
//                 ...state,
//                 id: action.id,
//                 title: action.title,
//                 next: action.next,
//                 style: action.style,
//                 chapter_id: action.chapter_id,
//                 chapter_title: action.chapter_title,
//                 course_id: action.course_id,
//                 course_title: action.course_title,
//                 notebook: action.notebook,
//                 prerequisites: action.prerequisites,
//                 relatives: action.relatives,
//             };
//     }
// }

// interface NotebookParams {
//     recipe: RecipeStore;
// }

// const stomp = new Client();

// var kernel: StompSubscription | undefined;

// export const NotebookEdit: FunctionComponent<NotebookParams> = (props) => {
//     const recipe = props.recipe;

//     console.log("Rendering notebook", recipe.id);

//     return (
//         <>
//             {
//                 recipe.notebook?.cells.map((cell, index) => {
//                     const key = `notebook-${recipe.id}-cell-${index}`;

//                     switch (cell.cell_type) {
//                         case "code":
//                             return <CodeCell
//                                 key={key}
//                                 source={cell.source}
//                                 stomp={stomp}
//                                 recipe={`${recipe.id}`}
//                                 metadata={cell.metadata as CodeMetadata}
//                             />;
//                         case "markdown":
//                             return <MarkdownCell
//                                 key={key}
//                                 source={cell.source}
//                             />;
//                         case "raw":
//                             return <RawCell
//                                 key={key}
//                                 source={cell.source}
//                                 metadata={cell.metadata as RawMetadata}
//                             />;
//                         default:
//                             return undefined;
//                     }
//                 })
//             }
//         </>
//     );
// }

// const RecipeBreadcrumb: FunctionComponent<NotebookParams> = (props) => {
//     const recipe = props.recipe;
//     const visible = recipe.course_id && recipe.style === RecipeType.COURSE;

//     return (
//         <>
//             {
//                 visible &&
//                 <Breadcrumb listProps={{ style: { paddingLeft: 0, paddingTop: 0, paddingBottom: 20 } }}>
//                     <LinkContainer to={`/course/${recipe.course_id}`} exact>
//                         <Breadcrumb.Item linkProps={{ style: { textDecoration: 'none' } }}>
//                             {recipe.course_title}
//                         </Breadcrumb.Item>
//                     </LinkContainer>

//                     <LinkContainer to={`/course/${recipe.course_id}#chapter-${recipe.chapter_id}`} exact>
//                         <Breadcrumb.Item linkProps={{ style: { textDecoration: 'none' } }} >
//                             {recipe.chapter_title}
//                         </Breadcrumb.Item>
//                     </LinkContainer>
//                 </Breadcrumb>
//             }
//         </>
//     );
// }

// const RecipeAction: FunctionComponent<NotebookParams> = (props) => {
//     const location = useLocation();
//     const query = new URLSearchParams(location.search);

//     const save = () => {
//         console.log("save the recipe to jupyter notebook");

//         const content = JSON.stringify(props.recipe.notebook, undefined, 4);
//         const file = new Blob([content], { type: "text/plain;charset=utf-8" });
//         const element = document.createElement("a");

//         element.href = URL.createObjectURL(file);
//         element.download = props.recipe.id + ".ipynb";
//         document.body.appendChild(element);
//         element.click();
//     }

//     return (
//         <>
//             {
//                 query.has("edit") &&
//                 <Button variant="light" onClick={save}>
//                     保存
//                 </Button>
//             }
//         </>
//     )
// }

export const StoryView: FunctionComponent = () => {
    // const params = useParams<RouteParams>();
    // const history = useHistory();
    // const dispatch = useDispatch();
    // const location = useLocation();
    // const token = useRef(localStorage.getItem(LocalStorageKey.ACCESSTOKEN));
    // const passport = useSelector((store: AppStore) => store.passport);

    // const [recipe, setRecipe] = useReducer(recipeReducer, initialStore);
    // const [connected, setConnected] = useState(false);
    // const [logout, setLogout] = useState(false);

    /**
     * This effect function is triggered exactly only once when the component mounts and demounts.
     * 
     * All the fixtures for stomp should not be part of the effect function since they are not relevant to the user interface, 
     * however these variables are initialized and uninitialized here.
     */
    // useEffect(() => {
    //     console.log("Connecting to websocket service");

    //     // initialize the stomp client
    //     stomp.configure({
    //         brokerURL: process.env.NODE_ENV === "production" ? "wss://notebook.codemage.cn/stomp" : "ws://localhost:8080/stomp",
    //         splitLargeFrames: true,
    //         connectHeaders: {
    //             "bearer": `${token.current}`,
    //         },
    //         onConnect: () => {
    //             console.log("Connected to websocket service");

    //             // store the stomp client to redux store
    //             dispatch<KernelStompAction>({
    //                 type: KernelActionType.STOMP,
    //                 stomp: stomp,
    //             });

    //             // subscribe to get the kernel information
    //             kernel = stomp.subscribe("/user/queue/kernel", (message: IMessage) => {
    //                 let reply = JSON.parse(message.body) as KernelInfoReply;

    //                 console.log("Received kernel reply:", message.body);

    //                 dispatch<KernelInfoAction>({
    //                     type: KernelActionType.INFO,
    //                     protocol: reply.protocol,
    //                     version: reply.version,
    //                     language: reply.language,
    //                 });
    //             });

    //             // flip the connected flag since websocket is now ready
    //             setConnected(true);
    //         },
    //         onDisconnect: () => {
    //             console.log("Disconnecting from notebook service");
    //         },
    //         onStompError: (frame: IFrame) => {
    //             console.log("Received error from notebook service:", frame.headers["message"]);
    //             console.log("Received details from notebook service:", frame.body);

    //             if (frame.headers["message"].includes("AccessDeniedException")) {
    //                 setLogout(true);
    //             }
    //         },
    //         debug: (info: string) => {
    //             // can use this info for stomp debugging
    //         }
    //     });

    //     // activate stomp as long as the user has execution permission
    //     if (passport.roles.includes("ROLE_EXECUTION")) {
    //         stomp.activate();
    //     } else {
    //         dispatch<KernelInfoAction>({
    //             type: KernelActionType.INFO,
    //             protocol: "未知",
    //             version: "未知",
    //             language: "未知",
    //         });
    //     }

    //     return () => {
    //         if (passport.roles.includes("ROLE_EXECUTION")) {
    //             kernel?.unsubscribe();

    //             stomp.deactivate();
    //         }
    //     }
    // }, [dispatch, passport.roles]);

    /**
     * This effect function is triggered when user navigates to a new recipe.
     * 
     * Notice that this should wait until the websocket connection is established.
     */
    // useEffect(() => {
    //     console.log("Loading recipe", params.id);

    //     // reset grader redux store
    //     dispatch<GraderResetAction>({
    //         type: GraderActionType.RESET,
    //     });

    //     // fetch recipe from backend service when websocket is connected
    //     const getRecipe = async () => {
    //         const response = await materialService().get(`/recipe/${params.id}`);

    //         switch (response.status) {
    //             case 200:
    //                 const info = response.data as RecipeInfo;
    //                 var notebook = JSON.parse(info.content || "{}") as Notebook;

    //                 const action: RecipeLoadAction = {
    //                     type: RecipeActionType.LOAD,
    //                     id: info.id,
    //                     title: info.title,
    //                     next: info.next,
    //                     chapter_id: info.chapter_id,
    //                     chapter_title: info.chapter_title,
    //                     course_id: info.course_id,
    //                     course_title: info.course_title,
    //                     style: info.type,
    //                     notebook: JSON.parse(info.content || "{ 'cells' : [] }") as Notebook,
    //                     prerequisites: info.prerequisites,
    //                     relatives: info.relatives,
    //                 };
    //                 setRecipe(action);

    //                 if (passport.roles.includes("ROLE_EXECUTION")) {
    //                     // reset kernel status to yet to get ready
    //                     dispatch<KernelResetAction>({
    //                         type: KernelActionType.RESET,
    //                         recipe: params.id,
    //                     });

    //                     // show kernel status in the navigation bar
    //                     dispatch<KernelShowAction>({
    //                         type: KernelActionType.SHOW,
    //                     });

    //                     // ensure a proper kernel is created for the user and the recipe
    //                     const request: KernelInfoRequest = {
    //                         id: params.id,
    //                         recipe: params.id,
    //                         operation: "create",
    //                     };
    //                     console.log('Going to ask for kernel info...')
    //                     stomp?.publish({
    //                         destination: "/notebook/kernel",
    //                         body: JSON.stringify(request),
    //                     });

    //                     console.log("Sent kernel request:", JSON.stringify(request));

    //                     // initialize grader redux store for each code cell
    //                     notebook.cells.forEach(cell => {
    //                         if (cell.cell_type === "code") {
    //                             const grader = (cell.metadata as CodeMetadata).grader;
    //                             if (grader) {
    //                                 let action: GraderUpdateAction = {
    //                                     type: GraderActionType.UPDATE,
    //                                     identity: grader.identity,
    //                                     status: GraderStatus.UNKNOWN,
    //                                 };

    //                                 dispatch(action);
    //                             }
    //                         }
    //                     });
    //                 }
    //                 break;

    //             case 401:
    //                 setLogout(true);
    //                 break;
    //         }
    //     }
    //     if (connected) {
    //         getRecipe();
    //     }

    //      // fetch setting from backend service when websocket is connected
    //      const getSetting = async () => {
    //         const response = await settingService().get(`/setting/${passport.username}`);

    //         switch (response.status) {
    //             case 200:
    //                 const setting = response.data as UserSetting;

    //                 dispatch<PassportAction>({
    //                     type: PassportActionType.SETTING,
    //                     difficulty: setting.difficulty,
    //                 });
    //                 break;

    //             default:
    //                 console.warn("Failed to get setting from the server {}", response);
    //                 break;
    //         }
    //     }
    //     if (connected){
    //         getSetting();
    //     }

    //     return () => {
    //         // hide kernel information
    //         dispatch<KernelHideAction>({
    //             type: KernelActionType.HIDE,
    //         });
    //     }
    // }, [params.id, passport.username, passport.roles, dispatch, connected]);

    /**
     * This effect function is triggered when logout flag is set to true.
     * 
     * This is necessary as the first effect hook is designed to be triggered exactly only once when the component mounts and demounts.
     * To avoid additional dependencies e.g. history, location to break the rule, the functionality is moved here via the logout flag.
     */
    // useEffect(() => {
    //     if (logout) {
    //         console.info("Invalid or expired token, try to login again");

    //         dispatch({
    //             type: PassportActionType.LOGOUT,
    //         });

    //         history.push({
    //             pathname: "/login",
    //             state: {
    //                 referrer: location,
    //             }
    //         });
    //     }
    // }, [dispatch, history, location, logout]);

    const { id, mode } = useParams();
    const [storyJson, setStoryJson] = useState("");
    const [storyTitle, setStoryTitle] = useState("Demo");

    assert(id, "id should not be undefined");

    const jsonTextForm = useRef();

    const submitJsonForm = (inputs: { textString: string }) => {
        const jsonText2 = inputs.textString;
        setStoryJson(jsonText2);
    }

    const abortController = useRef<AbortController>();

    useEffect(() => {
        return () => {
            abortController.current?.abort();
        };
    }, []);

    useEffect(() => {
        if (!abortController.current) {
            abortController.current = new AbortController();
        }
        const fetchStoryData = async (id: string) => {
            const response = await materialService().get(`/story/${id}`, {
                signal: abortController.current?.signal
            });
            switch (response.status) {
                case 200:
                    const story = response.data as { "title": string, "content": string };
                    setStoryJson(story.content);
                    setStoryTitle(story.title);
                    break;

                case 401:
                    break;
            }
        };

        fetchStoryData(id).catch(reason => { });
    }, [id]);

    const [tab, setTab] = useState(TabType.SHOW);
    const storyShow = useMemo(() => <StoryShow content={storyJson} caption={storyTitle} showmode={mode?.toUpperCase() as ShowMode} />, [storyJson, storyTitle, mode]);
    const container = useRef<HTMLDivElement>(null);
    const [containerOffsetTop, setContainerOffsetTop] = useState(0);

    useEffect(() => {
        if (tab === TabType.SHOW) {
            setContainerOffsetTop(container.current?.offsetTop ?? 0);
        }
    }, [tab]);

    return (<div>
        <Tabs
            defaultActiveKey={TabType.SHOW}
            activeKey={tab}
            onSelect={(key) => {
                setTab((key || "SHOW") as TabType);
            }}
        >
            <Tab eventKey={TabType.SHOW} title="Story Show">
                <div ref={container} />
                <div style={{
                    position: "absolute",
                    top: `${containerOffsetTop}px`,
                    bottom: 0,
                    left: 0,
                    right: 0
                }}>{storyShow}</div>
            </Tab>
            <Tab eventKey={TabType.JSON} title="Raw JSON">
                <TextForm className="jsonForm" ref={jsonTextForm} inputs={{ textString: storyJson }} onSubmit={submitJsonForm} />
            </Tab>
        </Tabs>
    </div>);
}
