import assert from "assert";
import moment from "moment-timezone";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { WebinarInfo } from ".";
import { MarkdownCell } from "../markdown";
import { Jumbotron, LearnFooter } from "../navigator";
import { Notebook } from "../recipe";
import { materialService, TutorInfo } from "../service";
import { TutorIntro } from "./tutor";

const WebinarComponent: FunctionComponent = () => {
    const { alias } = useParams();
    const [webinar, setWebinar] = useState<WebinarInfo>();
    const [tutor, setTutor] = useState<TutorInfo>();

    assert(alias, "alias should not be undefined");

    const getTutor = useCallback(async (id: number) => {
        const response = await materialService().get(`tutor/${id}`);

        switch (response.status) {
            case 200:
                return response.data as TutorInfo;

            default:
                console.warn("unexpected behavior, please contact site admin");
                break;
        }
    }, [])

    const getWebinar = useCallback(async (alias: string) => {
        const response = await materialService().get(`webinar/${alias}`);

        switch (response.status) {
            case 200:
                const webinar = response.data as WebinarInfo;
                const tutor = await getTutor(webinar.tutor);

                setTutor(tutor);
                setWebinar(webinar);

                break;

            default:
                console.warn("unexpected behavior, please contact site admin");
                break;
        }
    }, [getTutor])

    useEffect(() => {
        console.log("Loading webinar", alias);

        getWebinar(alias);
    }, [alias, getWebinar]);

    const render = () => {
        var notebook = JSON.parse(webinar?.content || "{\"cells\": []}") as Notebook;

        return notebook.cells.map(cell => {
            switch (cell.cell_type) {
                case "markdown":
                    return <MarkdownCell key={uuid()} source={cell.source} />;
                default:
                    console.warn("Unsupported cell type");
                    return <></>;
            }
        })
    }

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1 className="mb-5">{webinar?.title}</h1>

                    <TutorIntro tutor={tutor} />

                    <p>形式：在线会议</p>
                    <p>时间：{moment(webinar?.time).format("YYYY-MM-DD HH:mm")} {moment.tz.guess()}</p>
                    <p>时长：{webinar?.duration} 分钟</p>
                </Container>
            </Jumbotron>
            <Container>
                {
                    render()
                }

                <LearnFooter />
            </Container>
        </>
    );
}

export { WebinarComponent };
