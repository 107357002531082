import { FunctionComponent, useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsArrowCounterclockwise } from "react-icons/bs";
import useSound from "use-sound";
import { AssistantContext } from "../assistant";
import { PassportContext, RoleName } from "../passport";

interface Params {
    visible: boolean;
}

export const ResetCommand: FunctionComponent<Params> = ({ visible }) => {
    const [play] = useSound("https://resource.codemage.cn/disable-sound.mp3");

    const { assistant } = useContext(AssistantContext);
    const { passport } = useContext(PassportContext);

    const executable = passport.roles.includes(RoleName.CODER);

    return (
        visible ?
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="reset">重置代码</Tooltip>}
            >
                <Button
                    variant="light"
                    type="reset"
                    size="sm"
                    className="ms-1"
                    disabled={!executable}
                    onMouseDown={() => assistant.sound && play()}
                >
                    <BsArrowCounterclockwise size="20" />
                </Button>
            </OverlayTrigger>
            :
            <></>
    )
}
