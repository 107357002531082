import mermaidAPI from "mermaid";
import { FunctionComponent, memo, useEffect, useRef } from "react";

interface Params {
    definition: string;
};

export const MermaidDiagram: FunctionComponent<Params> = memo(({ definition }) => {
    const diagram = useRef<HTMLImageElement>(null)

    console.log(definition);

    useEffect(() => {
        console.log("mermaid render 1")
        mermaidAPI.render('id1', definition, source => {
            console.log("mermaid render 2")
            if (diagram.current) {
                diagram.current.src = `data:image/svg+xml;utf8,${encodeURIComponent(source)}`;
            }
        })
    }, [definition]);

    return <img alt="mermaid diagram" ref={diagram} />
});