import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { Card, CardGroup, Col, Container, Placeholder, Row } from "react-bootstrap";
import { BsFillLockFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { LearnFooter } from "../navigator";
import { CourseInfo, materialService } from "../service";

interface CourseTileParams {
    course: CourseInfo;
};

const CourseTile: FunctionComponent<CourseTileParams> = ({ course }) => {
    return (
        <Card className="mt-4" >
            <Card.Img variant="top" src={course.thumbnail} style={{ objectFit: "cover" }} />
            <Card.Body>
                <Card.Title>
                    {
                        course.sequence < 100 ?
                            <Link to={`/course/${course.alias}`} style={{ textDecoration: "none" }}>
                                {course.title}
                            </Link>
                            :
                            <span>
                                {course.title}
                                <BsFillLockFill color="orange" className="ms-2" size={16} />
                            </span>
                    }
                </Card.Title>
                <Card.Text>
                    {course.summary}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

const CoursePlaceholder: FunctionComponent = () => {
    const url = "https://resource.codemage.cn/poster-placeholder.svg";

    return (
        <Card className="mt-4" >
            <Card.Img variant="top" src={url} style={{ objectFit: "cover" }} />
            <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} />
                    <Placeholder xs={4} />
                </Placeholder>
            </Card.Body>
        </Card>
    )
}

export const CourseList: FunctionComponent = () => {
    const [courses, setCourses] = useState<CourseInfo[]>();
    const controller = useRef<AbortController>();

    const getCourses = useCallback(async () => {
        try {
            controller.current = new AbortController();
            const response = await materialService().get("/course", {
                signal: controller.current.signal,
            });

            switch (response.status) {
                case 200:
                    const courses = (response.data as CourseInfo[]).filter(course => !course.special);
                    setCourses(courses);
                    break;
            }
        } catch (error) {
            if (!controller.current?.abort) {
                console.log("Unexpected error found in material service request", error);
            }
        }
    }, []);

    useEffect(() => {
        getCourses();
    }, [getCourses]);

    useEffect(() => {
        return () => {
            controller.current?.abort();
        };
    }, []);

    return (
        <Container>
            <CardGroup>
                <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                    {
                        courses ?
                            courses.map(course =>
                                <Col key={course.alias}>
                                    <CourseTile course={course} />
                                </Col>
                            )
                            :
                            [1, 2, 3, 4, 5, 6, 7, 8, 9].map(index =>
                                <Col key={index}>
                                    <CoursePlaceholder />
                                </Col>
                            )
                    }
                </Row>
            </CardGroup>

            <LearnFooter />
        </Container>
    );
}

