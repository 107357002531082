import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import { Media, MediaBody, MediaHead } from "../navigator";
import { PassportActionType, PassportContext } from "../passport";

export const Security: FunctionComponent = () => {
    const navigate = useNavigate();

    const { passport, dispatch } = useContext(PassportContext);

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (passport.expired) {
            setShow(true);
        }
    }, [passport.expired, dispatch])

    const logout = () => {
        setShow(false);

        dispatch({
            type: PassportActionType.LOGOUT,
        });

        navigate("/login");
    }

    return (
        <>
            <Modal show={show}>
                <Modal.Body>
                    <Media>
                        <MediaHead>
                            <Image
                                width={250}
                                height={250}
                                src="https://resource.codemage.cn/python-comic-guido-complaint.svg"
                                alt="robot"
                            />
                        </MediaHead>
                        <MediaBody>
                            <p>道路千万条，安全第一条，使用不规范，老师两行泪。</p>
                            <p>距离上一次登入已经很久了，我已经快认不出你了。</p>
                            <p>请点击「确认」按钮以重新登入。</p>
                        </MediaBody>
                    </Media>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => logout()}>
                        确认
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}