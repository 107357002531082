export enum DifficultyLevel {
    EASY = "easy",
    NORMAL = "normal",
    HARD = "hard"
}

interface UserSetting {
    difficulty: DifficultyLevel;
}

export type { UserSetting }