import { FunctionComponent, ReactElement, ReactNode } from "react";

interface Params {
    children: ReactNode[];
};

export const ParagraphBlock: FunctionComponent<Params> = ({ children }) => {
    const image = children?.find((child: ReactNode) => {
        const key = (child as ReactElement).key as string;
        return key ? key.startsWith("img-") : false;
    })

    return (
        image ?
            <>
                {children}
            </>
            :
            <p>
                {children}
            </p>
    );
}
