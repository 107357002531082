import React, { FunctionComponent, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { SectionTitle } from "./title";

interface LessonRecordParams {
    record?: string;
}

export const LessonRecord: FunctionComponent<LessonRecordParams> = (props) => {
    const [link, setLink] = useState<string>();
    const [pass, setPass] = useState<string>();

    useEffect(() => {
        const values = props.record?.split("  ");
        if (values?.length === 2) {
            const one = values[0].split("：");
            const two = values[1].split("：");

            if (one.length === 2 && two.length === 2) {
                setLink(one[1]);
                setPass(two[1]);
            }
        }
    }, [props.record]);

    return (
        <>
            <SectionTitle title={"课程录像"} />

            {
                props.record ?
                    <>
                        <p>
                            课程录像是通过百度网盘进行分享的。请复制好提取码，然后点击下面的链接，在新开的窗口中黏贴提取码，就可以访问录像了。
                        </p>
                        <p>
                            <span>链接：</span>
                            <a href={link} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                {link}
                            </a>

                            <span className="ms-4">提取码：{pass}</span>
                            <CopyToClipboard text={pass || ""} >
                                <Button variant="link">
                                    <BiCopy color={"grey"} />
                                </Button>
                            </CopyToClipboard>
                        </p>
                    </>
                    :
                    <p>
                        尚未上传课程录像。
                    </p>
            }
        </>
    )
}