import { FunctionComponent, useEffect, useState } from "react";
import { materialService } from "../service";
import { ShowMode, StoryShow } from "./storyshow";

interface Params {
    id: string;
    eventFrom?: number;
    eventTo?: number;
}

const StoryCell: FunctionComponent<Params> = ({ id, eventFrom, eventTo }) => {
    const [storyJson, setStoryJson] = useState("");
    const [storyTitle, setStoryTitle] = useState("Demo");

    useEffect(() => {
        const fetchStoryData = async (id: string) => {
            const response = await materialService().get(`/story/${id}`);
            switch (response.status) {
                case 200:
                    const story = response.data as { "title": string, "content": string };
                    setStoryJson(story.content);
                    setStoryTitle(story.title);
                    break;

                case 401:
                    break;
            }
        };

        fetchStoryData(id);
    }, [id]);

    return (
        <StoryShow
            content={storyJson}
            caption={storyTitle}
            showmode={ShowMode.SLIDESHOW}
            eventFrom={eventFrom}
            eventTo={eventTo}
        />
    );
}

export { StoryCell };
