import React, { FunctionComponent, useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";
import { AssistantActionType, AssistantContext } from "../assistant";

const ExplanationToggle: FunctionComponent = () => {
    const { assistant, dispatch } = useContext(AssistantContext);

    const toggle = () => {
        dispatch({
            type: AssistantActionType.EXPLANATION,
        });
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="submit">提示开关</Tooltip>}
        >
            <Button variant="link" onClick={toggle}>
                {
                    assistant.explanation ?
                        <RiEyeLine size={24} style={{ color: "white" }} />
                        :
                        <RiEyeCloseLine size={24} style={{ color: "white" }} />
                }
            </Button>
        </OverlayTrigger>
    );
}

export { ExplanationToggle };
