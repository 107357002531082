export * from "./login";
export * from "./context";

export interface JwtResponse {
    username: string;
    token: string;
}

export class RoleName {
    public static readonly STUDENT = "student";
    public static readonly ADMIN = "admin";
    public static readonly TUTOR = "tutor";
    public static readonly CODER = "coder";
    public static readonly PRINCIPAL = "principal";
}