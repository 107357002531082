import assert from "assert";
import { FunctionComponent, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Accordion, AccordionContext, Badge, Button, Card, Container, useAccordionButton } from "react-bootstrap";
import HashLinkObserver from "react-hash-link";
import { BsBarChartFill, BsFillLockFill } from "react-icons/bs";
import { FaToriiGate, FaTrophy } from "react-icons/fa";
import { GoTriangleDown, GoTriangleRight } from "react-icons/go";
import { useLocation, useParams } from "react-router-dom";
import { ChapterTile } from "./chaptertile";
import { MarkdownCell } from "../markdown";
import { Jumbotron, LearnFooter, Media, MediaBody, MediaHead } from "../navigator";
import { Notebook } from "../recipe";
import { CourseInfo, CourseLevel, CourseType, materialService } from "../service";

interface CourseIntroParams {
    course?: CourseInfo;
}

const CourseIntro: FunctionComponent<CourseIntroParams> = ({ course }) => {
    var notebook = JSON.parse(course?.intro || "{\"cells\": []}") as Notebook;

    return (
        <>
            {
                notebook.cells.map((cell, index) =>
                    cell.cell_type === "markdown" &&
                    <MarkdownCell
                        key={`course-${course?.alias}-cell-${index}`}
                        source={cell.source}
                    />
                )
            }
        </>
    );
}

interface ToggleParams {
    children: ReactNode[];
    eventKey: string;
}

const ContextAwareToggle: FunctionComponent<ToggleParams> = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const toggle = useAccordionButton(eventKey);

    return (
        <div onClick={toggle}>
            {
                activeEventKey === eventKey ?
                    <GoTriangleDown size={20} className="me-2" />
                    :
                    <GoTriangleRight size={20} className="me-2" />
            }
            {
                children
            }
        </div>
    );
}

interface CourseTypeParams {
    type: CourseType;
}

interface CourseTypeInfo {
    title: string;
    content: string;
}

const CourseTypeMedia: FunctionComponent<CourseTypeParams> = ({ type }) => {
    const messages: Record<CourseType, CourseTypeInfo> = {
        FREEMIUM: {
            title: "会员专享",
            content: "精心策划的精品课程，成为会员即可使用该产品的所有内容。",
        },
        PREMIUM: {
            title: "增值服务",
            content: "悉心开发的全网独家课程，付费购买后凭借会员即可永久访问课程所有内容。",
        }
    }

    return (
        <Media className="mt-2 mb-2">
            <MediaHead>
                <Button
                    style={{
                        borderRadius: 50,
                        height: 50,
                        width: 50,
                        cursor: "default",
                        backgroundColor: "lightskyblue",
                        outline: "none",
                        border: "none",
                    }}
                    className="mx-3"
                    disabled>
                    <FaTrophy size="20" />
                </Button>
            </MediaHead>
            <MediaBody>
                <h5 className="mb-1">{messages[type].title}</h5>
                <p className="mt-0">{messages[type].content}</p>
            </MediaBody>
        </Media >
    );
}

const OnlineMedia: FunctionComponent = () => {
    return (
        <Media className="my-2">
            <MediaHead>
                <Button
                    style={{
                        borderRadius: 50,
                        height: 50,
                        width: 50,
                        cursor: "default",
                        backgroundColor: "lightskyblue",
                        outline: "none",
                        border: "none",
                    }}
                    className="mx-3"
                    disabled>
                    <FaToriiGate size="20" />
                </Button>
            </MediaHead>
            <MediaBody>
                <h5 className="mb-1">在线学习</h5>
                <p className="mt-0">仅需浏览器便能够在线学习，可以根据自己的时间灵活控制节奏。</p>
            </MediaBody>
        </Media >
    );
}

interface CourseLevelParams {
    level: CourseLevel;
}

interface CourseLevelInfo {
    title: string;
    content: string;
}

const CourseLevelMedia: FunctionComponent<CourseLevelParams> = ({ level }) => {
    const messages: Record<CourseLevel, CourseLevelInfo> = {
        BEGINNER: {
            title: "新手入门",
            content: "本课程难易程度为入门，零编程基础的同学就可以参加学习。",
        },
        ELEMENTARY: {
            title: "新手提高",
            content: "本课程难易程度为初级，适合完成入门篇或者具有一定基础的同学提高。",
        },
        INTERMEDIATE: {
            title: "中级进阶",
            content: "本课程难易程度为中级，适合熟练掌握编程知识并希望深入学习的同学。",
        },
        ADVANCED: {
            title: "高手精进",
            content: "本课程难易程度为高级，适合熟练掌握编程知识并在细分领域希望深造的同学。",
        }
    }

    return (
        <Media className="my-2">
            <MediaHead>
                <Button
                    style={{
                        borderRadius: 50,
                        height: 50,
                        width: 50,
                        cursor: "default",
                        backgroundColor: "lightskyblue",
                        outline: "none",
                        border: "none",
                    }}
                    className="mx-3"
                    disabled>
                    <BsBarChartFill size="20" />
                </Button>
            </MediaHead>
            <MediaBody className="mb-1">
                <h5 className="mb-1">{messages[level].title}</h5>
                <p className="mt-0">{messages[level].content}</p>
            </MediaBody>
        </Media >
    );
}

interface RegistrationStatusParams {
    registered: boolean;
}

const RegistrationStatus: FunctionComponent<RegistrationStatusParams> = ({ registered }) => {
    return (
        registered ?
            <></>
            :
            <BsFillLockFill color="orange" className="ms-2" />
    );
}

interface SectionTitleParams {
    title: string;
}

const SectionTitle: FunctionComponent<SectionTitleParams> = ({ title }) => {
    return (
        <h2 className="mt-5 mb-5">
            {title}
        </h2>
    );
}

export const CourseDetail: FunctionComponent = () => {
    const { id } = useParams();
    const [course, setCourse] = useState<CourseInfo>();
    const controller = useRef<AbortController>();

    assert(id, "id should not be undefined");

    const getCourses = useCallback(async (id: string) => {
        try {
            controller.current = new AbortController();
            const response = await materialService().get(`course/${id}`, {
                signal: controller.current.signal,
            });

            switch (response.status) {
                case 200:
                    setCourse(response.data as CourseInfo);
                    break;
            }
        } catch (error) {
            if (!controller.current?.abort) {
                console.log("Unexpected error found in material service request", error);
            }
        }
    }, []);

    useEffect(() => {
        getCourses(id);

    }, [id, getCourses]);

    useEffect(() => {
        return () => {
            controller.current?.abort();
        };
    }, []);

    const hash = useLocation()?.hash?.substr(1);

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>{course?.title}</h1>
                </Container>
            </Jumbotron>
            <Container>
                <CourseIntro course={course} />

                <SectionTitle title={"课程亮点"} />

                <CourseTypeMedia type={course?.type || CourseType.FREEMIUM} />
                <OnlineMedia />
                <CourseLevelMedia level={course?.level || CourseLevel.BEGINNER} />

                <SectionTitle title={"课程大纲"} />

                {
                    course?.chapters.map(chapter => {
                        const id = `chapter-${chapter.id}`;
                        const registered = course.registered || chapter.trial;

                        return (
                            <Accordion className="mb-3" id={id} key={chapter.id} flush defaultActiveKey={id === hash ? `${chapter.id}` : undefined}>
                                <Card key={chapter.id}>
                                    <Card.Header>
                                        <ContextAwareToggle eventKey={`${chapter.id}`}>
                                            <span className="py-2">
                                                <span className="me-3">{chapter.title}</span>
                                                {
                                                    chapter.tags.sort((a, b) => {
                                                        return ['问题拆解', '本质抽象', '模式识别', '算法设计', '谜题'].includes(a) ? -1 : 1;
                                                    }).map(tag =>
                                                        <Badge bg="info" key={tag} className="mx-1">{tag}</Badge>
                                                    )
                                                }
                                            </span>
                                            <RegistrationStatus registered={registered} />
                                            <p >
                                                {chapter.summary}
                                            </p>
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={`${chapter.id}`}>
                                        <Card.Body>
                                            <ChapterTile registered={registered} key={chapter.id} chapter={chapter} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        )
                    })
                }

                <LearnFooter />

                <HashLinkObserver />
            </Container>
        </>
    );
}