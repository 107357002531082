import { FunctionComponent } from "react";
import { Card, CardGroup, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ChapterInfo, RecipeInfo } from "../service";

interface SectionTitleParams {
    registered: boolean;
    chapter: ChapterInfo;
    section: RecipeInfo;
};

const SectionTitle: FunctionComponent<SectionTitleParams> = ({ registered, chapter, section }) => {
    const navigate = useNavigate();

    return (
        registered ?
            <Link to={`/recipe/${section.id}`} style={{ textDecoration: "none" }} onClick={() => navigate(`#chapter-${chapter.id}`, { replace: true })}>
                {section.title}
            </Link>
            :
            <>
                {section.title}
            </>
    )
}

interface ChapterParams {
    registered: boolean;
    chapter: ChapterInfo;
};

export const ChapterTile: FunctionComponent<ChapterParams> = ({ registered, chapter }) => {
    return (
        <CardGroup>
            <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                {
                    chapter.sections.map(section =>
                        <Col key={section.id}>
                            <Card key={section.id} className="mt-4">
                                <Card.Img variant="top" src={section.thumbnail} style={{ objectFit: "cover" }} />
                                < Card.Body>
                                    <Card.Title>
                                        <SectionTitle
                                            registered={registered}
                                            chapter={chapter}
                                            section={section}
                                        />
                                    </Card.Title>
                                    <Card.Text>
                                        {section.summary}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }
            </Row>
        </CardGroup>
    );
}
