import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { AchievementComponent } from "../achievement";
import { CertificateDetail } from "../certificate";
import { CircleDetail, CircleList } from "../circle";
import { CourseDetail, CourseList } from "../course";
import { KnowledgeList } from "../knowledge";
import { WelcomeComponent } from "../landing";
import { LessonDetail } from "../lesson";
import { LoginComponent } from "../passport";
import { ProjectList } from "../project";
import { RecipeView } from "../recipe";
import { RegisterView } from "../register";
import { ScholarDetail, ScholarList } from "../scholar";
import { StoryView } from "../story/story";
import { StudyDetail, StudyList } from "../study";
import { TutorDetail, TutorList } from "../tutor";
import { WebinarComponent } from "../webinar";
import { PrivateRoute, RestrictedRoute } from "./specialrouter";

export const ContentRouter: FunctionComponent = () => {
    return (
        <Routes>
            <Route path="/" element={<WelcomeComponent />} />
            <Route path="/course" element={<CourseList />} />
            <Route path="/course/:id" element={<CourseDetail />} />
            <Route path="/project" element={<ProjectList />} />
            <Route path="/knowledge/" element={<KnowledgeList />} />
            <Route path="/tutor" element={<TutorList />} />
            <Route path="/tutor/:id" element={<TutorDetail />} />
            <Route path="/study/" element={<StudyList />} />
            <Route path="/scholar/" element={<ScholarList />} />
            <Route path="/webinar/:alias" element={<WebinarComponent />} />
            <Route path="/certificate/:id" element={<CertificateDetail />} />
            <Route path="/register" element={<RestrictedRoute><RegisterView /></RestrictedRoute>} />
            <Route path="/login" element={<RestrictedRoute><LoginComponent /></RestrictedRoute>} />
            <Route path="/study/:id" element={<PrivateRoute><StudyDetail /></PrivateRoute>} />
            <Route path="/scholar/:id" element={<PrivateRoute><ScholarDetail /></PrivateRoute>} />
            <Route path="/circle/:id" element={<PrivateRoute><CircleDetail /></PrivateRoute>} />
            <Route path="/recipe/:id" element={<PrivateRoute><RecipeView /></PrivateRoute>} />
            <Route path="/story/:id" element={<StoryView />}>
                <Route path="/story/:id/:mode" element={<StoryView />} />
            </Route>
            <Route path="/lesson/:id" element={<PrivateRoute><LessonDetail /></PrivateRoute>} />
            <Route path="/circle" element={<PrivateRoute><CircleList /></PrivateRoute>} />
            <Route path="/achievement" element={<PrivateRoute><AchievementComponent /></PrivateRoute>} />
        </Routes>
    );
}