import { FunctionComponent, useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { RiDownloadCloud2Line } from "react-icons/ri";
import useSound from "use-sound";
import { AssistantContext } from "../assistant";
import { CodeActionType, CodeContext } from "../monaco";
import { PassportContext, RoleName } from "../passport";
import { notebookService } from "../service";

interface Params {
    snippet?: string;
    visible: boolean;
}

export const HistoryCommand: FunctionComponent<Params> = ({ snippet, visible }) => {
    const [play] = useSound("https://resource.codemage.cn/enable-sound.mp3");

    const { assistant } = useContext(AssistantContext);
    const { passport } = useContext(PassportContext);
    const { dispatch: setCode } = useContext(CodeContext);

    const executable = passport.roles.includes(RoleName.CODER);

    const download = async () => {
        const response = await notebookService().get(`/submission`, {
            params: {
                username: passport.username,
                snippet: snippet,
            }
        });
        switch (response.status) {
            case 200:
                setCode({
                    type: CodeActionType.UPDATE,
                    code: response.data as string,
                })
                break;

            default:
                console.warn("unexpected behavior, please contact site admin");
                break;
        }
    }

    return (
        visible ?
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="history">载入历史</Tooltip>}
            >
                <Button
                    variant="light"
                    type="button"
                    size="sm"
                    className="ms-1"
                    disabled={!executable}
                    onClick={download}
                    onMouseDown={() => assistant.sound && play()}
                >
                    <RiDownloadCloud2Line size="20" />
                </Button>
            </OverlayTrigger>
            :
            <></>
    );
}
