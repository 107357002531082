import { FunctionComponent } from "react";
import { Figure } from "react-bootstrap";

interface Params {
    src?: string;
    alt?: string;
};

export const ImageBlock: FunctionComponent<Params> = ({ src, alt }) => {
    return (
        <Figure>
            <Figure.Image alt={alt} src={src} />
            <Figure.Caption className="text-center">
                {alt}
            </Figure.Caption>
        </Figure>
    );
}
