export * from "./rawcell";
export * from "./recipe";
export * from "./context";

export enum CellType {
    markdown = "markdown",
    code = "code",
    raw = "raw",
}

export interface CellMetadata {

}

export interface GraderInfo {
    readonly type?: string;
    readonly length?: number;
    readonly identity: string;
    readonly answer: string | string[];
}

export interface CodeMetadata extends CellMetadata {
    grader?: GraderInfo;
    explanation: Array<string>;
    readonly?: boolean;
    snippet?: string;
}

export interface VideoMetadata extends CellMetadata {
    type: "vod";
    title: string;
    content: string;
}

export interface ComicMetadata extends CellMetadata {
    type: "comic";
    title: string;
    content: Array<string>;
}

export interface StoryMetadata extends CellMetadata {
    type: "story";
    content: string;
    from?: number;
    to?: number;
}

export type RawMetadata = VideoMetadata | ComicMetadata | StoryMetadata;

export interface Cell {
    cell_type: CellType;
    metadata: CellMetadata;
    source: string[];
    outputs: object[];
    execution_count: number;
}

export interface Notebook {
    cells: Cell[];
    metadata: {
        kernelspec: object;
        language_info: object;
    };
    nbformat: number;
    nbformat_minor: number;
}