import * as StoryData from "./storydata";

const STR_SLASH = "/";
const STORY_RESOURCE_PREFIX = process.env.NODE_ENV === "production" || process.env.REACT_APP_FORCE_PRODUCTION ?
    "https://resource.codemage.cn" : "/data";
export const storyResourceUrl = (path: string | undefined) =>
    !path || path === "" ? undefined : (STORY_RESOURCE_PREFIX + (path.startsWith(STR_SLASH) ? "" : STR_SLASH) + path);

export interface StoryScenesAssembly {
    defaultSets: StoryData.StorySceneDict,
    additionalSets: StoryData.StorySceneType[]
};

export interface StoryResourcesType {
    characters: StoryData.StoryCharactersDict;
    popups: StoryData.StoryPopupType[];
    scenes: StoryScenesAssembly;
}

export enum StoryResourcesActionType {
    SET = "SET",
}

interface StoryResourcesSetAction {
    type: typeof StoryResourcesActionType.SET;
    value?: any;
}

export type StoryResourcesAction = StoryResourcesSetAction;

export interface StoryResourcesContextType {
    state: StoryResourcesType
    dispatch: (action: StoryResourcesAction) => void
}

const updatedStoryResources = (stateOriginal: StoryResourcesType, updateValues: any) => {
    let stateUpdate = { ...stateOriginal };
    if (updateValues !== undefined) {
        if (updateValues.characters !== undefined) {
            Object.assign(stateUpdate, { characters: { ...updateValues.characters } });
        }
        if (updateValues.popups !== undefined) {
            Object.assign(stateUpdate, { popups: [...updateValues.popups] });
        }
        if (updateValues.scenes !== undefined) {
            Object.assign(stateUpdate, { scenes: { ...updateValues.scenes } });
        }
    }
    return stateUpdate;
};

export const initialStoryResources: StoryResourcesType = {
    characters: {},
    popups: [],
    scenes: { defaultSets: {}, additionalSets: [] },
};
export const reducerStoryResources = (state: StoryResourcesType, action: StoryResourcesAction) => {
    switch (action.type) {
        case StoryResourcesActionType.SET: {
            return updatedStoryResources(state, action.value);
        }
        default:
            return state;
    }
}