import assert from "assert";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { MarkdownCell } from "../markdown";
import { Jumbotron, LearnFooter } from "../navigator";
import { Notebook } from "../recipe";
import { materialService, ScholarInfo } from "../service";

interface ScholarContentParams {
    scholar?: ScholarInfo;
}

const ScholarContent: FunctionComponent<ScholarContentParams> = (props) => {
    var notebook = JSON.parse(props.scholar?.content || "{\"cells\": []}") as Notebook;

    return (
        <>
            {
                notebook.cells.map((cell, index) =>
                    cell.cell_type === "markdown" ?
                        <MarkdownCell key={index} source={cell.source} />
                        :
                        null
                )
            }
        </>
    );
}

const ScholarDetail: FunctionComponent = () => {
    const { id } = useParams();

    assert(id, "id should not be undefined");

    const [scholar, setScholar] = useState<ScholarInfo>();

    const getScholars = useCallback(async (id: string) => {
        const response = await materialService().get(`/scholar/${id}`);

        switch (response.status) {
            case 200:
                setScholar(response.data as ScholarInfo);
                break;
            default:
                console.warn("unexpected behavior, please contact site admin");
                break;
        }
    }, []);

    useEffect(() => {
        console.log("Loading scholar", id);

        getScholars(id);
    }, [id, getScholars]);

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>{scholar?.title}</h1>
                </Container>
            </Jumbotron>
            <Container>
                <ScholarContent scholar={scholar} />

                <LearnFooter />
            </Container>
        </>
    );
}

export { ScholarDetail };
