import { FunctionComponent, useContext } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { BsFillSkipEndFill } from "react-icons/bs";
import useSound from "use-sound";
import { AssistantContext } from "../assistant";
import { PassportContext, RoleName } from "../passport";

interface Params {
    ready: boolean
}

export const ExecuteCommand: FunctionComponent<Params> = ({ ready }) => {
    const [down] = useSound("https://resource.codemage.cn/pop-down.mp3");
    const [up] = useSound("https://resource.codemage.cn/pop-up-on.mp3");

    const { assistant } = useContext(AssistantContext);
    const { passport } = useContext(PassportContext);

    const executable = passport.roles.includes(RoleName.CODER);

    return (
        ready || !executable ?
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="submit">执行代码</Tooltip>}
            >
                <Button
                    variant="light"
                    type="submit"
                    size="sm"
                    disabled={!executable}
                    onMouseDown={() => assistant.sound && down()}
                    onMouseUp={() => assistant.sound && up()}>
                    <BsFillSkipEndFill size="20" />
                </Button>
            </OverlayTrigger>
            :
            <Button
                variant="light"
                type="submit"
                size="sm"
                className="me-1"
                disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </Button>
    )
}

