import { StompSubscription } from "@stomp/stompjs";
import Ansi from "ansi-to-react";
import parse, { Element } from "html-react-parser";
import { createRef, FormEvent, FunctionComponent, useContext, useEffect, useReducer, useRef } from "react";
import { Button, Figure, Form, Modal } from "react-bootstrap";
import { BsChatDots } from "react-icons/bs";
import { v4 as uuid } from "uuid";
import { KernelContext } from "../kernel";
import { MarkdownCell } from "../markdown";
import { DisplayData, ExecuteReply, ExecuteRequest, ExecuteResult, InputReply, InputRequest, Message, MessageStatus, Status, Stream } from "../message";
import { CodeActionType, CodeContext, codeReducer, MonacoEditor } from "../monaco";
import { Media, MediaBody, MediaHead } from "../navigator";
import { PassportContext, RoleName } from "../passport";
import { CodeMetadata, GraderProgressAction, RecipeActionType, RecipeContext } from "../recipe";
import { ExamStatus } from "./examstatus";
import { ErrorCode, ErrorType } from "./errorcode";
import { ExecuteCommand } from "./executecommand";
import { ExecutionActionType, ExecutionContext, executionReducer, ExecutionStatus, GraderStatus } from "./executioncontext";
import { ExplainCommand } from "./explaincommand";
import { HistoryCommand } from "./historycommand";
import { InterruptCommand } from "./interruptcommand";
import { QueryActionType, queryReducer } from "./querycontext";
import { ResetCommand } from "./resetcommand";
import { ExamDetail } from "./examdetail";

interface Params {
    lines: string[];
    recipe: string;
    metadata: CodeMetadata;
}

// There are various types of code cells:
//
// 1. The normal code cell where user can execute user code and inspect execution result.
// 2. The exam code cell where user can execute exam code and learn pass or fail result.
// 
// Exam code cell also works with grader such that user can earn the achievements.

export const CodeCell: FunctionComponent<Params> = ({ lines, recipe, metadata }) => {
    const { passport } = useContext(PassportContext);
    const { kernel } = useContext(KernelContext);
    const { dispatch } = useContext(RecipeContext);

    const shellSubs = useRef<StompSubscription>();
    const pubSubs = useRef<StompSubscription>();
    const inputSubs = useRef<StompSubscription>();
    const msgId = useRef<string>();

    const textInput = createRef<HTMLInputElement>();
    const textHidden = useRef<HTMLDivElement>(null);

    const executable = useRef<boolean>(passport.roles.includes(RoleName.CODER));

    const [code, setCode] = useReducer(codeReducer, {
        id: metadata.snippet || "",
        language: "python",
        current: lines.join(""),
        origin: lines.join(""),
    });

    const [execution, setExecution] = useReducer(executionReducer, {
        grader: metadata.grader,
        counter: "*",
        status: ExecutionStatus.COMPUTE,
        hint: "",
        outputs: [],
        qualified: GraderStatus.UNKNOWN,
        show: false,
    });

    const [query, setQuery] = useReducer(queryReducer, {
        querying: false,
        password: false,
        text: "",
    });

    /**
     * This effect function is supposed to triggered exactly only once when the component mounts and demounts.
     * 
     * All the fixtures to channels e.g. shell, io pub, input, are initialized and uninitialized here.
     */
    useEffect(() => {
        const subscribe = () => {
            console.log("Subscribing to shell channel");
            shellSubs.current = kernel.stomp?.subscribe("/user/queue/shell", (message) => {
                const reply = JSON.parse(message.body) as Message;

                if (reply.parent_header.msg_id === msgId.current) {
                    const content = reply.content as ExecuteReply;

                    switch (content.status) {
                        case MessageStatus.ok:
                            setExecution({
                                type: ExecutionActionType.STATUS,
                                counter: content.execution_count.toString(),
                                status: ExecutionStatus.SUCCESS,
                                hint: "",
                            });
                            break;

                        case MessageStatus.error:
                            var error = content.ename in ErrorType ? ErrorCode[content.ename as ErrorType] : "";
                            setExecution({
                                type: ExecutionActionType.STATUS,
                                counter: content.execution_count.toString(),
                                status: ExecutionStatus.ERROR + "：" + error,
                                hint: content.traceback.join("\n"),
                            });
                            break;

                        case MessageStatus.aborted:
                            var abort = content.ename in ErrorType ? ErrorCode[content.ename as ErrorType] : "";
                            setExecution({
                                type: ExecutionActionType.STATUS,
                                counter: content.execution_count.toString(),
                                status: ExecutionStatus.ABORTED + "：" + abort,
                                hint: content.traceback.join("\n"),
                            });
                            break;
                    }
                }
            });

            console.log("Subscribing to iopub channel");
            pubSubs.current = kernel.stomp?.subscribe("/user/queue/pub", (message) => {
                const reply = JSON.parse(message.body) as Message;

                if (reply.parent_header.msg_id === msgId.current) {
                    const type = reply.header.msg_type;
                    const content = reply.content;

                    switch (type) {
                        case "status":
                            switch ((content as Status).execution_state) {
                                case "busy":
                                case "starting":
                                    setExecution({
                                        type: ExecutionActionType.SHOW,
                                    });
                                    break;
                                case "idle":
                                    break;
                            }
                            break;

                        case "execute_result":
                            const result = content as ExecuteResult;

                            // handle the alternative mime types in a specific order

                            if ("text/html" in result.data) {
                                setExecution({
                                    type: ExecutionActionType.OUTPUT,
                                    payload: {
                                        type: "text/html",
                                        content: result.data["text/html"],
                                        text: result.data["text/plain"] || "",
                                    },
                                });
                            } else if ("text/latex" in result.data) {
                                setExecution({
                                    type: ExecutionActionType.OUTPUT,
                                    payload: {
                                        type: "text/latex",
                                        content: result.data["text/latex"],
                                        text: result.data["text/plain"] || "",
                                    },
                                });
                            } else if ("text/plain" in result.data) {
                                setExecution({
                                    type: ExecutionActionType.OUTPUT,
                                    payload: {
                                        type: "text/plain",
                                        content: result.data["text/plain"],
                                        text: result.data["text/plain"],
                                    },
                                });
                            }
                            break;

                        case "stream":
                            const stream = content as Stream;
                            setExecution({
                                type: ExecutionActionType.OUTPUT,
                                payload: {
                                    type: "text/plain",
                                    content: stream.text,
                                    text: stream.text,
                                },
                            });
                            break;

                        case "display_data":
                            const display = content as DisplayData;
                            const types: string[] = ["text/html", "image/png", "image/jpeg", "text/plain"];
                            var mime = types.find(element => display.data.hasOwnProperty(element));
                            if (mime) {
                                setExecution({
                                    type: ExecutionActionType.OUTPUT,
                                    payload: {
                                        type: mime,
                                        content: display.data[mime],
                                        text: display.data["text/plain"] || "",
                                    },
                                });
                            }
                            break;

                        case "error":
                            // error message is handled in shell channel
                            break;
                    }

                    // scroll to the hidden element so that latest outputs are shown
                    textHidden.current?.scrollIntoView({
                        behavior: "auto",
                        block: "nearest",
                        inline: "start",
                    });
                }
            });

            console.log("Subscribing to input channel");
            inputSubs.current = kernel.stomp?.subscribe("/user/queue/input", (message) => {
                const reply = JSON.parse(message.body) as InputRequest;

                if (reply.msgId === msgId.current) {
                    setQuery({
                        type: QueryActionType.START,
                        password: reply.password,
                        text: reply.prompt,
                    });
                }
            });
        }
        if (executable.current) {
            subscribe();
        }

        // validate the schema
        if (metadata.snippet === undefined) {
            console.warn("Generate random id for snippet");
            metadata.snippet = uuid();
        }

        return () => {
            console.log("Unsubscribing to the channels");

            shellSubs.current?.unsubscribe();
            pubSubs.current?.unsubscribe();
            inputSubs.current?.unsubscribe();
        }

        // NOTICE! DO NOT ADD ANY DEPENDENCY UNLESS IT WON'T CHANGE! DO NOT BREAK WEBSOCKET REGISTRATION!

    }, [kernel.stomp, metadata]);

    /**
     * This effect function is triggered every time the condition is met.
     * 
     * We can build up logic here e.g. to notify context store of the progress.
     */
    useEffect(() => {
        if (metadata.grader) {
            const action: GraderProgressAction = {
                type: RecipeActionType.PROGRESS,
                identity: metadata.grader?.identity,
                status: execution.qualified,
            };
            dispatch(action);
        }
    }, [dispatch, execution.qualified, metadata.grader]);

    /**
     * Handler for user to submit the code.
     * 
     * @param event form event
     */
    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // clean up the outputs
        setExecution({
            type: ExecutionActionType.RESET,
        });

        // submit code with a specific message id
        msgId.current = uuid();
        if (metadata.snippet === undefined) {
            console.warn("recipe", recipe, "contains a snippet without id");
        }

        const request: ExecuteRequest = {
            id: msgId.current,
            code: code.current,
            recipe: recipe,
            snippet: metadata.snippet,
        }

        kernel.stomp?.publish({
            destination: "/notebook/code",
            body: JSON.stringify(request)
        });

        console.log("Submitted execute request");
    };

    /**
     * Handler for user to reset code.
     * 
     * @param event form event
     */
    const reset = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setCode({
            type: CodeActionType.RESET
        });
    };

    /**
     * Handler for user to submit input value.
     * 
     * @param event form event
     */
    const input = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setQuery({
            type: QueryActionType.STOP,
        });

        const transfer: InputReply = {
            recipe: recipe,
            value: textInput.current?.value || "",
        }

        kernel.stomp?.publish({
            destination: "/notebook/input",
            body: JSON.stringify(transfer)
        });

        event.currentTarget.reset();
    }

    return (
        <>
            <CodeContext.Provider value={{ code: code, dispatch: setCode }} >
                <ExecutionContext.Provider value={{ execution: execution, dispatch: setExecution }}>
                    <Form onSubmit={submit} onReset={reset}>
                        <ExecuteCommand
                            ready={kernel.ready}
                        />

                        <InterruptCommand
                            recipe={recipe}
                        />

                        <ResetCommand
                            visible={!metadata.readonly}
                        />

                        <ExplainCommand
                            source={metadata.explanation}
                        />

                        <HistoryCommand
                            snippet={metadata.snippet}
                            visible={!metadata.readonly}
                        />

                        <span className="mx-2"></span>

                        <ExamStatus />

                        {
                            metadata.grader?.type === "senior" ?
                                <ExamDetail
                                />
                                :
                                <MonacoEditor
                                    readOnly={metadata.readonly || false}
                                    viewOnly={false}
                                />
                        }
                    </Form>
                </ExecutionContext.Provider>
            </CodeContext.Provider>

            <Media className={execution.show ? "" : "d-none"}>
                <MediaHead>
                    <Figure>
                        <BsChatDots size={24} style={{ color: "#007bff" }} className="mt-3" />
                    </Figure>
                </MediaHead>
                <MediaBody className="ms-2">
                    <p>
                        [{execution.counter}] {execution.status}
                    </p>
                    <div>
                        {
                            execution.grader?.type === "senior" ?
                                <>
                                </>
                                :
                                execution.outputs?.map((entry, index) => {
                                    switch (entry.type) {
                                        case "text/html":
                                            return parse(entry.content, {
                                                replace: (domNode) => {
                                                    if (domNode instanceof Element) {
                                                        switch (domNode.name) {
                                                            case "img":
                                                                if (domNode.type === "tag") {
                                                                    return (
                                                                        <Figure key={uuid()}>
                                                                            <Figure.Image alt={domNode.attribs.alt} src={domNode.attribs.src} />
                                                                        </Figure>
                                                                    );
                                                                }
                                                                break;

                                                            default:
                                                                // to suppress a warning about each child in a list should have a unique key prop
                                                                domNode.attribs.key = uuid();
                                                                break;
                                                        }
                                                    }
                                                },
                                                trim: true,
                                            });

                                        case "text/latex":
                                            return <MarkdownCell
                                                source={[entry.content]}
                                                key={index}
                                            />

                                        case "text/plain":
                                            return <pre
                                                key={index}
                                                className="mb-0 mt-0">
                                                {entry.content}
                                            </pre>

                                        case "image/png":
                                            return <Figure.Image
                                                src={`data:image/png;base64,${entry.content}`}
                                                key={index}
                                            />

                                        case "image/jpeg":
                                            return <Figure.Image
                                                src={`data:image/jpg;base64,${entry.content}`}
                                                key={index}
                                            />

                                        default:
                                            console.warn("Unsupported mime type");
                                            return null;
                                    }
                                })
                        }
                    </div>
                    <pre>
                        <Ansi>
                            {execution.hint}
                        </Ansi>
                    </pre>
                    <div ref={textHidden}>
                    </div>
                </MediaBody>
            </Media>

            <Modal show={query.querying} keyboard={false} backdrop="static">
                <Form onSubmit={input}>
                    <Modal.Header>
                        <Modal.Title>输入</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Label>
                            <pre>
                                <Ansi>
                                    {query.text}
                                </Ansi>
                            </pre>
                        </Form.Label>
                        <Form.Control
                            type={query.password ? "password" : "text"}
                            ref={textInput}
                            autoFocus={true}
                            required
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" type="submit">确认</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

