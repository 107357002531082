import React, { FunctionComponent, useContext, useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import { SoundPlayer } from "./soundplayers";
import * as StoryData from "./storydata";
import { storyResourceUrl } from "./storyresources";
import { StoryPreferenceContext, StoryResourcesContext, PlayingSoundsContext } from './storyshow';
import "./story.css";

export const rectValue = ((rect: number[], index: number, base: number) => {
    const num = rect.length < index ? -1 : rect[index];
    return Math.abs(num) <= 2 ? num * base : num < 0 ? undefined : num;
})

export const StorySubtitle: FunctionComponent<{
    className?: string,
    actionTalk: StoryData.StoryEventActionTalkType | null,
    targetLeft?: number,
    targetBottom?: number,
    sceneWidth: number,
    sceneHeight: number,
    targetSize: number[],
    followTarget?: boolean | false,
    onstage?: boolean,
    start?: string,
    duration?: string,
    emoticon?: StoryData.StoryEventActionEmoticonType,
    previewMode?: boolean,
    onClose?: () => void,
    closeButton?: string,
    enableVoice?: boolean,
    autoPlay?: boolean,
    isSoundPlaying?: boolean,
    onPlayerClicked?: () => void,
    onMouseOver?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onMouseOut?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}> = ((params) => {
    const currentStoryPreference = useContext(StoryPreferenceContext);
    const currentStoryResources = useContext(StoryResourcesContext);
    const currentPlayingSounds = useContext(PlayingSoundsContext);

    const refRoot = useRef<HTMLDivElement>(null);
    const refContent = useRef<HTMLDivElement>(null);
    const refEmoticon = useRef<HTMLDivElement>(null);
    const [appeared, setAppeared] = useState(false);
    const [actionTalk, setActionTalk] = useState(params.actionTalk);
    const [balloon, setBalloon] = useState<StoryData.StoryPopupType>();
    const [balloonFullSize, setBalloonFullSize] = useState(false);
    const [balloonDirection, setBalloonDirection] = useState<string>();
    const [emoticon, setEmoticon] = useState<StoryData.StoryEventActionEmoticonType>();
    const [emoticonDirection, setEmoticonDirection] = useState<string>();
    useEffect(() => {
        const actionTalk2 = params.actionTalk;
        setActionTalk(actionTalk2);
        const balloonIndex = actionTalk2?.popup ?? -1;
        const popups = currentStoryResources.state.popups;
        const balloon2 = !actionTalk2 || balloonIndex < 0 || !popups || balloonIndex >= popups.length ? undefined : popups[balloonIndex];
        setBalloon(balloon2);
        const balloonFullSize2 = balloon2?.fullSize ?? false;
        setBalloonFullSize(balloonFullSize2);
        setBalloonDirection(balloon2?.direction ? balloon2?.direction.toUpperCase() : balloonFullSize2 ? "DOWN" : "RIGHT");
        const emoticon2 = params.emoticon ?? balloon2?.emoticon;
        setEmoticon(emoticon2);
        setEmoticonDirection(emoticon2?.direction?.toUpperCase());
    }, [params, currentStoryResources]);

    useEffect(() => {
        const sound = storyResourceUrl(emoticon?.sound);
        const a = new Audio(sound);
        a.loop = false;
        a.play().then(result => {
            console.log(sound, result);
        }).catch((result) => {
            console.log(sound, result);
        });
        return () => {
            // a.pause();
        }
    }, [emoticon?.sound]);

    useEffect(() => {
        if (balloonFullSize) {
            setAppeared(true);
        }
    }, [balloonFullSize]);

    useEffect(() => {
        let divContent = refContent.current;
        let divEmoticon = refEmoticon.current;
        if (appeared) {
            setAppeared(false);
            setImmediate(() => {
                if (balloonFullSize) {
                    if (divContent) {
                        divContent.classList.replace(`reset${balloonDirection}`, `going${balloonDirection}`);
                    }
                }
                if (divEmoticon) {
                    divEmoticon.classList.replace(`reset${emoticonDirection}`, `going${emoticonDirection}`);
                }
            });
        }
    }, [appeared, balloonFullSize, balloonDirection, emoticon, emoticonDirection]);

    const {
        defaultSceneWidth,
        defaultSceneHeight,
        effectiveSceneWidth,
        effectiveSceneHeight,
        effectiveSceneWidthUnit,
        effectiveSceneHeightUnit,
    } = currentStoryPreference.state;
    const scaleToDefaultSize = [
        (effectiveSceneWidth / defaultSceneWidth),
        (effectiveSceneHeight / defaultSceneHeight),
    ];
    const balloonImage = balloon?.source ?? balloon?.bgImage ?? "";
    const balloonImageSize = balloon?.bgImageSize;
    const balloonImagePosition = balloon?.bgImagePosition;
    const balloonBgColor = balloon?.bgColor ?? "transparent";
    const targetWidth = !params.targetSize || params.targetSize.length <= 0 ? 0 : params.targetSize[0];
    const targetHeight = !params.targetSize || params.targetSize.length <= 1 ? 0 : params.targetSize[1];
    const spaceLeft = params.targetLeft ?? 0;
    const spaceRight = params.sceneWidth - spaceLeft - targetWidth;
    const spaceBottom = params.targetBottom ?? 0;
    const spaceTop = params.sceneHeight - spaceBottom - targetHeight;
    const balloonOffset = actionTalk?.offset ?? [0, 0];
    const balloonOffsetVertical = balloonOffset.length < 1 ? 0 : balloonOffset[0] * scaleToDefaultSize[0];
    const balloonOffsetHorizontal = balloonOffset.length < 2 ? 0 : balloonOffset[1] * scaleToDefaultSize[1];
    const enoughLeft = spaceLeft > targetWidth;
    const enoughRight = spaceRight > targetWidth;
    const enoughTop = spaceTop > targetHeight;
    const enoughBottom = spaceBottom > targetHeight;
    const preferLeft = !params.followTarget && spaceLeft > spaceRight;
    const preferRight = !params.followTarget && spaceRight > spaceLeft;
    const preferHCenter = spaceLeft === spaceRight;
    // const preferTop = !params.followTarget && spaceTop > spaceBottom;
    const preferBottom = !params.followTarget && spaceBottom > spaceTop;
    const onTop = !params.followTarget && enoughTop;
    const onLeft = !params.followTarget && !onTop && (!preferRight && enoughLeft);
    const onRight = !params.followTarget && !onTop && (preferRight && enoughRight);
    const onBottom = !params.followTarget && !onTop && !onLeft && !onRight && enoughBottom;
    const contentPaddingTop = (balloon?.padding && balloon?.padding?.length > 0) ? balloon?.padding[0] : 10;
    const contentPaddingRight = (balloon?.padding && balloon?.padding?.length > 1) ? balloon?.padding[1] : contentPaddingTop;
    const contentPaddingBottom = (balloon?.padding && balloon?.padding?.length > 2) ? balloon?.padding[2] : contentPaddingTop;
    const contentPaddingLeft = (balloon?.padding && balloon?.padding?.length > 3) ? balloon?.padding[3] : contentPaddingRight;
    const contentPadding = [
        contentPaddingTop * scaleToDefaultSize[1],
        contentPaddingRight * scaleToDefaultSize[0],
        contentPaddingBottom * scaleToDefaultSize[1],
        contentPaddingLeft * scaleToDefaultSize[0],
    ];

    const tailSize = (balloon?.tailSize ?? 0) * scaleToDefaultSize[0];
    const tailWidth = 20 * scaleToDefaultSize[0];
    const balloonBorderColor = "#000";
    const balloonMaxWidth = balloon?.maxWidth ? balloon?.maxWidth * scaleToDefaultSize[0] : (onTop ? params.sceneWidth : (onLeft || onRight) ? Math.max(spaceLeft, spaceRight) : enoughBottom ? params.sceneWidth : -1);
    const balloonMaxHeight = balloon?.maxHeight ? balloon?.maxHeight * scaleToDefaultSize[1] : (onTop ? spaceTop : (enoughLeft || enoughRight) ? targetHeight : enoughBottom ? spaceBottom : -1);
    const balloonRect = !balloon?.rect || balloon?.rect?.length < 4 ? undefined : {
        left: rectValue(balloon?.rect, 0, params.sceneWidth) ?? 0,
        top: rectValue(balloon?.rect, 1, params.sceneHeight) ?? 0,
        width: rectValue(balloon?.rect, 2, params.sceneWidth) ?? params.sceneWidth,
        height: rectValue(balloon?.rect, 3, params.sceneHeight) ?? params.sceneHeight,
    };
    const magnifiedRatio = true === params.previewMode ? 1 : currentStoryPreference.state.magnifiedRatio;
    const magnifiedRect = currentStoryPreference.state.magnifiedRect ?? { left: 0, top: 0, width: params.sceneWidth, height: params.sceneHeight };
    const sceneOffset = true === params.previewMode ? { left: 0, top: 0 } : currentStoryPreference.state.sceneOffset;
    const unmagnifiedRatio = 1 / magnifiedRatio;
    const offsetTop = (params.sceneHeight - magnifiedRect.top - magnifiedRect.height);
    const balloonLeft = !balloonRect ? undefined : (((balloonRect.left ?? 0) - (sceneOffset?.left ?? 0)) + effectiveSceneWidthUnit);
    const balloonTop = !balloonRect ? undefined : ((balloonRect.top - offsetTop) + effectiveSceneHeightUnit);
    const balloonWidth = balloonRect ? (balloonRect.width + effectiveSceneWidthUnit) : balloonFullSize ? (params.sceneWidth + effectiveSceneWidthUnit) : (onTop || onBottom) ? "200%" : (2 * Math.max(targetWidth, targetHeight) + effectiveSceneWidthUnit);
    const balloonHeight = !balloonRect || balloonRect.height < 0 ? undefined : (balloonRect.height + effectiveSceneHeightUnit);
    const balloonStyle: React.CSSProperties = {
        display: !actionTalk ? "none" : (params.duration === "auto" && !params.isSoundPlaying) ? "none" : undefined,
        transform: balloonRect ? `scale(${unmagnifiedRatio}, ${unmagnifiedRatio})` : (!balloonFullSize && (onTop || onBottom) && preferHCenter) ? "translateX(-50%)" : "",
        top: balloonTop ? (balloonRect?.top ?? 0) < 0 ? undefined : balloonTop : (balloonFullSize) ? 0 : (params.followTarget || !enoughBottom || (!preferBottom)) ? undefined : ((balloonOffsetVertical + targetHeight) + effectiveSceneHeightUnit),
        left: balloonLeft ? balloonLeft : (balloonFullSize || ((onTop || onBottom) && preferRight)) ? 0 : ((onTop || onBottom) && preferHCenter) ? "50%" : (params.followTarget || onLeft || ((onTop || onBottom) && preferLeft)) ? undefined : ((balloonOffsetHorizontal + targetWidth) + effectiveSceneWidthUnit),
        right: balloonDirection === "FIXED" ? undefined : (balloonFullSize || ((onTop || onBottom) && preferLeft)) ? 0 : (params.followTarget || onRight || ((onTop || onBottom) && (!preferLeft))) ? undefined : ((balloonOffsetHorizontal + targetWidth) + effectiveSceneWidthUnit),
        bottom: balloonDirection === "FIXED" ? (balloonRect?.top ?? 0) < 0 ? offsetTop + effectiveSceneHeightUnit : undefined : (balloonFullSize) ? 0 : (params.followTarget || !enoughTop || (preferBottom && enoughBottom)) ? undefined : ((balloonOffsetVertical + targetHeight + tailSize) + effectiveSceneHeightUnit),
        width: balloonFullSize ? undefined : balloonWidth,
        height: balloonFullSize ? undefined : balloonHeight,
        maxWidth: (balloonFullSize || balloonDirection === "FIXED") ? undefined : balloonMaxWidth < 0 ? "none" : balloonMaxWidth + effectiveSceneWidthUnit,
        maxHeight: (balloonFullSize || balloonDirection === "FIXED") ? undefined : balloonMaxHeight < 0 ? "none" : balloonMaxHeight + effectiveSceneHeightUnit,
        borderColor: balloonBorderColor,
        zIndex: 9999
    };
    const contentOnLeft = balloonFullSize || params.followTarget === true || preferLeft;
    const contentOnRight = balloonFullSize || params.followTarget === true || preferRight;

    const cssTransformInner = magnifiedRatio === 1 ? undefined : `translateY(calc((100% + 0${effectiveSceneHeightUnit}) / 3.6 * ${magnifiedRatio}))`;
    return (<div ref={refRoot} className={params.className ? params.className : balloonRect || balloonImage !== "" ? "characterDialogueCustom" : preferHCenter ? "characterDialogueHCenter" : preferRight ? "characterDialogueToRight" : "characterDialogueToLeft"}
        onMouseOut={params.onMouseOut}
        onMouseOver={params.onMouseOver}
        style={balloonStyle}>
        <div className="character-dialogue-inner"
            style={{
                position: !balloonFullSize ? undefined : "fixed",
                top: 0, bottom: 0, left: 0, right: 0,
                transform: cssTransformInner
            }}
        >
            <div className="characterDialogueBalloon" data-scene-height={params.sceneHeight} data-m-top={magnifiedRect.top} data-m-height={magnifiedRect.height}
                style={{
                    position: "absolute",
                    display: balloon ? undefined : "none",
                    ...balloonImage && {
                        backgroundSize: balloonImageSize ?? "100% 100%",
                        backgroundImage: `url(${storyResourceUrl(balloonImage)})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: balloonImagePosition ?? "center center",
                    },
                    backgroundColor: balloonBgColor,
                    transform: balloonFullSize ? "" : (balloonDirection === "RIGHT" && onRight) ? "none" : "scale(-1, 1)",
                    width: "100%",
                    height: "100%",
                    borderBottom: "0 none transparent",
                }}
            />
            <div className="characterDialogureBalloonBorderBottomLeft" style={{
                display: balloon ? undefined : "none",
                backgroundColor: balloonBorderColor,
                left: `${5 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
                right: !preferLeft ? undefined : `${60 * scaleToDefaultSize[1] + tailWidth}${effectiveSceneHeightUnit}`,
                width: preferHCenter ? "50%" : !preferRight ? undefined : `${55 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
            }} />
            <div className="characterDialogureBalloonBorderBottomRight" style={{
                display: balloon ? undefined : "none",
                backgroundColor: balloonBorderColor,
                left: !preferRight ? undefined : `${60 * scaleToDefaultSize[1] + tailWidth}${effectiveSceneHeightUnit}`,
                right: `${5 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
                width: preferHCenter ? "50%" : !preferLeft ? undefined : `${55 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
            }} />
            <div className="characterDialogureBalloonTail" style={{
                display: balloon ? undefined : "none",
                borderLeftColor: !preferRight ? undefined : balloonBgColor,
                borderRightColor: !preferLeft ? undefined : balloonBgColor,
                borderLeftWidth: !preferHCenter && !preferRight ? undefined : tailWidth + effectiveSceneHeightUnit,
                borderRightWidth: !preferHCenter && !preferLeft ? undefined : tailWidth + effectiveSceneHeightUnit,
                borderBottom: !preferHCenter ? `${tailSize}${effectiveSceneHeightUnit}` : `${tailWidth}${effectiveSceneHeightUnit} solid ${balloonBgColor}`,
                left: preferHCenter ? "50%" : preferLeft ? undefined : `${60 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
                right: preferHCenter || preferRight ? undefined : `${60 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
                transform: !preferHCenter ? undefined : "translateX(-50%) scale(1,-1)",
            }}>
                <div className="characterDialogureBalloonTailLeftSide" style={{
                    backgroundColor: balloonBorderColor,
                    top: preferRight ? undefined : `${-1 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
                    left: `${preferRight ? -tailWidth : 0 * scaleToDefaultSize[1] + tailWidth / 2}${effectiveSceneHeightUnit}`,
                    height: `${preferRight ? tailSize : Math.sqrt(tailSize * tailSize + tailWidth * tailWidth)}${effectiveSceneHeightUnit}`,
                    transform: preferRight ? undefined : `rotate(${-180 / Math.PI * Math.atan(tailWidth / tailSize)}deg)`
                }} />
                <div className="characterDialogureBalloonTailRightSide" style={{
                    backgroundColor: balloonBorderColor,
                    top: preferLeft ? undefined : `${-1 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
                    right: `${preferLeft ? -tailWidth : 0 * scaleToDefaultSize[1] + tailWidth / 2}${effectiveSceneHeightUnit}`,
                    height: `${preferLeft ? tailSize : Math.sqrt(tailSize * tailSize + tailWidth * tailWidth)}${effectiveSceneHeightUnit}`,
                    transform: preferLeft ? undefined : `rotate(${180 / Math.PI * Math.atan(tailWidth / tailSize)}deg)`
                }} />
            </div>
            <div ref={refContent} className={"characterDialogueContent" + (!balloonFullSize || !balloonDirection ? "" : ` reset${balloonDirection}`)}
                style={{
                    marginLeft: balloonDirection === "FIXED" || enoughTop || enoughBottom || contentOnLeft ? undefined : tailSize + effectiveSceneWidthUnit,
                    marginRight: balloonDirection === "FIXED" || enoughTop || enoughBottom || contentOnRight ? undefined : tailSize + effectiveSceneWidthUnit,
                    // marginBottom: !enoughTop ? undefined : tailSize,
                    // marginTop: !enoughBottom ? undefined : tailSize,
                    position: "relative",
                    textAlign: balloon?.textAlign ?? "center",
                    display: balloonFullSize ? "-webkit-flex" : undefined,
                    justifyContent: balloonFullSize ? "center" : undefined,
                    alignItems: balloonFullSize ? "center" : undefined,
                    width: balloonDirection === "FIXED" ? "100%" : undefined,
                    height: balloonDirection === "FIXED" ? "100%" : undefined,
                }}
            >
                <div style={{
                    position: balloonFullSize ? "absolute" : undefined,
                    fontFamily: balloon?.fontFamily,
                    fontSize: !balloon?.fontSize ? undefined : `${parseFloat(balloon?.fontSize + "") * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
                    color: balloon?.fontColor,
                    padding: contentPadding.map((v, i) => {
                        const forWidth = (i === 1 || i === 3);
                        return `${v}${(forWidth ? effectiveSceneWidthUnit : effectiveSceneHeightUnit)}`;
                    }).join(" "),
                }}>
                    {!actionTalk ? null : actionTalk.text?.split("\n").map((one, idx) => <p key={idx}>{one}</p>)}
                    {!currentPlayingSounds.state.waiting.includes(actionTalk?.voice ?? -1) ? null : <SoundPlayer index={actionTalk?.voice} style={{ width: `${50 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`, height: `${50 * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}` }} />}
                </div>
                {!balloonFullSize || !params.closeButton ? null :
                    <Button style={{
                        position: "absolute",
                        bottom: "30%",
                    }} onClick={() => {
                        params.onClose && params.onClose();
                    }}>{params.closeButton}</Button>}
            </div>
            {
                <div ref={refEmoticon} className={"story-dialgure-emoticon" + (!emoticonDirection ? "" : ` reset${emoticonDirection}`)} style={{
                    marginLeft: contentOnLeft ? 0 : balloon?.tailSize ?? 10,
                    marginRight: contentOnRight ? 0 : balloon?.tailSize ?? 10,
                    paddingLeft: contentOnLeft ? undefined : "50%",
                    paddingRight: contentOnRight ? undefined : "50%",
                }}>
                    <div className="story-dialgure-emoticon-image" style={{
                        width: 64,
                        height: 64,
                        overflow: "hidden",
                        transform: emoticon?.transform,
                    }}>
                        {
                            !emoticon?.image ? null :
                                <img alt="emoticon" src={`${storyResourceUrl(emoticon?.image)}`} style={{
                                    width: "100%",
                                    height: "100%",
                                }} />
                        }
                    </div>
                </div>
            }
        </div>
    </div>);
})

export default StorySubtitle;