import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { materialService, ScholarInfo } from "../service";

const ScholarList: FunctionComponent = () => {
    const [scholars, setScholars] = useState<ScholarInfo[]>();

    useEffect(() => {
        console.log("Loading scholars");

        const getScholars = async () => {
            const response = await materialService().get(`/scholar`);

            switch (response.status) {
                case 200:
                    const result = response.data as ScholarInfo[];
                    setScholars(result);
                    break;
                default:
                    console.warn("unexpected behavior, please contact site admin");
                    break;
            }
        }
        getScholars();
    }, []);

    return (
        <Container>
            <CardGroup>
                <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                    {
                        scholars?.map((scholar, index) =>
                            <Col key={scholar.id}>
                                <Card
                                    key={index}
                                    className="mt-4"
                                    style={{ minWidth: "18rem", maxWidth: "22rem" }}>
                                    <Card.Img variant="top" src={scholar.thumbnail} style={{ objectFit: "cover" }} />
                                    <Card.Body>
                                        <Card.Title>
                                            <Link to={`/scholar/${scholar.id}`} style={{ textDecoration: "none" }}>
                                                {scholar.title}
                                            </Link>
                                        </Card.Title>
                                        <Card.Text>
                                            {scholar.summary}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
            </CardGroup>
        </Container>
    )
}

export { ScholarList };
