export enum ErrorType {
    SyntaxError = "SyntaxError",
    NameError = "NameError",
    IndentationError = "IndentationError",
    ValueError = "ValueError",
    RecursionError = "RecursionError",
    KeyError = "KeyError",
    TypeError = "TypeError",
    IndexError = "IndexError",
    FileNotFoundError = "FileNotFoundError",
    AttributeError = "AttributeError",
    UnicodeError = "UnicodeError",
    AssertionError = "AssertionError",
    TabError = "TabError",
    KeyboardInterrupt = "KeyboardInterrupt",
    TimeoutInterrupt = "TimeoutInterrupt",
    ModuleNotFoundError = "ModuleNotFoundError",
};

export const ErrorCode: Record<ErrorType, string> = {
    SyntaxError: "代码的语法出错了，请根据错误提示定位并改正。",
    NameError: "变量未定义导致无法识别，请先定义变量再进行使用。",
    IndentationError: "缩进格式错误，请确保正确 缩进空格且空格字符需要前后一致。",
    ValueError: "数值错误，请不要调用不存在的值。",
    RecursionError: "递归错误，请检查是否导致了无穷递归。",
    KeyError: "字典中指定的键不存在，请确保键和值的对应已创建再查找。",
    TypeError: "参数的类型错误，请根据提示进行数据类型转换。",
    IndexError: "索引错误，请确保读取某个下标的元素的时候不要越界。",
    FileNotFoundError: "文件找不到错误，请确保操作文件时路径是正确的。",
    AttributeError: "属性错误，请确保调用已存在的对象方法或属性时。",
    UnicodeError: "万国码错误，请确保指定正确的编码类型。",
    AssertionError: "断言语句失败，请修改逻辑以避免触发断言",
    TabError: "制表符和空格混用，请坚持使用制表符或者空格来缩进。",
    KeyboardInterrupt: "用户中断执行",
    TimeoutInterrupt: "代码执行超时",
    ModuleNotFoundError: "模块引入错误，请检查模块名时候正确",
};