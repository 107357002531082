import { FunctionComponent, MouseEvent, useContext } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { BsGrid3X3Gap } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { KernelManager } from "../kernel";
import { PassportContext } from "../passport";
import { ExplanationToggle } from "./explanation";
import { SoundEffect } from "./soundeffect";
import { UserComponent } from "./user";

export const Navigator: FunctionComponent = () => {
    const { passport } = useContext(PassportContext);
    const navigate = useNavigate();

    const home = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        window.open("https://www.codemage.cn");
    }

    return (
        <Navbar bg="primary" variant="dark" expand="lg">
            <Container>
                <Nav.Link onClick={home} style={{ cursor: "pointer" }}>
                    <BsGrid3X3Gap size={22} color="white" className="my-1" />
                </Nav.Link>

                <Nav.Link onClick={() => navigate("/")} className="ps-0 pe-2">
                    <Navbar.Brand>
                        学习
                    </Navbar.Brand>
                </Nav.Link>

                <Navbar.Toggle aria-controls="mainframe" />

                <Navbar.Collapse id="mainframe">
                    <Nav className="me-auto">
                        <Nav.Link onClick={() => navigate("/course")}>
                            教程
                        </Nav.Link>

                        <Nav.Link onClick={() => navigate("/project")}>
                            项目
                        </Nav.Link>

                        <Nav.Link onClick={() => navigate("/knowledge")}>
                            知识
                        </Nav.Link>

                        <Nav.Link onClick={() => navigate("/scholar")}>
                            人物
                        </Nav.Link>

                        <Nav.Link onClick={() => navigate("/study")}>
                            研究
                        </Nav.Link>

                        {
                            passport.authenticated &&
                            <Nav.Link onClick={() => navigate("/circle")}>
                                课程
                            </Nav.Link>
                        }
                    </Nav>
                    <Nav>
                        <SoundEffect />
                        <ExplanationToggle />
                        <KernelManager />
                        <UserComponent />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}