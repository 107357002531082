import React, { FunctionComponent, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PassportContext } from "../passport";

const PrivateRoute: FunctionComponent = ({ children }) => {
    const location = useLocation();
    const { passport } = useContext(PassportContext);

    return (
        passport.authenticated ?
            <>
                {children}
            </>
            :
            <Navigate to="/login" state={{ from: location }} />
    );
}

const RestrictedRoute: FunctionComponent = ({ children }) => {
    const { passport } = useContext(PassportContext);

    return (
        passport.authenticated ?
            <Navigate to="/" />
            :
            <>
                {children}
            </>
    );
}

export { PrivateRoute, RestrictedRoute };
