import React, { FunctionComponent, HTMLAttributes } from "react";

const Media: FunctionComponent<HTMLAttributes<HTMLDivElement>> = (params) => {
    return (
        <div
            className={`${params.className} d-flex`}
        >
            {params.children}
        </div>
    );
}

const MediaHead: FunctionComponent<HTMLAttributes<HTMLDivElement>> = (params) => {
    return (
        <div
            className={`${params.className} flex-shrink-0`}
        >
            {params.children}
        </div>
    );
}

const MediaBody: FunctionComponent<HTMLAttributes<HTMLDivElement>> = (params) => {
    return (
        <div
            className={`${params.className} flex-grow-1 ms-3`}
        >
            {params.children}
        </div>
    );
}

export { Media, MediaHead, MediaBody };
