import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { MarkdownCell } from "../markdown";
import { Notebook } from "../recipe";
import { materialService, RecipeInfo } from "../service";

interface LessonReviewParams {
    id?: number;
    tags?: string[];
}

export const LessonIntro: FunctionComponent<LessonReviewParams> = (props) => {
    const [notebook, setNotebook] = useState<Notebook>();

    const getIntro = useCallback(async (id?: number) => {
        const response = await materialService().get(`/intro/${id}`);

        switch (response.status) {
            case 200:
                const recipe = response.data as RecipeInfo;
                setNotebook(JSON.parse(recipe.content || "{\"cells\": []}") as Notebook);
                break;

            default:
                console.warn("unexpected behavior, please contact site admin");
                break;
        }
    }, []);

    useEffect(() => {
        if (props.id) {
            getIntro(props.id);
        }
    }, [props.id, getIntro]);

    return (
        <>
            {
                notebook?.cells.map((cell, index) =>
                    cell.cell_type === "markdown" &&
                    <MarkdownCell key={index} source={cell.source} />
                )
            }
            标签：
            {
                props.tags?.map(tag =>
                    <Badge bg="info" key={tag} className="mx-1">{tag}</Badge>
                )
            }
        </>
    );
}