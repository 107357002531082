import React, { FunctionComponent } from "react";

interface SectionTitleParams {
    title: string;
}

export const SectionTitle: FunctionComponent<SectionTitleParams> = (props) => {
    return (
        <h2 className="mt-5 mb-5">
            {props.title}
        </h2>
    );
}