import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProjectInfo } from ".";
import { LearnFooter } from "../navigator";
import { materialService } from "../service";

export const ProjectList: FunctionComponent = () => {
    const [projects, setProjects] = useState<ProjectInfo[]>();

    useEffect(() => {
        const getProjects = async () => {
            const response = await materialService().get(`/project/`);

            switch (response.status) {
                case 200:
                    setProjects(response.data as ProjectInfo[]);
                    break;

                default:
                    console.warn("unexpected code path");
                    break;
            }
        }
        getProjects();

    }, []);

    return (
        <Container>
            <CardGroup>
                <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                    {
                        projects?.map(project =>
                            <Col key={project.id}>
                                <Card key={project.id} className="mt-4">
                                    <Card.Img variant="top" src={project.thumbnail} style={{ objectFit: "cover" }} />
                                    <Card.Body>
                                        <Card.Title>
                                            <Link to={`/recipe/${project.id}`} style={{ textDecoration: "none" }}>
                                                {project.title}
                                            </Link>
                                        </Card.Title>
                                        <Card.Text>{project.summary}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
            </CardGroup>

            <LearnFooter />
        </Container>
    )
}
