import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Jumbotron, Media, MediaBody, MediaHead } from "../navigator";

export const WelcomeComponent: FunctionComponent = () => {

    return (
        <>
            <Image src="https://resource.codemage.cn/people-sitting-down-with-laptop.jpg" fluid />
            <Container className="mt-5 mb-5">
                <h1>计算思维</h1>

                <p>
                    如何绘制人类完整的DNA？是否能创造人工智能可以自主作曲？这些问题的共性需要使用所谓的计算思维，每天有越来越多的人使用这种思维方式来解决问题。
                </p>

                <p>
                    那什么是计算思维呢？2006年3月，美国卡内基·梅隆大学计算机科学系主任周以真教授因提出并倡导计算思维（Computational Thinking）而享誉计算机科学界。周教授认为，计算思维是运用计算机科学的基础概念进行问题求解、系统设计、以及人类行为理解等涵盖计算机科学之广度的一系列思维活动。在她看来，计算思维是一种普适思维方法和基本技能，所有人都应该积极学习并使用，而非仅限于计算机科学家。
                </p>

                <Media>
                    <MediaHead>
                        <Image className="me-3"
                            src="https://resource.codemage.cn/jeannette-m-wing.jpg"
                            alt="Jeannette M. Wing"
                        />
                    </MediaHead>
                    <MediaBody>
                        <h5>周以真</h5>
                        <p>
                            周以真，Jeannette M. Wing，美国计算机科学家。卡内基梅隆大学教授。美国国家自然基金会计算与信息科学工程部助理部长。ACM和IEEE会士。她的主要研究领域是形式化方法、可信计算、分布式系统、编程语言等。1993年她与图灵奖得主芭芭拉·利斯科夫合作，提出了著名的里氏替换原则（Liskov Substitution Principle），是面向对象基本原则之一。
                        </p>
                    </MediaBody>
                </Media>

                <p>
                    计算思维包含以下四个核心步骤：
                </p>

                <ol>
                    <li>
                        拆解：将一个问题进行解构，厘清各个部分，从而达到分而治之的目的。
                    </li>
                    <li>
                        模式：找出各个部分的相异同点，以便进行预测。
                    </li>
                    <li>
                        归纳：找出这些模式背后的一般规律或准则。
                    </li>
                    <li>
                        算法 ：提出能解决类似问题并能一步步重复实施的做法。
                    </li>
                </ol>

                <p>
                    计算生物学、计算化学、计算社学会等等交叉科学都得益于计算思维。
                </p>

                <p>
                    需要注意的是，计算思维与计算机科学并不完全等同，后者包括了可计算性以及各种计算机应用的开发，而前者是关于解决问题的抽象思维方法，在解决复杂或者开放性问题的时候特别有用，适合任何学科背景或年龄段的学生。
                </p>

                <p>
                    假设你是一个记者，想要了解人们对即将到来的竞选所持的观点。一种方法是到街上进行采访，询问他们的想法；另一种方法是写程序去分析社交媒体上成千上万的推文。盖洛普公司通过数理统计加电话访谈来预测美国大选，输给了收集社交媒体并分析舆情的人工智能公司，这是一个能够体现计算思维优越性的很好的例子。运用科技的力量，我们能够更将更多的注意力放在对数据的研究上，增强洞察力并得出正确结论。
                </p>

                <p>
                    计算思维已受到越来越多的关注，其涉及的领域也将越来越广。逐步推广编程教育，鼓励社会力量参与寓教于乐的编程教学软件的开发和推广，才能最终实现全民计算思维的培养和全民智能教育。
                </p>

            </Container>
            <Jumbotron>
                <Container>
                    &copy; CodeMage
                    <a href="https://beian.miit.gov.cn" className="ms-3 text-decoration-none">沪ICP备15039555号</a>
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202012466" className="ms-3 text-decoration-none">沪公网安备 31011202012466号</a>
                </Container>
            </Jumbotron>
        </>
    )

}
