import { FunctionComponent, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

interface Params {
    pages: Array<string>;
    caption: string;
}

export const ComicCell: FunctionComponent<Params> = ({ pages, caption }) => {
    const [index, setIndex] = useState(0);

    const select = (selected: number) => {
        setIndex(selected);
    };

    return (
        <Carousel
            activeIndex={index}
            onSelect={select}
            interval={null}
            className="mb-4"
            wrap={false}
        >
            {
                pages.map((item, index) => (
                    <Carousel.Item key={item}>
                        <Image
                            className="d-block w-100"
                            key={index}
                            src={item}
                            alt={item}
                        />
                        <Carousel.Caption>
                            {caption}
                        </Carousel.Caption>
                    </Carousel.Item>)
                )
            }
        </Carousel>
    )
}