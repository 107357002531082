import React, { FunctionComponent, useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { VscMute, VscUnmute } from "react-icons/vsc";
import useSound from "use-sound";
import { AssistantActionType, AssistantContext } from "../assistant";

const SoundEffect: FunctionComponent = () => {
    const { assistant, dispatch } = useContext(AssistantContext);

    const [down] = useSound("https://resource.codemage.cn/pop-down.mp3");
    const [upOn] = useSound("https://resource.codemage.cn/pop-up-on.mp3");
    const [upOff] = useSound("https://resource.codemage.cn/pop-up-off.mp3");

    const toggle = () => {
        dispatch({
            type: AssistantActionType.SOUND
        });
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="submit">音效开关</Tooltip>}
        >
            <Button variant="link"
                onClick={toggle}
                onMouseDown={() => down()}
                onMouseUp={() => assistant.sound ? upOff() : upOn()}
            >
                {
                    assistant.sound ?
                        <VscUnmute size={24} style={{ color: "white" }} />
                        :
                        <VscMute size={24} style={{ color: "white" }} />
                }
            </Button>
        </OverlayTrigger>
    );
}

export { SoundEffect };
