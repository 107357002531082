import assert from "assert";
import * as monaco from "monaco-editor";
import { FunctionComponent, useContext, useEffect, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { CodeActionType, CodeContext } from "./codecontext";


interface Params {
    readOnly: boolean;
    viewOnly: boolean;
}

export const MonacoEditor: FunctionComponent<Params> = ({ readOnly, viewOnly }) => {
    const { code, dispatch } = useContext(CodeContext);

    const [height, setHeight] = useState<number>();

    const editor = useRef<monaco.editor.IStandaloneCodeEditor>();
    const container = useRef<HTMLDivElement>(null);
    const resized = useRef<monaco.IDisposable>();
    const readonly = useRef<monaco.IDisposable>();
    const changed = useRef<monaco.IDisposable>();

    const { width } = useResizeObserver<HTMLDivElement>({ ref: container });

    /**
     * Create monaco editor instance just once.
     * 
     * Language model and code content are updated separately.
     */
    useEffect(() => {
        assert(container.current, "container should be ready now");

        editor.current = monaco.editor.create(container.current, {
            automaticLayout: false,
            scrollBeyondLastLine: false,
            overviewRulerLanes: 0,
            cursorSurroundingLines: 1,
            selectOnLineNumbers: true,
            lineNumbers: "on",
            glyphMargin: false,
            minimap: {
                enabled: false,
            },
            fontFamily: "Monaco, Consolas",
            fontSize: 14,
            dimension: {
                width: 100,
                height: 200,
            },
            padding: {
                top: 5,
                bottom: 5,
            },
            contextmenu: false,
        });

        // Get the height of the content area so that we can resize the monaco editor proactively. Notice that the automatic layout
        // option is implemented by installing an interval that measures the container's size every 100 ms to measure its container.
        // So we'll need to collect the height to adjust the size of monaco editor proactively.
        resized.current = editor.current?.onDidContentSizeChange((event: monaco.editor.IContentSizeChangedEvent) => {
            if (event.contentHeightChanged) {
                setHeight(event.contentHeight);
            }
        });

        // Notify the user that the cell is readonly via the tooltip. If we don't change this behavior, english warning message
        // will be prompted to the user.
        readonly.current = editor.current?.onDidAttemptReadOnlyEdit(() => {
            const contribution = editor.current?.getContribution("editor.contrib.messageController");
            (contribution as any).showMessage("只读内容不能更改", editor.current?.getPosition());
        })

        // Keep updating the source code to context store.
        changed.current = editor.current?.onDidChangeModelContent((event: monaco.editor.IModelContentChangedEvent) => {
            const value = editor.current?.getValue();

            dispatch({ type: CodeActionType.UPDATE, code: value || "" })
        })

        return () => {
            changed.current?.dispose();
            resized.current?.dispose();
            readonly.current?.dispose();

            editor.current?.dispose();
        }
    }, [code.id, dispatch]);

    /**
     * Create a new model only if it does not exist.
     * 
     * For example, when we double click on a markdown cell, an editor model is created for it. Once we go back to markdown preview
     * mode that doesn't use the editor, double clicking on the markdown cell will again instantiate a monaco editor. In that case,
     * we should rebind the previously created editor model for the markdown instead of recreating one. Monaco does not allow models
     * to be recreated with the same uri.
     */
    useEffect(() => {
        const uri = monaco.Uri.file(code.id);
        const model = monaco.editor.getModel(uri) ?? monaco.editor.createModel("", code.language, uri);

        model.updateOptions({
            indentSize: 4,
            tabSize: 4,
            insertSpaces: true,
            trimAutoWhitespace: true,
        });

        editor.current?.setModel(model);

        return () => {
            editor.current?.getModel()?.dispose();
        }
    }, [code.id, code.language]);

    /**
     * Update options for monaco editor.
     * 
     * For example, one can change the readonly options here.
     */
    useEffect(() => {
        editor.current?.onKeyDown((listeners) => {
            if (listeners.keyCode === monaco.KeyCode.KeyC && (listeners.metaKey || listeners.ctrlKey)) {
                if (viewOnly) {
                    listeners.preventDefault();
                }
            }
        });

        editor.current?.updateOptions({
            readOnly: readOnly
        });
    }, [code.id, readOnly, viewOnly]);

    /**
     * Resize the monaco editor whenever the width or height changes proactively.
     * 
     * As we know, setting `automaticLayout` to true will cause the editor to check the dimension of the parent element with a timer,
     * which may lead to potential performance issue. Also resizing the parent element will cause flickering when resizing the editor.
     * Therefore, changing the dimension of the editor proactively seems to be the only way.
     */
    useEffect(() => {
        assert(container.current);
        assert(editor.current);

        if (width && height) {
            editor.current.layout({ width: width, height: height });
        }
    }, [width, height]);

    /**
     * Update code content for the monaco editor.
     */
    useEffect(() => {
        assert(editor.current, "editor should be ready now");

        // avoid updating the content if they are identical
        if (code.current === editor.current.getValue()) {
            return;
        }

        // update the content and reserve the history as appropriate
        if (editor.current.getOption(monaco.editor.EditorOption.readOnly)) {
            editor.current.setValue(code.current);
        } else {
            editor.current.executeEdits("", [{
                range: editor.current.getModel()!.getFullModelRange(),
                text: code.current,
                forceMoveMarkers: true,
            }]);

            editor.current.pushUndoStop();
        }
    }, [code]);

    return (
        <div
            ref={container}
            style={{ width: "100%", height: `${height}px` }}
            className="my-4"
        />
    );
}
