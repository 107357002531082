import moment from "moment-timezone";
import React, { FunctionComponent, useReducer } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { AiOutlineUser } from "react-icons/ai";
import { ExecuteInput, Message } from "../message";
import { CodeContext, codeReducer, MonacoEditor } from "../monaco";
import { Media, MediaBody, MediaHead } from "../navigator";
import { SubmissionInfo } from "../service";

interface SubmissionParams {
    submission?: SubmissionInfo;
}

interface MessageParams {
    snippet?: string,
    message?: Message,
}

const grades: Record<number, string> = {
    0: "😐",
    1: "🙂",
    2: "😀",
    3: "🙁",
}

const hints: Record<number, string> = {
    0: "没意见",
    1: "做对啦",
    2: "太棒了",
    3: "需努力",
}

const TitleSection: FunctionComponent<SubmissionParams> = ({ submission }) => {
    const message = submission?.execute_input;
    const date = message?.header.date;
    const time = moment(date?.substring(0, date?.indexOf("."))).format("YYYY-MM-DD HH:mm:ss");

    return (
        <>
            <Media className="mt-5 mb-3 p-3" style={{ backgroundColor: "#f8f9fa" }}>
                <MediaHead>
                    <AiOutlineUser size={48} style={{ color: "grey" }} className="me-4" />
                </MediaHead>
                <MediaBody>
                    <Row>
                        <Col>用户：{submission?.username}</Col>
                        <Col>
                            时间：{time}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            操作：{submission?.id}
                        </Col>
                        <Col>
                            评分：
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-grade">{hints[submission?.grade || 0]}</Tooltip>}
                            >
                                <span role="img" aria-label="grade">{grades[submission?.grade || 0]}</span>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </MediaBody>
            </Media>
        </>
    );
}

const InputSection: FunctionComponent<MessageParams> = ({ snippet, message }) => {
    const content = message?.content as ExecuteInput;

    const [code, setCode] = useReducer(codeReducer, {
        id: "",
        language: "python",
        current: content?.code || "",
        origin: content?.code || "",
    });


    return (
        <CodeContext.Provider value={{ code, dispatch: setCode }}>
            <MonacoEditor
                readOnly={true}
                viewOnly={false}
            />
        </CodeContext.Provider>
    );
}

export const CodeSubmission: FunctionComponent<SubmissionParams> = ({ submission }) => {
    return (
        <>
            <TitleSection
                submission={submission}
            />

            <InputSection
                message={submission?.execute_input}
                snippet={submission?.snippet}
            />
        </>
    );
}
