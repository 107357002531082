import { FunctionComponent, useEffect, useState } from "react";
import { CardGroup, Container } from "react-bootstrap";
import { KnowledgeChapter } from ".";
import { LearnFooter } from "../navigator";
import { CourseInfo, materialService } from "../service";

const KnowledgeList: FunctionComponent = () => {
    const [base, setBase] = useState<CourseInfo>();

    useEffect(() => {
        const getCourses = async () => {
            const response = await materialService().get("/course/python-knowledge-base");

            switch (response.status) {
                case 200:
                    setBase(response.data as CourseInfo);
                    break;

                default:
                    console.warn("Failed to get chapters of knowledge base");
                    break;
            }
        }
        getCourses();

    }, []);

    return (
        <Container>
            <CardGroup>
                {
                    base?.chapters?.map(chapter =>
                        <KnowledgeChapter chapter={chapter} key={chapter.id} />
                    )
                }
            </CardGroup>

            <LearnFooter />
        </Container>
    );
}

export { KnowledgeList };
