import { useContext, useReducer } from "react";
import { CodeComponent } from "react-markdown/lib/ast-to-react";
import { v4 as uuid } from "uuid";
import { AssistantContext, DifficultyLevel } from "../assistant";
import { CodeContext, codeReducer, MonacoEditor } from "../monaco";
import { MermaidDiagram } from "./mermaiddiagram";

export const CodeBlock: CodeComponent = ({ inline, children, className }) => {
    const { assistant } = useContext(AssistantContext);

    const source = children.toString().trim();
    const language = className?.replace("language-", "");
    const magic = "px-1" // leave some space so that scroll bars won't show up

    const [code, setCode] = useReducer(codeReducer, {
        id: uuid(),
        language: language || "python",
        current: source,
        origin: source,
    });

    const render = () => {
        switch (language) {
            case "mermaid":
                return <MermaidDiagram definition={source} />
            default:
                return inline ?
                    <code className="font-weight-normal bg-light p-1 mx-1">
                        {source}
                    </code>
                    :
                    <CodeContext.Provider value={{ code, dispatch: setCode }}>
                        <div className={magic}>
                            <MonacoEditor
                                readOnly={true}
                                viewOnly={assistant.difficulty === DifficultyLevel.NORMAL}
                            />
                        </div>
                    </CodeContext.Provider>
        }
    }

    return render();
}
