import { HeadingComponent } from "react-markdown/lib/ast-to-react";

export const Heading: HeadingComponent = ({ level, children, ...props }) => {
    const heading = (level: number) => {
        switch (level) {
            case 1:
                return <></>;
            case 2:
                return <h2 className="mt-4 mb-4">{children}</h2>
            case 3:
                return <h3 className="mt-4 mb-4">{children}</h3>
            case 4:
                return <h4 className="mt-4 mb-4">{children}</h4>
            case 5:
                return <h5 className="mt-4 mb-4">{children}</h5>
            case 6:
                return <h6 className="mt-4 mb-4">{children}</h6>
            default:
                return null;
        }
    }

    return (
        heading(level)
    );
}
