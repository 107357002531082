import { FunctionComponent, useContext } from "react";
import { NavDropdown, NavItem } from "react-bootstrap";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AssistantContext, DifficultyLevel } from "../assistant";
import { PassportActionType, PassportContext } from "../passport";

export const UserComponent: FunctionComponent = () => {
    const { passport, dispatch } = useContext(PassportContext);
    const { assistant } = useContext(AssistantContext);
    const navigate = useNavigate();

    const icon = <AiOutlineUser size={24} style={{ color: "white" }} />;

    const logging = () => {
        if (passport.authenticated) {
            dispatch({
                type: PassportActionType.LOGOUT,
            })

            navigate("/");
        } else {
            navigate("/login");
        }
    }

    const difficulty = () => {
        switch (assistant.difficulty) {
            case DifficultyLevel.EASY:
                return "难度：简单";
            case DifficultyLevel.NORMAL:
                return "难度：普通";
            case DifficultyLevel.HARD:
                return "难度：困难";
        }
    }

    return (
        <NavDropdown title={icon} id="nav-dropdown-ai">
            <NavDropdown.Item disabled>
                用户
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>
                {passport.username}
            </NavDropdown.Item>
            <NavDropdown.Item>
                {
                    passport.authenticated &&
                    difficulty()
                }
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {
                passport.authenticated &&
                <NavDropdown.Item onClick={() => navigate("/achievement")}>
                    <NavItem>成就</NavItem>
                </NavDropdown.Item>
            }
            {
                !passport.authenticated &&
                <NavDropdown.Item onClick={() => navigate("/register")}>
                    <NavItem>注册</NavItem>
                </NavDropdown.Item>
            }
            <NavDropdown.Item onClick={logging}>
                {passport.authenticated ? "登出" : "登入"}
            </NavDropdown.Item>
        </NavDropdown>
    );
}
