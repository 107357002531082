import React, { ChangeEvent, FormEvent, FunctionComponent, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { passportService, UserInfo } from "../service";

const RegisterView: FunctionComponent = () => {
    const navigate = useNavigate();

    const [alert, setAlert] = useState<string>();
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState<UserInfo>({
        code: "",
        username: "",
        password: "",
        fullname: "",
    });

    const register = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity() === true) {
            const response = await passportService().post("/passport", user);

            switch (response.status) {
                case 200:
                    console.info("Passport created successfully");
                    navigate("/login");
                    break;
                case 401:
                    console.warn("Invalid code");
                    setAlert(`邀请码不存在，请联系客服！`);
                    break;
                case 409:
                    console.warn("Duplicate username");
                    setAlert(`用户名已存在，请换一个！`);
                    break;
                default:
                    console.warn("Something went wrong");
                    setAlert(`出错啦！错误代码${response.status}。`);
                    break;
            }
        } else {
            setValidated(true);
        }
    };

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setUser({
            ...user,
            [name]: value.trim(),
        });
    };

    return (
        <>
            <Container>
                <Row className="mt-5">
                    <Col xs={0} md={3}></Col>
                    <Col xs={12} md={6}>
                        <Alert
                            variant={"danger"}
                            show={alert ? true : false}
                            onClose={() => {
                                setAlert(undefined);
                            }}
                            dismissible>
                            {alert}
                        </Alert>

                        <h3 className="mb-5">
                            建立您的账户：
                        </h3>

                        <Form noValidate validated={validated} onSubmit={register}>
                            <Form.Control
                                required
                                autoComplete="off"
                                type="text"
                                name="code"
                                placeholder="邀请码"
                                className="mt-3 mb-3"
                                value={user.code}
                                pattern="[A-Z][0-9]{6,10}"
                                title="邀请码格式不正确"
                                onChange={change}
                            />

                            <Form.Control
                                required
                                autoComplete="off"
                                type="text"
                                name="username"
                                placeholder="用户名"
                                className="mt-3 mb-3"
                                value={user.username}
                                pattern="[A-Za-z][A-Za-z0-9]{5,19}"
                                title="用户名允许英文字母和数字且在6至20个字符之间"
                                onChange={change}
                            />

                            <Form.Control
                                required
                                type="password"
                                name="password"
                                placeholder="密码"
                                className="mt-3 mb-3"
                                value={user.password}
                                pattern=".{6,20}"
                                title="密码要在6至20个字符之间"
                                onChange={change}
                            />

                            <Form.Control
                                required
                                type="text"
                                name="fullname"
                                placeholder="昵称"
                                className="mt-3 mb-3"
                                value={user.fullname}
                                pattern=".{2,10}"
                                title="昵称要在2至10个字符之间"
                                onChange={change}
                            />

                            <Button variant="primary" type="submit" className="mt-4">
                                注册
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export { RegisterView };
