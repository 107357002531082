import { FunctionComponent, useCallback, useContext, useEffect } from "react";
import { Toast } from "react-bootstrap";
import { PassportContext } from "../passport";
import { settingService } from "../service";
import { UserSetting } from "./setting";
import { AssistantActionType, AssistantContext } from "./context";

export const Assistant: FunctionComponent = () => {
    const { passport } = useContext(PassportContext);
    const { assistant, dispatch } = useContext(AssistantContext);

    const getSetting = useCallback(async () => {
        const response = await settingService().get(`/setting/${passport.username}`);

        switch (response.status) {
            case 200:
                const setting = response.data as UserSetting;

                dispatch({
                    type: AssistantActionType.SETTING,
                    difficulty: setting.difficulty,
                });
                break;

            default:
                console.warn("Failed to get setting from the server {}", response);
                break;
        }
    }, [dispatch, passport.username]);

    useEffect(() => {
        if (passport.authenticated) {
            getSetting();
        }
    }, [getSetting, passport.authenticated])

    const acknowledge = () => {
        dispatch({
            type: AssistantActionType.ACKNOWLEDGE
        });
    }

    return (
        <div className="position-absolute m-2" style={{ right: 0 }}>
            <Toast show={assistant.toast} onClose={() => acknowledge()} delay={5000} autohide>
                <Toast.Header>
                    <strong className="me-auto">{assistant.title}</strong>
                    <small>{assistant.subtitle}</small>
                </Toast.Header>
                <Toast.Body>{assistant.content}</Toast.Body>
            </Toast>
        </div>
    )
}