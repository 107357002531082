import { FunctionComponent } from "react";
import ReactPlayer from "react-player";
import { RawMetadata } from ".";
import { StoryCell } from "../story";
import { ComicCell } from "./comiccell";


interface Params {
    metadata: RawMetadata;
}

export const RawCell: FunctionComponent<Params> = ({ metadata }) => {
    const render = () => {
        switch (metadata.type) {
            case "comic":
                return (
                    <ComicCell
                        pages={metadata.content}
                        caption={metadata.title}
                    />
                );

            case "vod":
                return (
                    <ReactPlayer
                        controls
                        url={metadata.content}
                        width="100%"
                        height="100%"
                    />
                )

            case "story":
                return (
                    <StoryCell
                        id={metadata.content}
                        eventFrom={metadata.from}
                        eventTo={metadata.to}
                    />
                );

            default:
                console.warn("Unsupported mime type");
                return <></>;
        }
    }

    return (
        render()
    );
}