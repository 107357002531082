import moment from "moment";
import React, { ChangeEvent, FormEvent, FunctionComponent, useContext, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { JwtResponse } from ".";
import { AssistantActionType, AssistantContext } from "../assistant";
import { PassportActionType, PassportContext } from "../passport";
import { passportService } from "../service";

const LoginComponent: FunctionComponent = () => {
    const navigate = useNavigate();

    const { dispatch: dispatchPassport } = useContext(PassportContext);
    const { dispatch: dispatchAssistant } = useContext(AssistantContext);

    const [validated, setValidated] = useState(false);
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [authenticated, setAuthenticated] = useState<boolean>();

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            const response = await passportService().get("passport", {
                params: {
                    username: username,
                    password: password
                }
            });

            switch (response.status) {
                case 200:
                    console.info("Login successfully");

                    const transfer = response.data as JwtResponse;
                    dispatchPassport({
                        type: PassportActionType.LOGIN,
                        username: transfer.username,
                        token: transfer.token,
                    });

                    navigate(-1);

                    dispatchAssistant({
                        type: AssistantActionType.NOTIFY,
                        hint: false,
                        toast: true,
                        title: "💡",
                        subtitle: moment(new Date()).format("YYYY/MM/DD"),
                        content: "欢迎回来，" + transfer.username + "！非学无以广才，非志无以成学。",
                    });

                    break;
                case 401:
                    console.warn("Invalid username or password");

                    setAuthenticated(false);
                    setTimeout(() => {
                        setAuthenticated(undefined)
                    }, 4000);

                    break;
            }
        } else {
            setValidated(true);
        }
    };

    const alert = () => {
        if (authenticated === false) {
            return (
                <Alert variant="danger" onClose={() => setAuthenticated(undefined)} dismissible>
                    <p>
                        错误的用户名或者密码！
                    </p>
                </Alert>
            );
        }
    }

    return (
        <Container>
            <Row className="mt-5">
                <Col xs={0} md={3}></Col>
                <Col xs={12} md={6}>
                    {alert()}

                    <Form noValidate validated={validated} onSubmit={submit}>
                        <Form.Control
                            required
                            autoComplete="off"
                            type="text"
                            placeholder="用户名"
                            className="mt-3 mb-3"
                            value={username || ""}
                            pattern=".{2,20}"
                            title="用户名要在2至20个字符之间"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
                        />

                        <Form.Control
                            required
                            type="password"
                            placeholder="密码"
                            className="mt-3 mb-3"
                            value={password || ""}
                            pattern=".{6,20}"
                            title="密码要在6至20个字符之间"
                            autoComplete="on"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                        />

                        <Button variant="primary" type="submit">
                            登入
                        </Button>
                    </Form>
                </Col>
                <Col xs={0} md={3}></Col>
            </Row>

        </Container>
    )
}

export { LoginComponent };
