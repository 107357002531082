
export interface StoryProgressType {
    storyId?: string;
    eventIndex: number;
    eventMoment: number;
    eventPaused: boolean;
    eventEnded: boolean;
}

export enum StoryProgressActionType {
    STORY = "STORY",
    RESET = "RESET",
    READY = "READY",
    PAUSE = "PAUSE",
    RESUME = "RESUME",
    NEXT_STEP = "NEXT_STEP",
    REDO_EVENT = "REDO_EVENT",
    EXIT_EVENT = "EXIT_EVENT",
    NEXT_EVENT = "NEXT_EVENT",
    PREV_EVENT = "PREV_EVENT",
    GOTO_EVENT = "GOTO_EVENT",
}
interface StoryProgressStoryAction {
    type: typeof StoryProgressActionType.STORY;
    id: string;
    autoStart?: boolean;
}

interface StoryProgressResetAction {
    type: typeof StoryProgressActionType.RESET;
}
interface StoryProgressReadyAction {
    type: typeof StoryProgressActionType.READY;
}
interface StoryProgressPauseAction {
    type: typeof StoryProgressActionType.PAUSE;
}
interface StoryProgressResumeAction {
    type: typeof StoryProgressActionType.RESUME;
}
interface StoryProgressNextStepAction {
    type: typeof StoryProgressActionType.NEXT_STEP;
}
interface StoryProgressRedoEventAction {
    type: typeof StoryProgressActionType.REDO_EVENT;
}
interface StoryProgressExitEventAction {
    type: typeof StoryProgressActionType.EXIT_EVENT;
}
interface StoryProgressNextEventAction {
    type: typeof StoryProgressActionType.NEXT_EVENT;
}
interface StoryProgressPrevEventAction {
    type: typeof StoryProgressActionType.PREV_EVENT;
}
interface StoryProgressGotoEventAction {
    type: typeof StoryProgressActionType.GOTO_EVENT;
    eventIndex: number;
}
export type StoryProgressAction = StoryProgressStoryAction | StoryProgressResetAction | StoryProgressReadyAction
    | StoryProgressPauseAction | StoryProgressResumeAction | StoryProgressNextStepAction
    | StoryProgressRedoEventAction | StoryProgressExitEventAction | StoryProgressGotoEventAction
    | StoryProgressNextEventAction | StoryProgressPrevEventAction;

export interface StoryProgressContextType {
    state: StoryProgressType
    dispatch: (action: StoryProgressAction) => void
}

export const initialStoryProgress: StoryProgressType = { eventIndex: -1, eventMoment: 0, eventPaused: true, eventEnded: false };
export const reducerStoryProgress = (state: StoryProgressType, action: StoryProgressAction) => {
    switch (action.type) {
        case StoryProgressActionType.STORY:
            if (!action.id) {
                return state;
            }
            return { ...initialStoryProgress, storyId: action.id };
        case StoryProgressActionType.RESET:
            return { ...initialStoryProgress, storyId: state.storyId };
        case StoryProgressActionType.READY:
            return { ...initialStoryProgress, storyId: state.storyId, eventIndex: 0 };
        case StoryProgressActionType.PAUSE:
            if (state.eventPaused) {
                return state;
            }
            return { ...state, eventPaused: true };
        case StoryProgressActionType.RESUME:
            if (!state.eventPaused) {
                return state;
            }
            const eventEnded = state.eventEnded;
            const eventMoment = eventEnded ? 0 : state.eventMoment;
            return { ...state, eventPaused: false, eventEnded: false, eventMoment: eventMoment, eventIndex: state.eventIndex + (eventEnded ? 1 : 0) };
        case StoryProgressActionType.NEXT_STEP:
            if (state.eventPaused) {
                return state;
            }
            return { ...state, eventMoment: state.eventMoment + 1 };
        case StoryProgressActionType.REDO_EVENT:
            return { ...state, eventPaused: false, eventEnded: false, eventMoment: 0 };
        case StoryProgressActionType.EXIT_EVENT:
            return { ...state, eventPaused: true, eventEnded: true, eventMoment: 0 };
        case StoryProgressActionType.GOTO_EVENT:
            return { ...state, eventPaused: false, eventEnded: false, eventMoment: 0, eventIndex: action.eventIndex };
        case StoryProgressActionType.NEXT_EVENT:
            return { ...state, eventPaused: false, eventEnded: false, eventMoment: 0, eventIndex: state.eventIndex + 1 };
        case StoryProgressActionType.PREV_EVENT:
            return { ...state, eventPaused: false, eventEnded: false, eventMoment: 0, eventIndex: state.eventIndex > 0 ? state.eventIndex - 1 : 0 };
        default:
            return state;
    }
}