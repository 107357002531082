import React, { FunctionComponent, useReducer, useState, useEffect, useRef, useCallback, useMemo } from "react";
import Carousel, { CarouselRef } from "react-bootstrap/Carousel";
import { Button, Spinner } from "react-bootstrap";
import { BiReset, BiFullscreen, BiExitFullscreen, BiChevronLeftCircle, BiChevronRightCircle, BiPlayCircle } from 'react-icons/bi';
import useResizeObserver from "use-resize-observer";
import { useFullscreen } from "rooks";
import { Base64 } from "js-base64";
import * as StoryData from "./storydata";
import * as DefaultData from "./defaultdata";
import SoundPlayers, { PlayingSoundsContextType, initialPlayingSounds, reducerPlayingSounds, PlayingSoundsActionType } from "./soundplayers";
import StoryEvent from "./storyevent";
import { StoryPreferenceActionType, StoryPreferenceContextType, initialStoryPreference, reducerStoryPreference, initialPreviewPreference, reducerPreviewPreference } from "./storypreference";
import { storyResourceUrl, StoryResourcesActionType, StoryResourcesContextType, initialStoryResources, reducerStoryResources } from "./storyresources";
import { StoryProgressActionType, StoryProgressContextType, initialStoryProgress, reducerStoryProgress } from "./storyprogress";
import { materialService } from "../service";
import { StoryCollection } from "./storycollection";
import "./story.css";

let typingTextElements: { element: Element | null, text: string }[] = [];
var typingText: { element: Element | null, text: string };
function typingContent(c: Element) {
    if (c.nodeType === 3 && c.textContent !== "") {
        typingTextElements.push({ element: c, text: c.textContent ?? "" });
        c.textContent = "";
        return;
    }
    for (var i = 0; i < c.childNodes.length; i++) {
        typingContent(c.childNodes[i] as Element);
    }
}

export enum ShowMode {
    SLIDESHOW = "SLIDESHOW",
    WATERFALL = "WATERFALL",
}
interface StoryShowParams {
    caption: string;
    content: string;
    showmode?: ShowMode;
    eventFrom?: number;
    eventTo?: number;
}

export const StoryPreferenceContext = React.createContext({ state: initialStoryPreference } as StoryPreferenceContextType);

export const StoryResourcesContext = React.createContext({ state: initialStoryResources } as StoryResourcesContextType);

export const StoryProgressContext = React.createContext({ state: initialStoryProgress } as StoryProgressContextType);

export const PlayingSoundsContext = React.createContext({ state: initialPlayingSounds } as PlayingSoundsContextType)

var divFullscreen: HTMLDivElement | undefined;

export const StoryShow: FunctionComponent<StoryShowParams> = (params) => {
    const [currentStoryPreference, dispatchStoryPreference] = useReducer(reducerStoryPreference, initialStoryPreference);
    const [currentStoryResources, dispatchStoryResources] = useReducer(reducerStoryResources, initialStoryResources);
    const [currentStoryProgress, dispatchStoryProgress] = useReducer(reducerStoryProgress, { ...initialStoryProgress });

    const sizeRatio = currentStoryPreference.effectiveSceneWidth / currentStoryPreference.defaultSceneWidth;
    const storyControllButtonStyle = useMemo(() => ({
        width: `${32 * sizeRatio}${currentStoryPreference.effectiveSceneWidthUnit}`,
        height: `${32 * sizeRatio}${currentStoryPreference.effectiveSceneWidthUnit}`,
        strokeWidth: 0.01 * sizeRatio + currentStoryPreference.effectiveSceneWidthUnit
    }), [currentStoryPreference, sizeRatio]);
    const container = useRef<HTMLDivElement>(null);
    const fullScreen = useFullscreen({
        onChange: (event_, isOpen) => {
            if (divFullscreen !== container.current) {
                return;
            }

            dispatchStoryPreference({ type: StoryPreferenceActionType.SET, value: { fullScreen: isOpen } });
            setChangingFullScreen(false);
            if (!isOpen) {
                divFullscreen = undefined;
            }
        }
    });
    const [changingFullScreen, setChangingFullScreen] = useState(false);

    const divEvents = useRef<CarouselRef>(null);
    const refCatalog = useRef<HTMLDivElement>(null);

    const [currentPreviewPreference, dispatchPreviewPreference] = useReducer(reducerPreviewPreference, StoryData.StoryPreferenceType.create(initialPreviewPreference));
    const [currentPreviewProgress, dispatchPreviewProgress] = useReducer(reducerStoryProgress, { ...initialStoryProgress });
    const [catalogShow, setCatalogShow] = useState(false);
    const [catalogRange, setCatalogRange] = useState<{ start?: number, end?: number }>({});

    const [storyContent, setStoryContent] = useState<StoryData.IStoryContent>();
    const [storyEvents, setStoryEvents] = useState<StoryData.StoryEventType[]>();
    const [storyEnd, setStoryEnd] = useState(false);
    const [notesFound] = useState(false);
    const [soundsList, setSoundsList] = useState<string[]>([]);
    const [currentPlayingSounds, dispatchPlayingSounds] = useReducer(reducerPlayingSounds, initialPlayingSounds);

    const [playerEnded, setPlayerEnded] = useState(false);

    const [storyCollectionChanged, setStoryCollectionChanged] = useState(false);
    const storyCollectionData = useRef<Record<string, StoryData.StoryContent | undefined>>({});
    const [storyCollectionList, setStoryCollectionList] = useState<(StoryData.StoryContent | undefined)[]>([]);
    const [storyCollection, setStoryCollection] = useState<string[]>([]);
    const [storyCurrentId, setStoryCurrentId] = useState("");
    const [storyAutoStart, setStoryAutoStart] = useState(false);

    const abortController = useRef<AbortController>();

    useEffect(() => {
        return () => {
            abortController.current?.abort();
        };
    }, []);

    const fetchStory = useCallback(async (id: string) => {
        if (!abortController.current) {
            abortController.current = new AbortController();
        }
        const collectionData = storyCollectionData.current;
        if (Object.keys(collectionData).includes(id)) {
            return;
        }
        collectionData[id] = undefined;
        setStoryCollectionChanged(true);
        const getResponse = async () => {
            const response = await materialService().get(`/story/${id}`, {
                signal: abortController.current?.signal
            });
            switch (response.status) {
                case 200:
                    const story = response.data as { "title": string, "content": string };
                    const content = StoryData.createStoryContent(JSON.parse(story.content));
                    collectionData[id] = Object.assign(content, { id: id });
                    setStoryCollectionChanged(true);
                    break;

                case 401:
                    break;
            }
        };
        // if (envProd) {
        getResponse().catch(reason => { });
        // } else {
        //     setTimeout(() => {
        //         getResponse();
        //     }, 3000);
        // }
    }, []);

    const nextStory = useCallback(() => {
        const currentStoryIndex = storyCollection.findIndex(x => x === storyCurrentId);
        if (currentStoryIndex < 0 || currentStoryIndex >= (storyCollection.length - 1)) {
            return false;
        } else {
            const storyId = storyCollection[currentStoryIndex + 1];
            dispatchStoryProgress({ type: StoryProgressActionType.STORY, id: storyId });
            dispatchPlayingSounds({ type: PlayingSoundsActionType.SET, value: { playing: "", waiting: "" } });
            return true;
        }
    }, [storyCollection, storyCurrentId]);

    const storySlidesComponent = useMemo(() => (<>
        {[storyEvents ?? []].map((eventsList, storyIndex) =>
            <Carousel fade slide={false}
                key={storyIndex}
                indicators={false}
                activeIndex={currentStoryProgress.eventIndex < 0 || (eventsList.length) === 0 ? 0 : Math.min(currentStoryProgress.eventIndex, (eventsList.length) - 1)}
                interval={null}
                wrap={false}
                ref={divEvents}
                data-notesfound={notesFound}
                prevIcon={<BiChevronLeftCircle color="#fff" style={storyControllButtonStyle} />}
                nextIcon={<BiChevronRightCircle className={currentStoryProgress.eventEnded ? "blinker" : ""} color="#fff" style={{ ...storyControllButtonStyle, opacity: 1 }} />}
                onSelect={(eventKey: number, event: Record<string, unknown> | null) => {
                    if (eventKey < currentStoryProgress.eventIndex) {
                        dispatchStoryProgress({ type: StoryProgressActionType.PREV_EVENT });
                    } else if (eventKey > currentStoryProgress.eventIndex) {
                        dispatchStoryProgress({ type: StoryProgressActionType.NEXT_EVENT });
                    }
                    dispatchStoryPreference({ type: StoryPreferenceActionType.SET, value: { magnifiedRatio: 1, magnifiedRect: undefined } })
                }}
            >{eventsList.length === 0 ?
                <Carousel.Item key={0} style={{
                    width: `${currentStoryPreference.effectiveSceneWidth}${currentStoryPreference.effectiveSceneWidthUnit}`,
                    height: `${currentStoryPreference.effectiveSceneHeight}${currentStoryPreference.effectiveSceneHeightUnit}`,
                }}>
                    <div className="absolute-center"><Spinner animation="border" /></div>
                </Carousel.Item> : eventsList.map((storyEvent: StoryData.StoryEventType, index) =>
                    <Carousel.Item key={index} style={{ width: "100%", height: "100%" }}
                    >{Math.abs((index < 0 ? 0 : index) - currentStoryProgress.eventIndex) > 1 ? null :
                        <StoryEvent
                            eventIndex={index}
                            content={storyEvent}
                            playerEnded={index !== currentStoryProgress.eventIndex ? false : playerEnded}
                        />}
                    </Carousel.Item>
                )}
                <Carousel.Caption style={{
                    position: "absolute", margin: "5px",
                    left: 0, right: 0, top: 0,
                }}>
                    <div className="storyController">
                        <Button onClick={() => {
                            const catalogShow2 = !catalogShow;
                            dispatchStoryProgress({ type: StoryProgressActionType.PAUSE });
                            setCatalogShow(catalogShow2);
                        }} size="lg" variant="light" className="bg-transparent story-control" type="button" style={{ left: 0 }}
                        >
                            <BiReset color="#fff" style={storyControllButtonStyle} />
                        </Button>
                        <Button onClick={() => {
                            dispatchStoryPreference({ type: StoryPreferenceActionType.TOGGLE_FULLSCREEN });
                        }} size="lg" variant="light" className="bg-transparent story-control" type="button" style={{ right: 0 }}>
                            {currentStoryPreference.fullScreen === true ? <BiExitFullscreen color="#fff" style={storyControllButtonStyle} /> : <BiFullscreen color="#fff" style={storyControllButtonStyle} />}
                        </Button>
                    </div>
                    <div style={{ position: "absolute", bottom: "20px", zIndex: 999 }}>
                        <SoundPlayers visible urls={soundsList} playList={currentPlayingSounds.playing}
                            onPlayEnded={(soundIndex: number) => {
                                setPlayerEnded(true);
                                dispatchPlayingSounds({
                                    type: PlayingSoundsActionType.SET, value: {
                                        waiting: !currentPlayingSounds.waiting.includes(soundIndex) ? undefined : currentPlayingSounds.waiting.filter(x => x !== soundIndex).join(","),
                                        history: currentPlayingSounds.history.includes(soundIndex) ? undefined : [...currentPlayingSounds.history, soundIndex].join(","),
                                    }
                                });
                            }}
                            onPlayStart={(soundIndex: number) => {
                                dispatchPlayingSounds({
                                    type: PlayingSoundsActionType.SET, value: {
                                        playing: currentPlayingSounds.playing.includes(soundIndex) ? undefined : [...currentPlayingSounds.playing, soundIndex].join(","),
                                        waiting: !currentPlayingSounds.waiting.includes(soundIndex) ? undefined : currentPlayingSounds.waiting.filter(x => x !== soundIndex).join(","),
                                    }
                                });
                            }}
                            onPlayError={(soundIndex: number) => {
                                dispatchPlayingSounds({
                                    type: PlayingSoundsActionType.SET, value: {
                                        playing: !currentPlayingSounds.playing.includes(soundIndex) ? undefined : currentPlayingSounds.playing.filter(x => x !== soundIndex).join(","),
                                        waiting: currentPlayingSounds.waiting.includes(soundIndex) ? undefined : [...currentPlayingSounds.waiting, soundIndex].join(","),
                                    }
                                });
                            }}
                        />
                    </div>
                </Carousel.Caption>
            </Carousel>
        )}
    </>), [storyControllButtonStyle, storyEvents, catalogShow, currentPlayingSounds, currentStoryPreference, currentStoryProgress, notesFound, playerEnded, soundsList]);

    const storyCollectionComponent = useMemo(() => (
        storyCollectionList.length === 0 ? null : <div className="story-collection">
            <div className="story-collection-background"></div>
            <StoryPreferenceContext.Provider value={{
                state: Object.assign(StoryData.StoryPreferenceType.create(initialPreviewPreference), {
                    effectiveSceneWidth: initialPreviewPreference.effectiveSceneWidth / 5,
                    effectiveSceneHeight: initialPreviewPreference.effectiveSceneHeight / 5,
                    magnifiedRatio: 1,
                    magnifiedRect: undefined,
                }),
                dispatch: dispatchPreviewPreference
            }}>
                <StoryCollection className="story-collection-list" classNameCurrentIndicator="story-indicator-collection-current"
                    storiesList={storyCollectionList} />
            </StoryPreferenceContext.Provider>
        </div>
    ), [storyCollectionList]);

    const select = useCallback((selected: number) => {
        let events = storyEvents ?? [];
        let event = selected < 0 || selected >= events.length ? undefined : events[selected];
        if (!event) {
            setStoryEnd(true);
            dispatchStoryProgress({ type: StoryProgressActionType.PAUSE });
            return;
        }

        setStoryEnd(false);
        dispatchPlayingSounds({ type: PlayingSoundsActionType.SET, value: { playing: "", waiting: "", history: "" } });
        let notesFound_ = event !== null && event.board?.type === "notes";
        if (notesFound_) {
            var storyBoards = document.getElementsByClassName("storyBoard");
            for (var i = 0; i < storyBoards.length; i++) {
                var storyBoard = storyBoards[i];
                var notesList = storyBoard.getElementsByClassName("notes");
                for (var j = 0; j < notesList.length; j++) {
                    var notes = notesList[j];
                    if (notes.getAttribute("data-effect") === "typing") {
                        typingContent(notes);
                    }
                }
            }
        }
    }, [storyEvents]);

    useEffect(() => {
        if (changingFullScreen) {
            return;
        }
        if (currentStoryPreference.fullScreen) {
            if (divFullscreen === undefined && true !== fullScreen?.isFullscreen && container.current) {
                setChangingFullScreen(true);
                divFullscreen = container.current;
                fullScreen?.request(container.current);
            }
        } else {
            if (divFullscreen === container.current && true === fullScreen?.isFullscreen) {
                setChangingFullScreen(true);
                fullScreen?.exit();
            }
        }
    }, [currentStoryPreference.fullScreen, changingFullScreen, fullScreen]);

    useEffect(() => {
        dispatchPreviewPreference({ type: StoryPreferenceActionType.SET, value: { ...currentStoryPreference, magnifiedRatio: 0.2 } });
    }, [currentStoryPreference]);

    useEffect(() => {
        let content = {};
        try {
            content = JSON.parse(params.content);
        } catch {
        }
        const storyContent2 = StoryData.createStoryContent(content);
        const collection2 = storyContent2.collection ?? [];
        if (collection2.length > 0) {
            setStoryCollection(prevCollection => {
                return (collection2.length === prevCollection.length && !collection2.find((x, i) => x !== prevCollection[i])) ?
                    prevCollection :
                    collection2;
            });
            collection2.forEach(x => {
                fetchStory(x);
            });
        } else {
            setStoryContent(storyContent2);
            dispatchStoryProgress({ type: StoryProgressActionType.RESET });
        }
    }, [params.content, fetchStory]);

    useEffect(() => {
        if (storyCollectionChanged) {
            setStoryCollectionChanged(false);
            const collectionData = storyCollectionData.current;
            const collectionList2 = storyCollection.map(x => !Object.keys(collectionData).includes(x) ? undefined : collectionData[x]);
            setStoryCollectionList(collectionList2);
        }
        dispatchStoryProgress({ type: StoryProgressActionType.STORY, id: storyCurrentId !== "" ? storyCurrentId : storyCollection[0] });
    }, [storyCollectionChanged, storyCollection, storyCurrentId]);

    useEffect(() => {
        setStoryCurrentId(currentStoryProgress.storyId ?? "");
    }, [currentStoryProgress.storyId]);

    useEffect(() => {
        setStoryEvents(undefined);
    }, [storyCurrentId]);

    useEffect(() => {
        const story = storyCollectionList.find((x, i) => x?.id === storyCurrentId || (i === 0 && storyCurrentId === ""));
        if (!story) {
            return;
        }
        setStoryContent(story);
        setStoryAutoStart(true);
    }, [storyCurrentId, storyCollectionList]);

    useEffect(() => {
        if (currentStoryProgress.eventEnded) {
            const events = storyEvents ?? [];
            if (currentStoryProgress.eventIndex >= (events.length - 1)) {
                nextStory();
            }
        }
    }, [currentStoryProgress.eventEnded, currentStoryProgress.eventIndex, storyEvents, nextStory])

    useEffect(() => {
        const loadCharacters = async () => {
            const characters: StoryData.StoryCharactersDict = Object.assign(DefaultData.defaultCharacters, storyContent?.characters);
            Object.keys(characters).forEach(name => {
                let figures = characters[name].figure;
                figures?.forEach((x, i) => {
                    const source = storyResourceUrl(x.source) ?? "";
                    if (source === "") {
                        return;
                    }
                    fetch(source, {
                        signal: abortController.current?.signal
                    }).then(async value => {
                        if (value.status >= 200 && value.status < 300 && value.headers.get("Content-Type") === "image/svg+xml") {
                            const text = await value.text();
                            const svg64 = Base64.encode(text);
                            x.data = `data:image/svg+xml;base64,${svg64}`;
                        }
                    }).catch((reason) => {
                        console.log(source, reason);
                    }).finally(() => {
                    });
                });
            });
            dispatchStoryResources({ type: StoryResourcesActionType.SET, value: { characters: characters } })
        };

        const loadPopups = async () => {
            let popups = storyContent?.popups;
            if (popups) {
                dispatchStoryResources({ type: StoryResourcesActionType.SET, value: { popups: popups } });
                return;
            }
            popups = DefaultData.defaultPopups as StoryData.StoryPopupType[];
            dispatchStoryResources({ type: StoryResourcesActionType.SET, value: { popups: popups } });
        }

        const loadScenes = async () => {
            let scenes = {};
            const defaultSets = DefaultData.defaultScenes as StoryData.StorySceneDict;
            Object.assign(scenes, { defaultSets: defaultSets });

            if (Array.isArray(storyContent?.scenes)) {
                Object.assign(scenes, { additionalSets: storyContent?.scenes });
            }
            dispatchStoryResources({ type: StoryResourcesActionType.SET, value: { scenes: scenes } });
        }

        if (currentStoryProgress.eventIndex < 0) {
            loadCharacters();
            loadPopups();
            loadScenes();

            const eventBegin = params.eventFrom;
            const eventEnd = params.eventTo && params.eventTo + 1;
            setStoryEvents(storyContent?.events.slice(eventBegin, eventEnd) ?? []);
            setStoryEnd(false);
            select(0);
            dispatchPlayingSounds({ type: PlayingSoundsActionType.SET, value: { playing: "", waiting: "", history: "" } });
            setSoundsList(storyContent?.voices.map((voice) => storyResourceUrl(voice.sound) ?? "") ?? []);
            setPlayerEnded(false);
            dispatchStoryProgress({ type: StoryProgressActionType.READY });
            dispatchStoryPreference({ type: StoryPreferenceActionType.SET, value: { magnifiedRatio: 1, magnifiedRect: undefined } })
            if (storyAutoStart) {
                setStoryAutoStart(false);
                if (!catalogShow) {
                    dispatchStoryProgress({ type: StoryProgressActionType.RESUME });
                }
            }
            return;
        }
    }, [currentStoryProgress.eventIndex, storyContent, params.eventFrom, params.eventTo, storyAutoStart, catalogShow, select]);

    useEffect(() => {
        if (currentStoryProgress.eventIndex < 0) {
            return;
        }
        if (!storyEvents) {
            dispatchStoryProgress({ type: StoryProgressActionType.RESET });
            return;
        }

        let events = storyEvents;
        if (currentStoryProgress.eventIndex < events.length) {
            select(currentStoryProgress.eventIndex);
        } else {
            if (storyEnd) {
                if (!currentStoryProgress.eventPaused) {
                    setStoryEnd(false);
                    dispatchStoryProgress({ type: StoryProgressActionType.RESET });
                }
            }
            else if (!nextStory() && !currentStoryProgress.eventPaused) {
                setStoryEnd(true);
                dispatchStoryProgress({ type: StoryProgressActionType.PAUSE });
            }
            return;
        }

        setImmediate(function () {
            var playing = divEvents.current?.element?.getAttribute("data-effect-playing") ?? "";
            if (playing !== "") {
                return;
            }
            if (typingTextElements.length > 0) {
                divEvents.current?.element?.setAttribute("data-effect-playing", "1");
                typingText = typingTextElements.shift() ?? { element: null, text: "" };
                var timer = setInterval(() => {
                    if (typingText.element === null || typingText.text === "") {
                        if (typingTextElements.length === 0) {
                            clearInterval(timer);
                            divEvents.current?.element?.setAttribute("data-effect-playing", "");
                        } else {
                            typingText = typingTextElements.shift() ?? { element: null, text: "" };
                        }
                    } else if (typingText.element.textContent === typingText.text) {
                        typingText.text = "";
                    } else {
                        if (typingText.element.textContent === "") {
                            console.log(typingText);
                        }
                        var text = typingText.element.textContent;
                        typingText.element.textContent += text === null ? "" : typingText.text.charAt(text.length);
                    }
                }, 100);
            }
        }, 1000);
    }, [storyEvents, storyEnd, currentStoryProgress.eventIndex, currentStoryProgress.eventPaused, select, nextStory]);

    useEffect(() => {
        if (playerEnded) {
            setPlayerEnded(false);
        }
    }, [playerEnded]);

    useEffect(() => {
        if (currentStoryProgress.eventPaused) {
            dispatchPlayingSounds({ type: PlayingSoundsActionType.SET });
        }
    }, [currentStoryProgress.eventPaused]);

    useResizeObserver<HTMLElement>({
        ref: container.current?.parentElement, onResize: (size) => {
            const fullScreen = currentStoryPreference.fullScreen === true;
            const availableSceneWidth = fullScreen ? window.screen.width : size.width ?? StoryData.DEFAULT_SCENE_WIDTH;
            const availableSceneHeight = fullScreen ? window.screen.height : size.height ?? StoryData.DEFAULT_SCENE_HEIGHT;
            const scaleRatio = Math.min(availableSceneWidth / StoryData.DEFAULT_SCENE_WIDTH, availableSceneHeight / StoryData.DEFAULT_SCENE_HEIGHT);
            dispatchStoryPreference({
                type: StoryPreferenceActionType.SET,
                value: {
                    effectiveSceneWidth: StoryData.DEFAULT_SCENE_WIDTH * scaleRatio,
                    effectiveSceneHeight: StoryData.DEFAULT_SCENE_HEIGHT * scaleRatio,
                }
            });
        }
    });

    const catalogAnimeDuration = 0.75;
    const catalogAnimeDelay = 0.25;
    const catalogItemMarginSize = 5;
    const effectiveWidthUnit = currentPreviewPreference.effectiveSceneWidthUnit;
    const effectiveHeightUnit = currentPreviewPreference.effectiveSceneHeightUnit;
    const catalogItemContentWidth = currentPreviewPreference.effectiveSceneWidth * currentPreviewPreference.magnifiedRatio;
    const catalogItemContentHeight = currentPreviewPreference.effectiveSceneHeight * currentPreviewPreference.magnifiedRatio;
    const catalogItemScaledWidth = currentPreviewPreference.effectiveSceneWidth + catalogItemMarginSize * 2 / currentPreviewPreference.magnifiedRatio;
    const catalogItemScaledHeight = currentPreviewPreference.effectiveSceneHeight + catalogItemMarginSize * 2 / currentPreviewPreference.magnifiedRatio;
    const catalogItemFinalWidth = catalogItemContentWidth + catalogItemMarginSize * 2;
    const catalogItemFinalHeight = catalogItemContentHeight + catalogItemMarginSize * 2;
    const catalogCols = Math.floor(currentStoryPreference.effectiveSceneWidth / catalogItemFinalWidth);
    const catalogItemCol = (currentStoryProgress.eventIndex % catalogCols);
    const catalogItemRow = (Math.floor(currentStoryProgress.eventIndex / catalogCols));
    const catalogRows = Math.ceil((storyEvents?.length ?? 0) / catalogCols);
    // const catalogWidth = currentStoryPreference.effectiveSceneWidth;
    const catalogItemsWidth = catalogItemFinalWidth * catalogCols;
    const catalogItemsHeight = catalogItemFinalHeight * catalogRows;
    const cssCalcCatalogLeft = `${catalogItemScaledWidth * (catalogCols - 1) / 2 - catalogItemScaledWidth * catalogItemCol}${effectiveWidthUnit}`;
    const cssCalcCatalogTop = `calc(${((catalogItemsHeight - catalogItemMarginSize * 2) / currentPreviewPreference.magnifiedRatio - catalogItemsHeight) / 2 - catalogItemScaledHeight * catalogItemRow}${effectiveHeightUnit} + ${refCatalog.current?.scrollTop ?? 0}px)`;
    return (<StoryResourcesContext.Provider value={{
        state: currentStoryResources,
        dispatch: dispatchStoryResources
    }}><div ref={container} className="story-show">{
        params.showmode === ShowMode.WATERFALL ? <>{
            storyEvents?.map((storyEvent, index) => <div key={index}>
                <StoryEvent
                    innerController={index === currentStoryProgress.eventIndex}
                    eventIndex={index}
                    content={storyEvent}
                    playerEnded={index !== currentStoryProgress.eventIndex ? false : playerEnded}
                />
            </div>)
        }</> : <>
            <PlayingSoundsContext.Provider value={{
                state: currentPlayingSounds,
                dispatch: dispatchPlayingSounds
            }}>
                <StoryPreferenceContext.Provider value={{
                    state: currentStoryPreference,
                    dispatch: dispatchStoryPreference
                }}>
                    <StoryProgressContext.Provider value={{
                        state: currentStoryProgress,
                        dispatch: dispatchStoryProgress
                    }}><div style={{
                        margin: "0 auto",
                        width: currentStoryPreference.effectiveSceneWidth + currentStoryPreference.effectiveSceneWidthUnit,
                        height: currentStoryPreference.effectiveSceneHeight + currentStoryPreference.effectiveSceneHeightUnit,
                        visibility: catalogShow ? "hidden" : "visible",
                        transitionProperty: "visibility",
                        transitionDelay: `${catalogShow ? 0 : (catalogAnimeDuration + catalogAnimeDelay)}s`,
                        transform: !currentStoryPreference.fullScreen ? undefined : "translateY(-50%)",
                        position: !currentStoryPreference.fullScreen ? undefined : "absolute",
                        top: !currentStoryPreference.fullScreen ? undefined : "50%",
                    }}>{storySlidesComponent}</div></StoryProgressContext.Provider>
                </StoryPreferenceContext.Provider>
            </PlayingSoundsContext.Provider>
            <StoryPreferenceContext.Provider value={{
                state: currentPreviewPreference,
                dispatch: dispatchPreviewPreference
            }}>
                <StoryProgressContext.Provider value={{
                    state: currentPreviewProgress,
                    dispatch: dispatchPreviewProgress
                }}>
                    {!catalogShow ? null : <div className="story-catalog-background"></div>}
                    <div ref={refCatalog} className="story-catalog"
                        style={{
                            zIndex: catalogShow ? 1 : -1,
                            visibility: catalogShow ? "visible" : "hidden",
                            transitionProperty: "z-index, visibility",
                            transitionDelay: `${catalogShow ? 0 : (catalogAnimeDuration + catalogAnimeDelay)}s`,
                        }}
                        onScroll={() => {
                            const catalog = refCatalog.current;
                            if (!catalog) return;
                            const catalogItemRowStartInPage = Math.floor(catalog.scrollTop / catalogItemFinalHeight);
                            const catalogItemRowsInPage = Math.ceil(catalog.scrollHeight / catalogItemFinalHeight);
                            const catalogItemStartInPage = catalogItemRowStartInPage * catalogCols;
                            const catalogItemCountInPage = catalogItemRowsInPage * catalogCols;
                            setCatalogRange({ start: catalogItemStartInPage, end: catalogItemStartInPage + catalogItemCountInPage });
                        }}
                    >
                        <div className="story-catalog-contents"
                            style={{
                                position: "absolute",
                                left: catalogShow ? "0" : cssCalcCatalogLeft,
                                top: catalogShow ? "0" : cssCalcCatalogTop,
                                width: "100%",
                                height: `${catalogItemsHeight}${effectiveHeightUnit}`,
                                transform: catalogShow ? "scale(1)" : `scale(${(1 / currentPreviewPreference.magnifiedRatio)})`,
                                transitionProperty: catalogShow ? "transform, left, top" : "left, top, transform",
                                transitionDuration: `${catalogAnimeDuration}s`,
                                transitionDelay: `${catalogAnimeDelay}s`,
                            }}
                        >
                            <div className="story-catalog-contents-inner" style={{
                                width: `${catalogItemsWidth}${effectiveWidthUnit}`
                            }}>
                                {storyEvents?.map((storyEvent, index) => <div className="story-catalog-item" key={index}
                                    style={{
                                        transform: true ? `` : `scale(${(10 - Math.min(3, storyEvent.level)) * 10}%, ${(10 - Math.min(3, storyEvent.level)) * 10}%)`,
                                        display: storyEvent.level === 0 ? "" : "none",
                                        width: `${catalogItemContentWidth}${effectiveWidthUnit}`,
                                        height: `${catalogItemContentHeight}${effectiveHeightUnit}`,
                                        margin: `${catalogItemMarginSize}${effectiveHeightUnit} ${catalogItemMarginSize}${effectiveWidthUnit}`
                                    }}
                                    onClick={() => {
                                        if (index !== currentStoryProgress.eventIndex) {
                                            dispatchStoryPreference({
                                                type: StoryPreferenceActionType.SET,
                                                value: {
                                                    magnifiedRatioWidth: 1,
                                                    magnifiedRatioHeight: 1,
                                                    magnifiedRatio: 1,
                                                    magnifiedRect: undefined,
                                                    sceneOffset: undefined,
                                                }
                                            })
                                        }
                                        dispatchStoryProgress({ type: StoryProgressActionType.GOTO_EVENT, eventIndex: index });
                                        setCatalogShow(false);
                                    }}
                                >
                                    {(!catalogShow && index !== currentStoryProgress.eventIndex) || index < (catalogRange.start ?? 0) || index >= (catalogRange.end ?? (storyEvents.length + 1)) ? null :
                                        <StoryEvent
                                            innerController={false}
                                            eventIndex={index}
                                            content={storyEvent}
                                            playerEnded={false}
                                            previewMode={true}
                                        />}
                                    {!catalogShow || index !== currentStoryProgress.eventIndex ? null :
                                        <BiPlayCircle className="story-catalog-indicator-current" />}
                                </div>)}
                            </div>
                        </div>
                    </div>
                </StoryProgressContext.Provider>
            </StoryPreferenceContext.Provider>
        </>
    }</div >
        <StoryProgressContext.Provider value={{
            state: currentStoryProgress,
            dispatch: dispatchStoryProgress
        }}>
            {storyCollectionComponent}
        </StoryProgressContext.Provider>
    </StoryResourcesContext.Provider>);
}

export * from "./storypreference";
export * from "./storyresources";
export * from "./storyprogress";
export * from "./soundplayers";
