import assert from "assert";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useParams } from "react-router-dom";
import { Jumbotron, Media, MediaBody } from "../navigator";
import { materialService, TutorInfo } from "../service";

const TutorDetail: FunctionComponent = () => {
    const { id } = useParams();
    const [tutor, setTutor] = useState<TutorInfo>();

    assert(id, "id should not be undefined");

    const getTutor = useCallback(async (id: string) => {
        const response = await materialService().get(`tutor/${id}`);

        switch (response.status) {
            case 200:
                setTutor(response.data as TutorInfo);
                break;

            default:
                console.warn("unexpected behavior, please contact site admin");
                break;
        }
    }, []);

    useEffect(() => {
        console.log("Loading webinar", id);

        getTutor(id);
    }, [getTutor, id]);

    return (
        <>
            <Jumbotron>
                <Container>
                    <Media>
                        <MediaBody>
                            <h1>{tutor?.name}</h1>
                            <p>{tutor?.bio}</p>
                        </MediaBody>

                        <Image className="rounded-circle ms-5 me-3"
                            height={180}
                            width={180}
                            src={tutor?.icon}
                            alt={tutor?.name}
                        />
                    </Media>

                </Container>
            </Jumbotron>
            <Container>
                <p className="text-center">
                    &copy; CodeMage
                </p>
            </Container>
        </>
    );

}

export { TutorDetail };
