import React, { FunctionComponent } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { TutorInfo } from "../service";

interface TutorParams {
    tutor?: TutorInfo;
}

export const TutorIntro: FunctionComponent<TutorParams> = (props) => {
    return (
        <Link to={`/tutor/${props.tutor?.id}`} style={{ textDecoration: "none" }}>
            <Image className="rounded-circle mx-auto d-block"
                height={180}
                width={180}
                src={props.tutor?.icon}
                alt={props.tutor?.name}
            />

            <div className="mb-5">
                <p className="text-center">{props.tutor?.name}</p>
            </div>
        </Link>
    );
}