import { FunctionComponent, useContext } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router";
import { RecipeType } from "../service";
import { RecipeContext } from "./context";

export const RecipeBreadcrumb: FunctionComponent = () => {
    const navigate = useNavigate();
    const { recipe } = useContext(RecipeContext);

    const visible = recipe.course_alias && recipe.style === RecipeType.COURSE;
    const course = `/course/${recipe.course_alias}`;
    const chapter = `/course/${recipe.course_alias}#chapter-${recipe.chapter_id}`;

    return (
        <>
            {
                visible &&
                <Breadcrumb>
                    <Breadcrumb.Item href="#" linkProps={{ style: { textDecoration: 'none' } }} onClick={() => navigate(course)}>
                        {recipe.course_title}
                    </Breadcrumb.Item>

                    <Breadcrumb.Item href="#" linkProps={{ style: { textDecoration: 'none' } }} onClick={() => navigate(chapter)}>
                        {recipe.chapter_title}
                    </Breadcrumb.Item>
                </Breadcrumb>
            }
        </>
    );
}