import React, { FunctionComponent, useState, useEffect, useCallback } from 'react'
import { FaPlay, FaPause } from 'react-icons/fa';
import { BsSoundwave } from "react-icons/bs";
import { Button } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';

export enum PlayingSoundsActionType {
    SET = "SET",
}
interface PlayingSoundsSetAction {
    type: typeof PlayingSoundsActionType.SET;
    value?: { playing?: string, waiting?: string; history?: string; };
}
export type PlayingSoundsAction = PlayingSoundsSetAction;
export interface PlayingSoundsContextType {
    state: { playing: number[], waiting: number[], history: number[] }
    dispatch: (action: PlayingSoundsAction) => void
}

export const initialPlayingSounds = { playing: Array<number>(), waiting: Array<number>(), history: Array<number>() };
export const reducerPlayingSounds = (state: { playing: number[], waiting: number[], history: number[] }, action: PlayingSoundsAction) => {
    switch (action.type) {
        case PlayingSoundsActionType.SET:
            const playingList = action.value?.playing?.split(",").map(x => parseInt(x)).filter(x => !isNaN(x));
            const waitingList = action.value?.waiting?.split(",").map(x => parseInt(x)).filter(x => !isNaN(x));
            const historyList = action.value?.history?.split(",").map(x => parseInt(x)).filter(x => !isNaN(x));
            if (playingList) {
                Object.assign(state, { playing: playingList });
            }
            if (waitingList) {
                Object.assign(state, { waiting: waitingList });
            }
            if (historyList) {
                Object.assign(state, { history: historyList });
            }
            return state;
        default:
            return state;
    }
}

const memoryState: Record<string, any> = {};

function useMemoryState<T>(key: string, initialState: T): [T, (s: T) => void] {
    function onChange(nextState: T) {
        memoryState[key] = nextState;
        setState(nextState);
    }

    const [state, setState] = useState<T>(() => {
        const hasMemoryValue = Object.prototype.hasOwnProperty.call(memoryState, key);
        if (hasMemoryValue) {
            return memoryState[key] as T;
        } else {
            return typeof initialState === 'function' ? initialState() : initialState;
        }
    });

    return [state, onChange];
};

export const SoundPlayer: FunctionComponent<any> = (params) => {
    const [sounds] = useMemoryState<{ source: string, audio: HTMLAudioElement }[]>("sounds", []);
    const [playListOnClick, setPlayListOnClick] = useMemoryState<number[]>("playListOnClick", []);
    const [isPlaying, setIsPlaying] = useState(false);
    const paramsIndex = useCallback(() => params.index ?? -1, [params.index]);
    const onClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        const soundIndex = paramsIndex();
        if (soundIndex < 0 || soundIndex > sounds.length) {
            return;
        }
        const audio = sounds[soundIndex]?.audio;
        if (audio) {
            if (audio.paused) {
                audio.play().then(() => {
                    setPlayListOnClick([soundIndex]);
                });
            } else {
                audio.pause();
                audio.currentTime = 0;
                setPlayListOnClick([]);
            }
        }
    };

    useEffect(() => {
        setIsPlaying(playListOnClick.includes(paramsIndex()));
    }, [paramsIndex, playListOnClick]);

    return (
        <Button className="story-voice-player-wrap" {...params} onClick={onClicked}>
            {isPlaying ? <FaPause className="story-voice-player" /> : <FaPlay className="story-voice-player" />}
        </Button>
    );
};

export const SoundPlayers: FunctionComponent<{
    urls: string[],
    playList: number[],
    visible?: boolean,
    onPlayEnded: (i: number) => void,
    onPlayStart: (i: number) => void,
    onPlayError: (i: number) => void,
}> = ((props) => {
    const [reset, setReset] = useState(false);
    const [sounds, setSounds] = useMemoryState<{ source: string, audio: HTMLAudioElement }[]>("sounds", []);
    const [players, setPlayers] = useMemoryState<{ source: string, playing: boolean }[]>("players", []);
    const [playListOnClick] = useMemoryState<number[]>("playListOnClick", []);
    const onEnd = useCallback((i: number) => {
        let players2 = [...players];
        if (i >= 0 && i < players2.length && players2[i].playing) {
            players2[i].playing = false;
            setPlayers(players2);
        }
        props.onPlayEnded && props.onPlayEnded(i);
    }, [props, players, setPlayers]);
    const renewSounds = useCallback((urls: string[], existedSounds: Array<{ source: string, audio: HTMLAudioElement }>) => {
        return urls.map((url, index) => {
            let a = existedSounds.find((sound) => sound.source === url)?.audio;
            if (!a) {
                a = new Audio(url);
                a.loop = false;
                a.addEventListener('ended', () => { onEnd(index); });
            }
            return { source: url, audio: a };
        });
    }, [onEnd]);
    const renewPlayers = useCallback((urls: string[]) => {
        return urls.map((url) => {
            return { source: url, playing: false };
        });
    }, []);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    const onPlayStart = useCallback((index: number) => {
        props.onPlayStart && props.onPlayStart(index);
    }, [props]);

    const onPlayError = useCallback((index: number) => {
        props.onPlayError && props.onPlayError(index);
    }, [props]);

    useEffect(() => {
        if (reset) {
            setReset(false);
            setSounds(renewSounds(props.urls, sounds));
            setPlayers(renewPlayers(props.urls));
            return;
        }
    }, [reset, props.urls, sounds, setSounds, players, setPlayers, renewSounds, renewPlayers]);

    useEffect(() => {
        if (props.urls.length !== players.length || props.urls.find((url, index) => url !== players[index].source)) {
            setReset(true);
        }
    }, [props.urls, players, setReset]);

    useEffect(() => {
        let changed = false;
        let players2 = [...players];
        players2.forEach((player, index) => {
            if (props.playList.includes(index)) {
                if (!player.playing) {
                    player.playing = true;
                    changed = true;
                }
            } else {
                if (playListOnClick.includes(index)) {
                    player.playing = true;
                    changed = true;
                }
                else if (player.playing) {
                    player.playing = false;
                    changed = true;
                }
            }
        });
        if (changed) {
            setPlayers(players2);
            sounds.forEach((sound, index) => {
                if (index < players2.length && players2[index].playing) {
                    if (sound.audio.paused) {
                        sound.audio.play().then((value) => {
                            onPlayStart(index);
                        }).catch((reason) => {
                            onPlayError(index);
                        });
                    } else {
                        onPlayStart(index);
                    }
                } else {
                    sound.audio.pause();
                    sound.audio.currentTime = 0;
                }
            })
        }
    }, [props.playList, sounds, players, setPlayers, playListOnClick, onPlayStart, onPlayError]);

    return (<>
        <Button variant="secondary" onClick={toggleShow} className="me-2" style={{
            display: props.visible ? "unset" : "none",
            position: "absolute",
        }}>
            <BsSoundwave style={{ strokeWidth: "1px" }} />
        </Button>
        <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Sounds</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {players.map((player, index) => <p key={`player.${index}`}>{player.source},{sounds[index].audio.readyState === 4 ? "ready" : "..."},{player.playing ? "playing" : "paused"}</p>)}
            </Offcanvas.Body>
        </Offcanvas>
    </>);
});

export default SoundPlayers;