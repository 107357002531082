import { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { BsCheck, BsStarFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import useSound from "use-sound";
import { AssistantContext } from "../assistant";
import { GraderStatus } from "../code";
import { Media, MediaBody, MediaHead } from "../navigator";
import { PassportContext } from "../passport";
import { materialService, RecipeType } from "../service";
import { RecipeActionType, RecipeContext } from "./context";

const QUOTES: Array<string> = [
    "令人恐惧还是令人尊敬，二者兼顾。",
    "有时候，在学会走之前要先学会跑！",
    "有时候做事必须有超前意识。",
    "爱你三千遍！",
    "博观而约取，厚积而薄发。",
];

export const AutoGrader: FunctionComponent = () => {
    const { assistant } = useContext(AssistantContext);
    const { passport } = useContext(PassportContext);
    const { recipe, dispatch } = useContext(RecipeContext);

    const [congrats, setCongrats] = useState(false);
    const [sending, setSending] = useState(false);

    const [fanfare] = useSound("https://resource.codemage.cn/fanfare.mp3");

    const getAchievement = useCallback(async () => {
        switch (recipe.style) {
            case RecipeType.COURSE:
            case RecipeType.KNOWLEDGE:
            case RecipeType.BLOG:
            case RecipeType.QUIZ:
                const response = await materialService().get("/achievement", {
                    params: {
                        user: passport.username,
                        recipe: recipe.id,
                    }
                });

                switch (response.status) {
                    case 200:
                        dispatch({
                            type: RecipeActionType.ACHIEVE,
                            achieved: true,
                        });
                        break;

                    case 204:
                        dispatch({
                            type: RecipeActionType.ACHIEVE,
                            achieved: false,
                        });
                        break;

                    default:
                        console.warn("Received invalid token");
                        break;
                }
                break;
            default:
                break;
        }
    }, [recipe.id, recipe.style, dispatch, passport.username]);

    useEffect(() => {
        getAchievement();
    }, [getAchievement]);

    const passed = () => {
        const checks = Array.from(recipe.progresses.values()).reduce((n, val) => val === GraderStatus.PASSED ? n + 1 : n, 0);

        return checks === recipe.progresses.size; // user can pass when all questions have been answered correctly
    }

    const achieve = async () => {
        setSending(true);

        const response = await materialService().post("/achievement/", null, {
            params: {
                user: passport.username,
                recipe: recipe.id,
            }
        });
        switch (response.status) {
            case 200:
                dispatch({
                    type: RecipeActionType.ACHIEVE,
                    achieved: true,
                });

                setCongrats(true);
                setSending(false);
                break;

            default:
                setSending(false);
                break;
        }
    }

    const Quote: FunctionComponent = () => {
        const index = Math.floor(QUOTES.length * Math.random())

        return (
            <p>
                {
                    QUOTES[index]
                }
            </p>
        );
    }

    const MarkComplete: FunctionComponent = () => {
        return (
            <div className="float-end my-3">
                <span className="me-3" style={{ visibility: recipe.achieved || passed() ? "hidden" : "visible" }}>
                    <small>* 需完成所有练习</small>
                </span>
                <Button
                    disabled={!passed() || sending}
                    onClick={() => { achieve(); assistant.sound && fanfare(); }}
                >
                    {
                        sending && <Spinner animation="border" size="sm" role="status" className="me-2" />
                    }
                    标记完成
                </Button>
            </div >
        );
    }

    const NextChapter: FunctionComponent = () => {
        return (
            <div className="float-end my-3">
                <span className="me-3">
                    <BsCheck color="green" />已经完成
                </span>
                <Link to={recipe.next === 90 ? `/achievement` : `/recipe/${recipe.next}`} style={{ textDecoration: "none" }}>
                    <Button variant="primary">下一节</Button>
                </Link>
            </div>
        );
    }

    const Validation: FunctionComponent = () => {
        const render = () => {
            switch (recipe.style) {
                case RecipeType.COURSE:
                case RecipeType.KNOWLEDGE:
                case RecipeType.BLOG:
                case RecipeType.QUIZ:
                    return recipe.next && recipe.achieved ? <NextChapter /> : <MarkComplete />;
                default:
                    return <></>
            }
        };

        return (
            <div className="clearfix">
                {
                    render()
                }
            </div>
        );
    }

    return (
        <>
            <Validation />

            <Modal show={congrats} onHide={() => setCongrats(false)}>
                <Modal.Body>
                    <Media>
                        <MediaHead>
                            <Image
                                width={200}
                                height={200}
                                src="https://resource.codemage.cn/python-comic-tony-congrats.svg"
                                alt="robot"
                            />
                        </MediaHead>
                        <MediaBody>
                            <p className="text-center mt-5 mb-5">
                                <BsStarFill color="gold" size="30" className="mx-1" />
                                <BsStarFill color="gold" size="30" className="mx-1" />
                                <BsStarFill color="gold" size="30" className="mx-1" />
                            </p>
                            <Quote />
                            <p>恭喜过关！再接再厉！</p>
                        </MediaBody>
                    </Media>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setCongrats(false)}>
                        确认
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
