import { FunctionComponent, useContext } from "react";
import { ProgressBar } from "react-bootstrap";
import { RecipeContext } from "./context";

export const RecipeProgress: FunctionComponent = () => {
    const { recipe } = useContext(RecipeContext);
    const visible = recipe.progresses.size > 0;

    return (
        visible ?
            <>
                <h2 className="mt-4 mb-4">进度</h2>
                <ProgressBar
                    now={recipe.score}
                    label={`${recipe.score}%`}
                    className="mb-4"
                />
            </>
            :
            <></>
    );
}