import moment from "moment";
import { FunctionComponent } from "react";
import { Button } from "react-bootstrap";
import { LessonInfo } from "../service";
import { SectionTitle } from "./title";

interface LessonScheduleParams {
    lesson?: LessonInfo;
}

export const LessonSchedule: FunctionComponent<LessonScheduleParams> = (props) => {
    return (
        <>
            <SectionTitle title={"课程信息"} />
            <p>
                时间：{moment(props.lesson?.time).format("YYYY-MM-DD HH:mm")}
            </p>
            <p>
                网课：<Button href={props.lesson?.webinar} style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">腾讯会议</Button>
            </p>
            <p>
                练习：<a href="https://learn.codemage.cn" style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">CodeMage</a>，请通过<a href="https://www.google.cn/chrome/" style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">Google Chrome</a>浏览器登录网站进行操作。
            </p>
            <p>
                时长：{props.lesson?.duration} 分钟
            </p>
        </>
    );
}
