import moment from "moment";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from "recharts";
import { Jumbotron } from "../navigator";
import { PassportContext } from "../passport";
import { AchievementInfo, materialService } from "../service";

interface DataPoint {
    time: number,
    value: number,
}

const AchievementComponent: FunctionComponent = () => {
    const { passport } = useContext(PassportContext);
    const [achievements, setAchievements] = useState<AchievementInfo[]>();

    useEffect(() => {
        const getCourses = async () => {
            const response = await materialService().get(`/achievement?user=${passport.username}`);

            switch (response.status) {
                case 200:
                    setAchievements(response.data as AchievementInfo[]);
                    break;

                default:
                    console.warn("unexpected code path");
                    break;
            }
        }
        getCourses();

    }, [passport.username]);

    const render = () => {

        // group achievements by date (without time)
        const grouped = achievements?.reduce((result, element) => {
            const date = new Date(element.time!).toDateString();
            const value = result.get(date);
            result.set(date, value ? value + 1 : 1);
            return result;
        }, new Map<string, number>())

        // sort achievements by time
        const sorted = Array.from(grouped || new Map(), ([time, count]) => ({
            time: new Date(time).getTime(),
            value: count,
        } as DataPoint)).sort((a, b) => {
            return a.time - b.time;
        });

        // calculate the running total of achievement points
        const accumulated = sorted.reduce((result, element, index) => {
            result.push({
                time: element.time,
                value: index === 0 ? element.value : result[index - 1].value + element.value,
            } as DataPoint);
            return result;
        }, new Array<DataPoint>());

        return (
            <>
                <ResponsiveContainer width="95%" height={500}>
                    <ScatterChart>
                        <XAxis
                            dataKey="time"
                            name="日期"
                            domain={["auto", "auto"]}
                            tickFormatter={(time) => moment(time).format("YYYY-MM-DD")}
                            type="number"
                        />

                        <YAxis
                            dataKey="value"
                            name="成就"
                        />

                        <Scatter
                            name="成就"
                            data={accumulated}
                            fill="#007bff"
                            line={{ stroke: "#eee" }}
                            lineJointType="monotoneX"
                            lineType="joint"
                        />
                    </ScatterChart>
                </ResponsiveContainer>
            </>
        )
    };

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1 className="display-4">成就</h1>
                </Container>
            </Jumbotron>
            <Container>
                {
                    achievements?.length && render()
                }
            </Container>
        </>
    )

}

export { AchievementComponent }
