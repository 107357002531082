import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CircleInfo, materialService } from "../service";
import { PassportContext } from "../passport";

interface CircleTileParams {
    circle: CircleInfo;
};

const CircleTile: FunctionComponent<CircleTileParams> = ({ circle }) => {
    return (
        <Col key={circle.id}>
            <Card key={circle.id} className="mt-4">
                <Card.Img variant="top" src={circle.thumbnail} style={{ objectFit: "cover" }} />
                <Card.Body>
                    <Card.Title>
                        <Link to={`/circle/${circle.id}`} style={{ textDecoration: "none" }}>
                            {circle.name}
                        </Link>
                    </Card.Title>
                    <Card.Text>
                        { }
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    )
}

const CircleList: FunctionComponent = () => {
    const { passport } = useContext(PassportContext);
    const [circles, setCircles] = useState<CircleInfo[]>();

    useEffect(() => {
        const getCourses = async () => {
            const response = await materialService().get("/circle", {
                params: {
                    user: passport.username,
                }
            });

            switch (response.status) {
                case 200:
                    setCircles(response.data as CircleInfo[]);
                    break;
                default:
                    console.warn("Unexpected behavior, please contact site admin");
                    break;
            }
        }
        getCourses();
    }, [passport.username]);

    return (
        <>
            <Container>
                <CardGroup>
                    <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                        {
                            circles?.map(circle =>
                                <CircleTile circle={circle} key={circle.id} />
                            )
                        }
                    </Row>
                </CardGroup>
            </Container>
        </>
    );
}

export { CircleList };
