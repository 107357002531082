import { FunctionComponent, useContext, useEffect } from "react";
import { CodeMetadata, RawMetadata } from ".";
import { CodeCell } from "../code";
import { MarkdownCell } from "../markdown";
import { RawCell } from "./rawcell";
import { RecipeContext } from "./context";

export const NotebookEdit: FunctionComponent = () => {
    const { recipe } = useContext(RecipeContext);

    useEffect(() => {
        console.log("Rendering notebook", recipe.id);
    }, [recipe.id]);

    return (
        <>
            {
                recipe.notebook?.cells.map((cell, index) => {
                    const key = `notebook-${recipe.id}-cell-${index}`;

                    switch (cell.cell_type) {
                        case "code":
                            return <CodeCell
                                key={key}
                                lines={cell.source}
                                recipe={`${recipe.id}`}
                                metadata={cell.metadata as CodeMetadata}
                            />;
                        case "markdown":
                            return <MarkdownCell
                                key={key}
                                source={cell.source}
                            />;
                        case "raw":
                            return <RawCell
                                key={key}
                                metadata={cell.metadata as RawMetadata}
                            />;
                        default:
                            return undefined;
                    }
                })
            }
        </>
    );
}
