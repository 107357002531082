import { BarDatum, ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ErrorCode, ErrorType } from "../code";
import { notebookService } from "../service";

interface DashboardParams {
    sections?: number[];
    prerequisites?: number[];
    lesson?: number;
}

interface SubmissionEntry extends BarDatum {
    snippet: string;
    ok: number;
    error: number;
}

interface ErrorEntry {
    id: string;
    label: string;
    value: number;
}

interface SubmissionReportedParams {
    submissions: SubmissionEntry[];
}

interface ErrorReportedParams {
    errors: ErrorEntry[];
}

const SubmissionReport: FunctionComponent<SubmissionReportedParams> = (params) => {
    const visible = params.submissions.length > 0;
    const ok = params.submissions.reduce((total, current) => total + current.ok, 0);
    const error = params.submissions.reduce((total, current) => total + current.error, 0);
    const warn = error * 2 > ok;

    return (
        visible ?
            <>
                <p>下面是章节中所有片段的代码提交统计，出错的代码提交用红色表示，{warn ? "这次错误比较多，革命尚未成功同志尚需努力" : "总体情况符合预期"}：</p>
                <Row style={{ height: "600px" }}>
                    <ResponsiveBar
                        data={params.submissions}
                        keys={["ok", "error", "aborted"]}
                        indexBy="snippet"
                        margin={{ top: 60, right: 100, bottom: 60, left: 100 }}
                        padding={0.3}
                        colors={{ scheme: "nivo" }}
                        axisBottom={null}
                        legends={[
                            {
                                dataFrom: "keys",
                                anchor: "bottom-right",
                                direction: "column",
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: "left-to-right",
                            }
                        ]}
                    />
                </Row>
            </>
            :
            <></>
    );
}

const ErrorReport: FunctionComponent<ErrorReportedParams> = (params) => {
    const visible = params.errors.length > 0;

    return (
        visible ?
            <Row style={{ height: "400px" }} >
                <Col xs={12} md={6}>
                    <ResponsivePie
                        data={params.errors}
                        margin={{ top: 80, right: 100, bottom: 80, left: 100 }}
                        innerRadius={0.5}
                        colors={{ scheme: "nivo" }}

                    />
                </Col>
                <Col>
                    <p>左边显示了错误提交的具体类型，请阅读错误原因和改进方法：</p>
                    <ol>
                        {
                            params.errors.map((entry) => {
                                if (entry.id in ErrorType) {
                                    return <li key={entry.id}>{entry.id}：{ErrorCode[entry.id as ErrorType]}</li>;
                                } else {
                                    return <li key={entry.id}>{entry.id}：请联系导师沟通错误原因以及改进方案。</li>;
                                }
                            })
                        }
                    </ol>
                </Col>
            </Row >
            :
            <></>
    );
}

export const Dashboard: FunctionComponent<DashboardParams> = (params) => {
    const [submissions, setSubmissions] = useState<SubmissionEntry[]>([]);
    const [errors, setErrors] = useState<ErrorEntry[]>([]);

    const getErrors = useCallback(async (sections: number[]) => {
        console.log("Query error statistics for the recipe");

        const response = await notebookService().get(`/report/error?recipes=${sections.join()}`);

        switch (response.status) {
            case 200:
                setErrors(response.data as ErrorEntry[]);
                break;
            default:
                console.warn("unexpected behavior, please contact site admin");
        }
    }, []);

    const getSubmissions = useCallback(async (sections: number[]) => {
        console.log("Query submission statistics for the recipe");

        const response = await notebookService().get(`/report/submission?recipes=${sections.join()}`);

        switch (response.status) {
            case 200:
                setSubmissions(response.data as SubmissionEntry[]);
                break;
            default:
                console.warn("unexpected behavior, please contact site admin");
        }
    }, []);

    useEffect(() => {
        if (params.sections && params.prerequisites && params.lesson) {
            const recipes = [...params.sections, ...params.prerequisites];

            getErrors(recipes);
            getSubmissions(recipes);
        }
    }, [params.sections, params.prerequisites, params.lesson, getErrors, getSubmissions]);

    return (
        <>
            <SubmissionReport submissions={submissions} />
            <ErrorReport errors={errors} />
        </>
    );
}
