import { Message } from "../message";

export * from "./service";

export enum CourseType {
    FREEMIUM = "FREEMIUM",
    PREMIUM = "PREMIUM",
}

export enum CourseLevel {
    BEGINNER = "BEGINNER",
    ELEMENTARY = "ELEMENTARY",
    INTERMEDIATE = "INTERMEDIATE",
    ADVANCED = "ADVANCED"
}

export interface CourseInfo {
    alias: string;
    registered: boolean;
    title: string;
    type: CourseType;
    level: CourseLevel;
    summary: string;
    sequence: number;
    special: boolean;
    intro: string;
    thumbnail: string;
    author: string;
    chapters: ChapterInfo[];
}

export interface ChapterInfo {
    id: number;
    title: string;
    trial: boolean;
    summary: string;
    sections: RecipeInfo[];
    tags: string[];
}

export enum RecipeType {
    COURSE = "COURSE",
    BLOG = "BLOG",
    KNOWLEDGE = "KNOWLEDGE",
    QUIZ = "QUIZ",
}

export interface RecipeInfo {
    id: number;
    title: string;
    summary: string;
    thumbnail: string;
    content?: string;
    chapter_id?: number;
    chapter_title?: string;
    course_alias?: string;
    course_title?: string;
    next?: number;
    type: RecipeType;
    prerequisites: RecipeInfo[];
    relatives: RecipeInfo[];
    sequence: number;
}

export interface AchievementInfo {
    id?: number;
    recipe: number;
    user?: string;
    chapter?: string;
    time?: string;
}

export interface CircleInfo {
    id: number;
    name?: string;
    tutor: number;
    thumbnail: string;
    lessons: LessonInfo[];
}

export interface LessonInfo {
    id: number;
    registered: boolean;
    name: string;
    time: string;
    duration: number;
    webinar: string;
    record: string;
    summary: string;
    tutor: number;
    chapter: ChapterInfo;
    prerequisites: RecipeInfo[];
    relatives: RecipeInfo[];
}

export interface TutorInfo {
    id: number;
    portrait: string;
    icon: string;
    name: string;
    english: string;
    intro: string;
    title: string;
    bio: string
};

export interface StudyInfo {
    id: number;
    title: string;
    summary: string;
    thumbnail: string;
    content: string;
};

export interface ScholarInfo {
    id: number;
    title: string;
    summary: string;
    thumbnail: string;
    content: string;
};

export interface SubmissionInfo {
    id: string;
    snippet: string;
    username: string;
    grade: number;
    recipe: number;
    execute_reply: Message;
    execute_input: Message;
    execute_result: Message;
    stream: Message[];
}

export interface UserInfo {
    code: string;
    username: string;
    password: string;
    fullname: string;
    //guardian: string;
    // email?: string;
}