import { FunctionComponent, useReducer, useContext } from "react";
import { Stack, Spinner } from "react-bootstrap";
import { BiPlayCircle } from 'react-icons/bi';
import * as StoryData from "./storydata";
import StoryEvent from "./storyevent";
import { StoryPreferenceContext, StoryProgressContext, StoryProgressActionType, initialStoryProgress, reducerStoryProgress } from "./storyshow";

interface StoryCollectionParamsType {
    storiesList: (StoryData.StoryContent | undefined)[],
    className?: string,
    classNameCurrentIndicator?: string,
};

export const StoryCollection: FunctionComponent<StoryCollectionParamsType> = ({
    storiesList,
    className,
    classNameCurrentIndicator
}) => {
    const [currentPreviewProgress, dispatchPreviewProgress] = useReducer(reducerStoryProgress, { ...initialStoryProgress });
    const currentStoryPreference = useContext(StoryPreferenceContext);
    const currentStoryProgress = useContext(StoryProgressContext);

    return (<div className={className}>
        <Stack direction="horizontal" gap={3}>
            {storiesList.map((x, i) => <div style={{ position: "relative" }} key={i}
                onClick={() => {
                    if (!x || !x.id || x.id === "" || x.id === currentStoryProgress.state.storyId) {
                        return;
                    }
                    currentStoryProgress.dispatch({ type: StoryProgressActionType.STORY, id: x.id ?? "", autoStart: true });
                }}>{!x?.events || !x?.events[0] ? <div key={i} style={{
                    position: "relative",
                    width: `${currentStoryPreference.state.effectiveSceneWidth}${currentStoryPreference.state.effectiveSceneWidthUnit}`,
                    height: `${currentStoryPreference.state.effectiveSceneHeight}${currentStoryPreference.state.effectiveSceneHeightUnit}`,
                }}>
                    <div className="absolute-center"><Spinner animation="border" /></div>
                </div> : <StoryProgressContext.Provider value={{
                    state: currentPreviewProgress,
                    dispatch: dispatchPreviewProgress
                }}>
                    <StoryEvent
                        innerController={false}
                        eventIndex={0}
                        content={x.events[0]}
                        playerEnded={false}
                        previewMode={false}
                    />
                </StoryProgressContext.Provider>}
                {currentStoryProgress.state.storyId !== x?.id ? null :
                    <BiPlayCircle className={classNameCurrentIndicator} />}
            </div>
            )}
        </Stack>
    </div>);
}