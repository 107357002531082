import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, CardGroup, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { materialService, TutorInfo } from "../service";

export const TutorList: FunctionComponent = () => {
    const [tutors, setTutors] = useState<TutorInfo[]>();

    useEffect(() => {
        console.log("Loading instructors");

        // fetch tutor from backend
        const getTutors = async () => {
            const response = await materialService().get(`tutor`);

            switch (response.status) {
                case 200:
                    const result = response.data as TutorInfo[];
                    setTutors(result);
                    break;

                default:
                    console.warn("unexpected behavior, please contact site admin");
                    break;
            }
        }
        getTutors();

    }, []);

    return (
        <Container>
            <CardGroup>
                {
                    tutors?.map((tutor, index) =>
                        <Card
                            key={index}
                            className="mt-4"
                            style={{ minWidth: "18rem", maxWidth: "22rem" }}>
                            <Card.Img variant="top" src={tutor.portrait} style={{ objectFit: "cover" }} />
                            <Card.Body>
                                <Card.Title>
                                    <Link to={`/tutor/${tutor.id}`} style={{ textDecoration: "none" }}>
                                        {tutor.name}
                                    </Link>
                                </Card.Title>
                                <Card.Text>
                                    {tutor.bio}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    )
                }
            </CardGroup>
        </Container>
    )
}