import { FunctionComponent, useContext } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsStopFill } from "react-icons/bs";
import useSound from "use-sound";
import { AssistantContext } from "../assistant";
import { KernelContext } from "../kernel";
import { KernelInfoRequest } from "../message";
import { PassportContext, RoleName } from "../passport";

interface Params {
    recipe: string;
}

export const InterruptCommand: FunctionComponent<Params> = ({ recipe }) => {
    const { assistant } = useContext(AssistantContext);
    const { passport } = useContext(PassportContext);
    const { kernel } = useContext(KernelContext);

    const [down] = useSound("https://resource.codemage.cn/pop-down.mp3");
    const [up] = useSound("https://resource.codemage.cn/pop-up-off.mp3");

    const executable = passport.roles.includes(RoleName.CODER);

    const interrupt = () => {
        console.log("Interrupt the kernel for recipe", recipe);

        const request: KernelInfoRequest = {
            id: recipe,
            recipe: recipe,
            operation: "interrupt",
        };

        kernel.stomp?.publish({
            destination: "/notebook/kernel",
            body: JSON.stringify(request),
        });
    }

    return (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="explanation">停止执行</Tooltip>}
        >
            <Button variant="light"
                type="button"
                size="sm"
                className="ms-1"
                disabled={!executable}
                onClick={interrupt}
                onMouseDown={() => assistant.sound && down()}
                onMouseUp={() => assistant.sound && up()}
            >
                <BsStopFill size="20" />
            </Button>
        </OverlayTrigger>
    )
}

