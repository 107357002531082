import jwt_decode from "jwt-decode";
import { createContext, Dispatch, Reducer } from "react";
import { LocalStorageKey } from "../constant";

export enum PassportActionType {
    LOGIN,
    EXPIRE,
    LOGOUT,
}

export interface PassportLoginAction {
    type: typeof PassportActionType.LOGIN;
    username: string;
    token: string | undefined;
}

export interface PassportExpireAction {
    type: typeof PassportActionType.EXPIRE;
}

export interface PassportLogoutAction {
    type: typeof PassportActionType.LOGOUT;
}

export type PassportAction = PassportLoginAction | PassportExpireAction | PassportLogoutAction;

export interface PassportStore {
    authenticated: boolean;
    expired: boolean;
    username: string;
    roles: Array<string>;
    token: string | undefined;
}

export interface JwtTokenFormat {
    exp: number;
    iat: number;
    roles: string;
    sub: string;
}

export const passportReducer: Reducer<PassportStore, PassportAction> = (state, action) => {
    switch (action.type) {
        case PassportActionType.LOGIN:
            localStorage.setItem(LocalStorageKey.ACCESSTOKEN, action.token!);
            localStorage.setItem(LocalStorageKey.USERNAME, action.username);

            return {
                ...state,
                authenticated: true,
                username: action.username,
                roles: jwt_decode<JwtTokenFormat>(action.token || "").roles.split(","),
                token: action.token,
            };

        case PassportActionType.EXPIRE:
            return {
                ...state,
                expired: true,
            }

        case PassportActionType.LOGOUT:
            localStorage.removeItem(LocalStorageKey.ACCESSTOKEN);
            localStorage.removeItem(LocalStorageKey.USERNAME);

            return {
                ...state,
                expired: false,
                authenticated: false,
                username: "游客",
                roles: [],
                token: undefined,
            };

        default:
            return state;
    }
}

interface ContextProps {
    passport: PassportStore;
    dispatch: Dispatch<PassportAction>
}

export const PassportContext = createContext({} as ContextProps);