import assert from "assert";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { LearnFooter } from "../navigator";
import { LessonInfo, materialService } from "../service";
import { CodeReview } from "./code";
import { LearningContent as LessonContent } from "./content";
import { LessonIntro } from "./intro";
import { LessonRecord } from "./record";
import { LessonSchedule } from "./schedule";
import { LessonTutor } from "./tutor";

const LessonDetail: FunctionComponent = () => {
    const [lesson, setLesson] = useState<LessonInfo>();
    const { id } = useParams();

    assert(id, "id should not be undefined");

    const getLesson = useCallback(async (id: string) => {
        const response = await materialService().get(`/lesson/${id}`);

        switch (response.status) {
            case 200:
                setLesson(response.data as LessonInfo);
                break;
            default:
                console.warn("Unexpected behavior, please contact site admin");
                break;
        }
    }, []);

    useEffect(() => {
        console.log("Loading lesson", id);

        getLesson(id);
    }, [id, getLesson]);

    return (
        <Container>
            <LessonTutor id={lesson?.id} />

            <LessonIntro id={lesson?.id} tags={lesson?.chapter.tags} />

            <LessonSchedule lesson={lesson} />

            <LessonContent
                registered={lesson?.registered}
                thinking={lesson?.chapter}
                coding={lesson?.prerequisites || []}
                relatives={lesson?.relatives || []}
            />

            <CodeReview
                sections={lesson?.chapter.sections.reduce((total, current) => [...total, current.id], [] as number[])}
                prerequisites={lesson?.prerequisites.reduce((total, current) => [...total, current.id], [] as number[])}
                lesson={lesson?.id}
            />

            <LessonRecord record={lesson?.record} />

            <LearnFooter />
        </Container>
    );
}

export { LessonDetail };
