import { FunctionComponent, useContext } from "react";
import { Card, CardGroup, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RecipeContext } from "./context";

export const PrerequisiteList: FunctionComponent = () => {
    const { recipe } = useContext(RecipeContext);

    const size = recipe.prerequisites?.length || 0;

    return (
        size > 0 ?
            <>
                <h2 className="mt-4 mb-4">依赖</h2>

                <CardGroup>
                    <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                        {
                            recipe.prerequisites?.sort((a, b) => {
                                return a.sequence - b.sequence;
                            }).map(recipe =>
                                <Col key={recipe.id}>
                                    <Card key={recipe.id} className="my-2">
                                        <Card.Img variant="top" src={recipe.thumbnail} style={{ objectFit: "cover" }} />
                                        <Card.Body>
                                            <Card.Title>
                                                <Link to={`/recipe/${recipe.id}`} style={{ textDecoration: "none" }}>
                                                    {recipe.title}
                                                </Link>
                                            </Card.Title>
                                            <Card.Text>{recipe.summary}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        }
                    </Row>
                </CardGroup>
            </>
            :
            <></>
    );
}
