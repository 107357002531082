import { createContext, Dispatch, Reducer } from "react";
import { DifficultyLevel } from "./setting";

export enum AssistantActionType {
    NOTIFY,
    ACKNOWLEDGE,
    SOUND,
    EXPLANATION,
    SETTING,
}

export interface AssistantNotifyAction {
    type: typeof AssistantActionType.NOTIFY;
    toast: boolean;
    hint: boolean;
    title: string;
    subtitle: string;
    content: string;
}

export interface AssistantAcknowledgeAction {
    type: typeof AssistantActionType.ACKNOWLEDGE;
}

export interface AssistantSoundAction {
    type: typeof AssistantActionType.SOUND;
}

export interface AssistantExplanationAction {
    type: typeof AssistantActionType.EXPLANATION;
}

export interface AssistantSettingAction {
    type: typeof AssistantActionType.SETTING;
    difficulty: DifficultyLevel;
}

export type AssistantAction = AssistantNotifyAction | AssistantAcknowledgeAction | AssistantSoundAction | AssistantExplanationAction | AssistantSettingAction;

export interface AssistantStore {
    // system notification
    toast: boolean;
    title: string;
    subtitle: string;
    content: string;

    // sound effect
    sound: boolean;

    // code explanation
    explanation: boolean;

    // user setting
    difficulty: DifficultyLevel;
}

export const assistantReducer: Reducer<AssistantStore, AssistantAction> = (state, action) => {
    switch (action.type) {
        case AssistantActionType.NOTIFY:
            return {
                ...state,
                toast: action.toast,
                title: action.title,
                subtitle: action.subtitle,
                content: action.content,
            };

        case AssistantActionType.ACKNOWLEDGE:
            return {
                ...state,
                toast: false,
            };

        case AssistantActionType.SOUND:
            return {
                ...state,
                sound: !state.sound,
            };

        case AssistantActionType.EXPLANATION:
            return {
                ...state,
                explanation: !state.explanation,
            }

        case AssistantActionType.SETTING:
            return {
                ...state,
                difficulty: action.difficulty,
            }

        default:
            return state;
    }
}

export const AssistantContext = createContext({} as {
    assistant: AssistantStore;
    dispatch: Dispatch<AssistantAction>
});

