import assert from "assert";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { MarkdownCell } from "../markdown";
import { Jumbotron, LearnFooter } from "../navigator";
import { Notebook, RawCell, RawMetadata } from "../recipe";
import { materialService, StudyInfo } from "../service";

interface StudyContentParams {
    study?: StudyInfo;
}

const StudyContent: FunctionComponent<StudyContentParams> = ({ study }) => {
    var notebook = JSON.parse(study?.content || "{\"cells\": []}") as Notebook;

    return (
        <>
            {
                notebook.cells.map((cell, index) => {
                    switch (cell.cell_type) {
                        case "markdown":
                            return <MarkdownCell key={index} source={cell.source} />;
                        case "raw":
                            return <RawCell key={index} metadata={cell.metadata as RawMetadata} />;
                        default:
                            return undefined;
                    }
                })
            }
        </>
    );
}

const StudyDetail: FunctionComponent = () => {
    const { id } = useParams();
    const [study, setStudy] = useState<StudyInfo>();

    assert(id, "id should not be undefined");

    const getStudy = useCallback(async (id: string) => {
        const response = await materialService().get(`/study/${id}`);

        switch (response.status) {
            case 200:
                setStudy(response.data as StudyInfo);
                break;

            default:
                console.warn("unexpected behavior, please contact site admin");
                break;
        }
    }, []);

    useEffect(() => {
        console.log("Loading study", id);

        getStudy(id);
    }, [id, getStudy]);

    return (
        <>
            <Jumbotron>
                <Container>
                    <h1>{study?.title}</h1>
                </Container>
            </Jumbotron>
            <Container>
                <StudyContent study={study} />

                <LearnFooter />
            </Container>
        </>
    );
}

export { StudyDetail };
