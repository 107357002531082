import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Badge, Card, CardGroup, Col, Row } from "react-bootstrap";
import { BsFillLockFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { PassportContext } from "../passport";
import { ChapterInfo, materialService, RecipeInfo } from "../service";
import { SectionTitle } from "./title";

interface SectionListParams {
    chapter?: ChapterInfo;
    registered?: boolean;
}

interface ContentListParams {
    sections: RecipeInfo[];
};

interface ContentDetailParams {
    section: RecipeInfo;
    registered?: boolean;
};

const SectionList: FunctionComponent<SectionListParams> = ({ chapter, registered }) => {
    const sections = chapter?.sections || [];

    return (
        <>
            {
                sections.length > 0 &&
                <p>
                    以下是学习目标，需要完成所有内容。
                </p>
            }
            <CardGroup>
                <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                    {
                        sections.map(section =>
                            <SectionDetail registered={registered} section={section} key={section.id} />
                        )
                    }
                </Row>
            </CardGroup>
        </>
    );
}

const SectionDetail: FunctionComponent<ContentDetailParams> = ({ section, registered }) => {
    const { passport } = useContext(PassportContext);
    const [achieved, setAchieved] = useState<boolean>();

    useEffect(() => {
        const getAchievement = async () => {
            const response = await materialService().get("/achievement", {
                params: {
                    user: passport.username,
                    recipe: section.id,
                }
            });

            switch (response.status) {
                case 200:
                    setAchieved(true);
                    break;

                case 204:
                    setAchieved(false);
                    break;

                default:
                    console.warn("Received invalid token");
                    break;
            }
        }
        getAchievement();

    }, [section.id, passport.username]);

    return (
        <Col key={section.id}>
            <Card key={section.id} className="my-2">
                <Card.Img
                    variant="top"
                    src={section.thumbnail}
                    style={{ objectFit: "cover" }}
                />
                < Card.Body>
                    <Card.Title>
                        {
                            registered ?
                                <Link to={`/recipe/${section.id}`} style={{ textDecoration: "none" }}>
                                    {section.title}
                                </Link>
                                :
                                <>
                                    {section.title}
                                    <BsFillLockFill color="orange" className="ms-2" />
                                </>
                        }
                        {
                            registered &&
                            <Badge bg={achieved ? "success" : "warning"} className="float-end">
                                {achieved ? "已完成" : "进行中"}
                            </Badge>
                        }
                    </Card.Title>
                    <Card.Text>
                        {section.summary}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
}

const CodingList: FunctionComponent<ContentListParams> = ({ sections }) => {
    return (
        <>
            {
                sections.length > 0 &&
                <p>
                    以下是相关的编程知识点，请及时完成的学习和练习。纸上得来终觉浅，绝知此事要躬行。
                </p>
            }
            <CardGroup>
                <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                    {
                        sections.map(section =>
                            <Col key={section.id}>
                                <Card key={section.id} className="my-2">
                                    <Card.Img variant="top" src={section.thumbnail} style={{ objectFit: "cover" }} />
                                    <Card.Body>
                                        <Card.Title>
                                            <Link to={`/recipe/${section.id}`} style={{ textDecoration: "none" }}>
                                                {section.title}
                                            </Link>
                                        </Card.Title>
                                        <Card.Text>
                                            {section.summary}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
            </CardGroup>
        </>
    );
}

const RelativeList: FunctionComponent<ContentListParams> = ({ sections }) => {
    return (
        <>
            {
                sections.length > 0 &&
                <p>
                    以下是拓展的知识点，推荐学习以开阔知识面。
                </p>
            }
            <CardGroup>
                <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                    {
                        sections.map(section =>
                            <Col key={section.id}>
                                <Card key={section.id} className="my-2">
                                    <Card.Img variant="top" src={section.thumbnail} style={{ objectFit: "cover" }} />
                                    <Card.Body>
                                        <Card.Title>
                                            <Link to={`/recipe/${section.id}`} style={{ textDecoration: "none" }}>
                                                {section.title}
                                            </Link>
                                        </Card.Title>
                                        <Card.Text>
                                            {section.summary}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
            </CardGroup>
        </>
    );
}

interface LearningContentParams {
    registered?: boolean;
    thinking?: ChapterInfo;
    coding: RecipeInfo[];
    relatives: RecipeInfo[];
}

const LearningContent: FunctionComponent<LearningContentParams> = ({ registered, thinking, coding, relatives }) => {
    return (
        <>
            <SectionTitle title={"学习目标"} />

            <SectionList chapter={thinking} registered={registered} />
            <CodingList sections={coding} />
            <RelativeList sections={relatives} />
        </>
    );
}

export { LearningContent };
