import React, { FunctionComponent } from "react";

const Jumbotron: FunctionComponent = ({ children }) => {
    return (
        <div
            className="fluid mb-5"
            style={{
                padding: "4em",
                backgroundColor: "hsl(210deg, 55%, 92%)",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
        >
            {children}
        </div>
    );
}

export { Jumbotron };
