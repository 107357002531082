import { FunctionComponent, useState, useEffect, useContext, useCallback } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import remarkMath from "remark-math";
import ReactMarkdown from "react-markdown";
import { Property } from "csstype";
import { CodeBlock } from "../markdown/codeblock";
import { ImageBlock } from "../markdown/imageblock";
import { ParagraphBlock } from "../markdown/paragraphblock";
import { BlockQuote } from "../markdown/blockquote";
import { StoryEventBoardExamType, StoryEventBoardPosterType, StoryEventBoardNotesType } from "./storydata";
import { storyResourceUrl } from "./storyresources";
import { StoryPreferenceContext, StoryPreferenceActionType } from './storyshow';

interface StoryBoardParams {
    rect: number[];
    type?: "exam" | "poster" | "notes";
    reset?: boolean;
    content: StoryEventBoardExamType | StoryEventBoardPosterType | StoryEventBoardPosterType[] | StoryEventBoardNotesType | undefined;
    effect?: string;
    fontFamily?: string;
    fontColor?: Property.Color;
    fontSize?: Property.FontSize<number>;
    interactive?: boolean;
    isSoundPlaying?: boolean;
    onPlayerClicked?: () => void;
    onExamSubmit?: (examSelection: number[]) => void;
}

interface ExamOptionType { value: number, text: string };

export const StoryBoard: FunctionComponent<StoryBoardParams> = ((params) => {
    const currentStoryPreference = useContext(StoryPreferenceContext);
    const calcRectSize = useCallback((rect: number[]) => { return currentStoryPreference.state.calcRectSize(rect); }, [currentStoryPreference]);
    const [posters, setPosters] = useState<StoryEventBoardPosterType[]>();
    const [notes, setNotes] = useState<StoryEventBoardNotesType>();
    const [exam, setExam] = useState<StoryEventBoardExamType>();
    const [multiple, setMultiple] = useState(false);
    const [examOptions, setExamOptions] = useState<ExamOptionType[][]>([]);
    const [examSelection, setExamSelection] = useState<number[]>([]);
    const [magnifiedIndex, setMagnifiedIndex] = useState(-1);

    useEffect(() => {
        const type = params.type ?? "poster";
        if (type === "poster") {
            if (Array.isArray(params.content)) {
                setPosters(params.content as StoryEventBoardPosterType[]);
            } else {
                setPosters([params.content as StoryEventBoardPosterType]);
            }
        }
        const notes2 = type !== "notes" ? undefined : params.content as StoryEventBoardNotesType;
        setNotes(notes2);
        const exam2 = type !== "exam" ? undefined : params.content as StoryEventBoardExamType;
        const examOptions2: ExamOptionType[][] = [];
        const colsPerRow = exam2?.colsPerRow ?? exam2?.options?.length ?? 1;
        exam2?.options?.forEach((option, index) => {
            const offset = (index % colsPerRow);
            if (offset === 0) {
                examOptions2.push([]);
            }
            examOptions2.slice(-1)[0].push({ value: index, text: option });
        });
        const lastRow = examOptions2.slice(-1)[0];
        while (lastRow && lastRow.length < colsPerRow) {
            lastRow.push({ value: -1, text: "" });
        }
        setExamOptions(examOptions2);
        setMultiple((exam2?.answer?.length ?? 0) > 1);
        setExam(exam2);
        if (params.reset) {
            setExamSelection([]);
        }
    }, [params]);

    const {
        defaultSceneWidth,
        defaultSceneHeight,
        effectiveSceneWidth,
        effectiveSceneHeight,
        // effectiveSceneWidthUnit,
        effectiveSceneHeightUnit,
    } = currentStoryPreference.state;
    const scaleToDefaultSize = [
        (effectiveSceneWidth / defaultSceneWidth),
        (effectiveSceneHeight / defaultSceneHeight),
    ];
    if (!params.content) {
        return null;
    } else if (posters && posters.length > 0) {
        return (
            <div>{posters.map((posterOne, index) => {
                const rectSize = calcRectSize(posterOne.rect ?? params.rect);
                return (<div key={index}>
                    <div className={`storyBoard${!posterOne.magnify || currentStoryPreference.state.magnifiedRatio !== 1 ? "" : " magnify"}`} style={{
                        left: rectSize.left + currentStoryPreference.state.effectiveSceneWidthUnit,
                        top: rectSize.top + currentStoryPreference.state.effectiveSceneHeightUnit,
                        width: rectSize.width + currentStoryPreference.state.effectiveSceneWidthUnit,
                        height: rectSize.height + currentStoryPreference.state.effectiveSceneHeightUnit,
                        outline: posterOne.border
                    }} onClick={() => {
                        if (!params.interactive) return;
                        if (true !== posterOne.magnify) {
                            return;
                        }
                        const magnified = magnifiedIndex === index;
                        const rectWidth = (rectSize.width ?? currentStoryPreference.state.effectiveSceneWidth);
                        const rectHeight = (rectSize.height ?? currentStoryPreference.state.effectiveSceneHeight);
                        const ratioWidth = currentStoryPreference.state.effectiveSceneWidth / rectWidth;
                        const ratioHeight = currentStoryPreference.state.effectiveSceneHeight / rectHeight;
                        const ratio = Math.min(ratioWidth, ratioHeight);
                        const magnifiedWidth = ratio === ratioWidth ? rectWidth : (currentStoryPreference.state.effectiveSceneWidth / ratio);
                        const magnifiedHeight = ratio === ratioHeight ? rectHeight : (currentStoryPreference.state.effectiveSceneHeight / ratio);
                        const magnifiedRect = {
                            left: (rectSize.left ?? 0) - ((magnifiedWidth - rectWidth) / 2),
                            top: (rectSize.top ?? 0) - ((magnifiedHeight - rectHeight) / 2),
                            width: magnifiedWidth,
                            height: magnifiedHeight,
                        };
                        const offsetLeft = ((currentStoryPreference.state.effectiveSceneWidth - magnifiedWidth) / 2 - magnifiedRect.left);
                        const offsetTop = ((currentStoryPreference.state.effectiveSceneHeight - magnifiedHeight) / 2 - magnifiedRect.top);
                        currentStoryPreference.dispatch({
                            type: StoryPreferenceActionType.SET,
                            value: {
                                magnifiedRatioWidth: magnified ? 1 : ratioWidth,
                                magnifiedRatioHeight: magnified ? 1 : ratioHeight,
                                magnifiedRatio: magnified ? 1 : ratio,
                                magnifiedRect: magnified ? undefined : magnifiedRect,
                                sceneOffset: magnified ? undefined : { left: offsetLeft, top: offsetTop },
                            }
                        })
                        setMagnifiedIndex(magnified ? -1 : index);
                        params.onExamSubmit && params.onExamSubmit(magnified ? [] : [index]);
                    }}>
                        <div className="poster">
                            <img style={{ width: "100%" }} alt={posterOne.caption} src={storyResourceUrl(posterOne.image)} />
                            <div className="icon-poster-magnify"><i /></div>
                        </div>
                        <label className="poster-title" style={{
                            fontFamily: posterOne.fontFamily,
                            fontSize: !posterOne.fontSize ? undefined : `${parseFloat(posterOne.fontSize + "") * scaleToDefaultSize[1]}${effectiveSceneHeightUnit}`,
                            color: posterOne.fontColor,
                        }}>{posterOne.caption}</label>
                    </div>
                </div>);
            })}</div>
        );
    } else {
        const rectSize = calcRectSize(params.rect);
        return (
            <div className="storyBoard" style={{
                left: rectSize.left + currentStoryPreference.state.effectiveSceneWidthUnit,
                top: rectSize.top + currentStoryPreference.state.effectiveSceneHeightUnit,
                width: rectSize.width + currentStoryPreference.state.effectiveSceneWidthUnit,
                height: rectSize.height + currentStoryPreference.state.effectiveSceneHeightUnit,
                fontSize: `${scaleToDefaultSize[0] * 100}%`
            }}>{exam
                ? <div className="exam">
                    <h1>{exam.question}</h1>
                    <h3>（请在下列选项中选出正确答案）</h3>
                    <Container>
                        {examOptions.map((row, rowIndex) => <Row key={rowIndex}>
                            {row.map((col, colIndex) => <Col key={colIndex}>
                                <Button style={{ visibility: col.value < 0 ? "hidden" : "visible", width: "100%", marginTop: "0.1%", fontSize: "125%" }} disabled={col.value < 0}
                                    variant={examSelection.includes(col.value) ? "secondary" : "light"}
                                    active={examSelection.includes(col.value)}
                                    onClick={(e) => {
                                        if (!params.interactive) return;
                                        let examSelection2 = examSelection.filter((colValue) => colValue !== col.value);
                                        if (examSelection2.length === examSelection.length) {
                                            if (multiple) {
                                                examSelection2.push(col.value);
                                            } else {
                                                examSelection2 = [col.value];
                                            }
                                        }
                                        setExamSelection(examSelection2);
                                        if (!multiple && !exam.submitButton) {
                                            params.onExamSubmit && params.onExamSubmit(examSelection2);
                                        }
                                    }}
                                >{col.text}</Button>
                            </Col>)}
                        </Row>)}
                    </Container>
                    {!exam.submitButton ? null : <Button onClick={() => {
                        if (!params.interactive) return;
                        params.onExamSubmit && params.onExamSubmit(examSelection);
                    }}>{exam.submitButton ?? "确定"}</Button>}
                </div>
                : notes
                    ? <div className="notes" data-effect={params.effect}>
                        <ReactMarkdown
                            children={notes.html ?? ""}
                            components={{
                                code: ({ ...props }) => <CodeBlock {...props} />,
                                blockquote: BlockQuote,
                                img: ({ ...props }) => <ImageBlock {...props} />,
                                p: ({ ...props }) => <ParagraphBlock {...props} />,
                            }}
                            remarkPlugins={[gfm, remarkMath]}
                            rehypePlugins={[rehypeRaw, rehypeKatex]}
                        />
                    </div>
                    : <></>
                }
            </div>
        );
    }
});

export default StoryBoard;