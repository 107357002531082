import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { MarkdownCell } from "../markdown";
import { Notebook } from "../recipe";
import { materialService, notebookService, RecipeInfo, SubmissionInfo } from "../service";
import { PassportContext } from "../passport";
import { Dashboard } from "./dashboard";
import { CodeSubmission } from "./submission";
import { SectionTitle } from "./title";

interface CodeReviewParams {
    sections?: number[];
    prerequisites?: number[];
    lesson?: number;
}

const CodeReview: FunctionComponent<CodeReviewParams> = ({ sections, prerequisites, lesson }) => {
    const { passport } = useContext(PassportContext);
    const [submissions, setSubmissions] = useState<SubmissionInfo[]>();
    const [notebook, setNotebook] = useState<Notebook>();

    const getReview = useCallback(async (id: number) => {
        const response = await materialService().get(`/review/${id}`);

        switch (response.status) {
            case 200:
                const recipe = response.data as RecipeInfo;

                setNotebook(JSON.parse(recipe.content || "{\"cells\": []}") as Notebook);

                break;
            default:
                console.warn("unexpected behavior, please contact site admin");
        }
    }, []);

    const getCodeReview = useCallback(async (recipe: number) => {
        const response = await notebookService().get("/submission", {
            params: {
                username: passport.username,
                recipe: recipe,
                grade: true,
            }
        });

        switch (response.status) {
            case 200:
                return response.data as SubmissionInfo[];
            default:
                console.warn("unexpected behavior, please contact site admin");
                return [];
        }
    }, [passport.username]);

    const getAllCodeReviews = useCallback(async (recipes: number[]) => {
        console.log("Find all code review for recipes", recipes);

        const promises = recipes.map(recipe => getCodeReview(recipe));
        const reviews = await Promise.all(promises);
        const submissions = reviews.reduce((total, current) => [...total, ...current], []);

        setSubmissions(submissions);
    }, [getCodeReview]);

    useEffect(() => {
        if (sections && prerequisites && lesson) {
            getReview(lesson);
            getAllCodeReviews([...sections, ...prerequisites]);
        }
    }, [sections, prerequisites, lesson, getReview, getAllCodeReviews]);

    return (
        <>
            <SectionTitle title={"作业评审"} />

            {
                submissions?.length ?
                    notebook?.cells.map((cell, index) =>
                        cell.cell_type === "markdown" &&
                        <MarkdownCell key={index} source={cell.source} />
                    )
                    :
                    undefined
            }

            <h3 className="mt-4 mb-4">
                代码情况
            </h3>

            <Dashboard
                sections={sections}
                prerequisites={prerequisites}
                lesson={lesson}
            />

            {
                submissions?.length ?
                    <p>
                        以下是从你课内作业中挑选出来的代码片段以及评分：
                    </p>
                    :
                    <p>
                        代码提交后将由导师评审。
                    </p>
            }

            {
                submissions?.map(submission =>
                    <CodeSubmission
                        key={submission.id}
                        submission={submission}
                    />
                )
            }
        </>
    );
}

export { CodeReview };
