import { FunctionComponent, useContext, useState, useEffect, useCallback, useMemo, useRef } from "react";
import Image from "react-bootstrap/Image";
import * as StoryData from "./storydata";
import StorySubtitle from "./storysubtitle";
import StoryBoard from "./storyboard";
import { storyResourceUrl, StoryPreferenceContext, StoryResourcesContext, StoryProgressActionType, StoryProgressAction, StoryProgressContext, PlayingSoundsContext, PlayingSoundsActionType } from './storyshow';

interface Params {
    innerController?: boolean;
    eventIndex: number;
    content: StoryData.StoryEventType;
    playerEnded: boolean;
    previewMode?: boolean;
}

export const StoryEvent: FunctionComponent<Params> = (params) => {
    const currentStoryPreference = useContext(StoryPreferenceContext);
    const currentStoryResources = useContext(StoryResourcesContext);

    const [storyEvent] = useState(params.content);
    const [interactions, setInteractions] = useState<StoryData.StoryEventInteractionType[]>([]);
    const [eventScene, setEventScene] = useState<StoryData.StoryEventSceneType>();
    const [storyScene, setStoryScene] = useState<StoryData.StorySceneType>();
    const [sceneWidth, setSceneWidth] = useState(0);
    const [sceneHeight, setSceneHeight] = useState(0);
    const [currentMoment, setCurrentMoment] = useState(0);
    const [eventDuration, setEventDuration] = useState(0);
    const [eventResultIsRight, setEventResultIsRight] = useState(false);
    const [eventResult, setEventResult] = useState<number>(-2);
    const [eventSounds, setEventSounds] = useState<number[]>([]);
    const [currentSound, setCurrentSound] = useState<number>(-1);
    const [eventIndex] = useState<number>(params.eventIndex);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [soundsToPlay, setSoundsToPlay] = useState<string>();
    const [eventResumed, setEventResumed] = useState(false);
    const [eventPaused, setEventPaused] = useState(false);
    const [eventEnded, setEventEnded] = useState(false);
    const storyProgress = useContext(StoryProgressContext);
    const playingSounds = useContext(PlayingSoundsContext);
    const dispatchStoryProgress = useCallback((action: StoryProgressAction) => {
        storyProgress.dispatch(action);
    }, [storyProgress]);
    const playingSoundsHistory = useRef<number[]>([]);

    const onTimer = useCallback(() => {
        dispatchStoryProgress({ type: StoryProgressActionType.NEXT_STEP });
    }, [dispatchStoryProgress]);
    const getBoardSound = useCallback(() => storyEvent?.board?.voice ?? -1, [storyEvent]);
    const isBoardSoundPlaying = useCallback(() => {
        let boardSound = getBoardSound();
        return boardSound < 0 ? undefined : playingSounds.state.playing.includes(boardSound);
    }, [playingSounds, getBoardSound]);
    const onPlayerEnded = useCallback(() => {
        setCurrentSound(currentSound + 1);
    }, [currentSound]);
    const toggleSound = useCallback((i: number, p: boolean) => {
        setSoundsToPlay(p ? `${i}` : "");
        if (!p) {
            setEventPaused(true);
        } else {
            setEventResumed(true);
        }
    }, [setSoundsToPlay]);
    const onPlayingSounds = useCallback((sounds) => {
        playingSounds.dispatch({ type: PlayingSoundsActionType.SET, value: { playing: sounds } });
    }, [playingSounds]);
    const reset = useCallback(() => {
        setEventEnded(false);
        setEventResult(-2);
        setCurrentMoment(0);
    }, []);

    const storyBoard = useMemo(() => <StoryBoard
        rect={storyEvent.board?.rect ?? []}
        content={storyEvent.board?.content}
        type={storyEvent.board?.type}
        reset={eventResult === undefined || eventResult === 0}
        effect={storyEvent.board?.effect}
        interactive={true !== params.previewMode}
        isSoundPlaying={isBoardSoundPlaying()}
        onPlayerClicked={() => {
            toggleSound(getBoardSound(), !isBoardSoundPlaying);
        }}
        onExamSubmit={(examSelection) => {
            dispatchStoryProgress({ type: StoryProgressActionType.REDO_EVENT });
            dispatchStoryProgress({ type: StoryProgressActionType.RESUME });
            let result = 0;
            examSelection.forEach((e, i) => {
                result += (1 << e);
            });
            setEventResult(result);
        }}
    />, [storyEvent, eventResult, params.previewMode, isBoardSoundPlaying, getBoardSound, toggleSound, dispatchStoryProgress]);

    useEffect(() => {
        if (isActive && isPlaying) {
            setCurrentMoment(storyProgress.state.eventMoment);
        }
    }, [isActive, isPlaying, storyProgress.state.eventMoment]);

    useEffect(() => {
        let active2 = storyProgress.state.eventIndex === eventIndex;
        if (!active2) {
            reset();
        }
        setIsActive(active2);
    }, [eventIndex, storyProgress.state.eventIndex, reset]);

    useEffect(() => {
        setIsPlaying(isActive && !storyProgress.state.eventPaused);
    }, [isActive, storyProgress.state.eventPaused]);

    useEffect(() => {
        if (params.playerEnded) {
            onPlayerEnded();
        }
    }, [params.playerEnded, onPlayerEnded]);

    useEffect(() => {
        let eventDuration2 = !storyEvent || storyEvent.duration === "" ? 0 : StoryData.toSeconds(storyEvent.duration);
        setEventDuration(eventDuration2);
        let eventScene2: StoryData.StoryEventSceneType = typeof (storyEvent?.scene) === "number" || typeof (storyEvent?.scene) === "string" ? { index: storyEvent?.scene ?? "" } : { ...storyEvent?.scene };
        setEventScene(eventScene2);
        let scene2: StoryData.StorySceneType | undefined;
        if (typeof (eventScene2.index) === "number") {
            let storyScenesArray = currentStoryResources.state.scenes.additionalSets ?? [];
            if (eventScene2.index >= 0 && eventScene2.index < storyScenesArray.length) {
                scene2 = storyScenesArray[eventScene2.index];
            }
        } else if (eventScene2.index !== "") {
            scene2 = currentStoryResources.state.scenes.defaultSets[eventScene2.index];
        }
        setStoryScene(scene2);
        const sceneWidthDefault = currentStoryPreference.state.effectiveSceneWidth;
        let sceneWidth2 = eventScene2.width ? StoryData.numberOf(eventScene2.width) : sceneWidthDefault;
        if (0 < sceneWidth2 && sceneWidth2 <= 1) {
            sceneWidth2 = sceneWidth2 * sceneWidthDefault;
        }
        setSceneWidth(sceneWidth2);
        const sceneHeightDefault = currentStoryPreference.state.effectiveSceneHeight;
        let sceneHeight2 = eventScene2.height ? StoryData.numberOf(eventScene2.height) : sceneHeightDefault;
        if (0 < sceneHeight2 && sceneHeight2 <= 1) {
            sceneHeight2 = sceneHeight2 * sceneHeightDefault;
        }
        setSceneHeight(sceneHeight2);
    }, [storyEvent, currentStoryResources, currentStoryPreference]);

    useEffect(() => {
        let eventSounds2: number[] = [];
        interactions.forEach((interaction) => {
            let voiceIndex = interaction?.content?.voice ?? -1;
            if (voiceIndex >= 0) {
                eventSounds2.push(voiceIndex);
            }
        });
        if (eventSounds2.length !== eventSounds.length || eventSounds2.find((eventSound, index) => index >= eventSounds.length || eventSound !== eventSounds[index])) {
            setEventSounds(eventSounds2);
            setSoundsToPlay(undefined);
            setCurrentSound(-1);
            if (isPlaying && !eventEnded) {
                setEventResumed(true);
            }
        }
    }, [isPlaying, interactions, getBoardSound, eventEnded, eventSounds]);

    useEffect(() => {
        const rightResult = storyEvent?.getExamRightResult();
        setEventResultIsRight(eventResult === rightResult);
    }, [storyEvent, eventResult]);

    useEffect(() => {
        playingSoundsHistory.current = playingSounds.state.history ?? [];
    }, [playingSounds.state.history]);

    useEffect(() => {
        if (isPlaying && (storyEvent?.transition ?? "manual") !== "manual") {
            setEventResumed(true);
        }
        if (eventDuration > 0 && currentMoment >= eventDuration) {
            setEventEnded(true);
            return;
        }
        const interactionsEffective = storyEvent?.getInteractionsPresent(currentMoment);
        const history = playingSoundsHistory.current ?? [];
        const gotResult = (eventResult > -2 && eventResult !== 0);
        let interactions2 = interactionsEffective.filter(interaction => {
            if (interaction.onResult !== undefined) {
                return false;
            }
            if (interaction.content?.voice !== undefined && (gotResult || history.includes(interaction.content?.voice))) {
                return false;
            }
            return true;
        });
        if (true) {
            let interactionsOnResult = interactionsEffective
                .filter(interaction => {
                    const validResults = Array.isArray(interaction.onResult)
                        ? interaction.onResult
                        : [interaction.onResult ?? -1];
                    return validResults.includes(eventResult);
                });
            if (interactionsOnResult?.length === 0 && gotResult) {
                if (eventResult === storyEvent?.transition) {
                    setInteractions([]);
                    setEventEnded(true);
                    return;
                }
                interactionsOnResult = interactionsEffective.filter(interaction => interaction.onResult === -1);
            }
            interactions2 = [...interactions2, ...(interactionsOnResult ?? [])];
        }
        if (interactions2.length !== interactions.length || interactions2.find((interaction, index) => index >= interactions.length || interaction !== interactions[index])) {
            setInteractions(interactions2);
        }
    }, [isPlaying, isActive, storyEvent, eventDuration, currentMoment, eventResult, interactions]);

    useEffect(() => {
        if (!isPlaying) {
            return;
        }
        let currentVoice2 = currentSound + 1;
        let playList: number[] = currentVoice2 >= eventSounds.length ? [] : eventSounds.slice(currentVoice2, currentVoice2 + 1);
        let soundsToPlay2 = playList.join(",");
        setSoundsToPlay(soundsToPlay2);
        if (storyEvent?.duration === "" && playList.length === 0) {
            setCurrentSound(-1);
            // console.log("end current event due to no more voices for this event without duration specified");
            let eventResultIsFinal = eventResult === storyEvent?.transition;
            if (!storyEvent?.board?.hasHotspot() && (storyEvent?.board?.type !== "exam" || eventResultIsRight || eventResultIsFinal)) {
                setEventEnded(true);
            }
        }
    }, [isPlaying, storyEvent, eventResultIsRight, eventResult, currentSound, eventSounds]);

    useEffect(() => {
        if (isPlaying && soundsToPlay !== undefined) {
            onPlayingSounds(soundsToPlay);
            setSoundsToPlay(undefined);
        }
    }, [isPlaying, soundsToPlay, onPlayingSounds]);

    useEffect(() => {
        if (!isActive) {
            return;
        }
        if (eventResumed) {
            setEventResumed(false);
            dispatchStoryProgress({ type: StoryProgressActionType.RESUME });
        }
    }, [isActive, eventResumed, dispatchStoryProgress]);

    useEffect(() => {
        if (!isActive) {
            return;
        }
        if (eventPaused) {
            setEventPaused(false);
            dispatchStoryProgress({ type: StoryProgressActionType.PAUSE });
        }
    }, [isActive, eventPaused, dispatchStoryProgress]);

    useEffect(() => {
        if (!isActive) {
            return;
        }
        if (eventEnded) {
            setEventEnded(false);
            if ((storyEvent?.transition ?? "manual") !== "manual") {
                reset();
                dispatchStoryProgress({ type: StoryProgressActionType.NEXT_EVENT });
            } else {
                dispatchStoryProgress({ type: StoryProgressActionType.EXIT_EVENT });
            }
        }
    }, [isActive, eventEnded, storyEvent?.transition, reset, dispatchStoryProgress]);

    useEffect(() => {
        if (!isPlaying) {
            return;
        }
        let timer = setTimeout(onTimer, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [isPlaying, onTimer]);

    const {
        defaultSceneWidth,
        defaultSceneHeight,
        effectiveSceneWidth: currentSceneWidth,
        effectiveSceneHeight: currentSceneHeight,
        effectiveSceneWidthUnit: widthUnit,
        effectiveSceneHeightUnit: heightUnit,
    } = currentStoryPreference.state;
    const magnifiedRatio = true !== params.previewMode && !isActive ? 1 : currentStoryPreference.state.magnifiedRatio;
    const magnifiedRect = true !== params.previewMode && !isActive ? undefined : currentStoryPreference.state.magnifiedRect;
    const offsetLeft = (currentStoryPreference.state.sceneOffset?.left ?? 0) * magnifiedRatio;
    const offsetTop = (currentStoryPreference.state.sceneOffset?.top ?? 0) * magnifiedRatio;
    let sceneBackgroundMargin = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    }
    const storySceneMargin = storyScene?.margin;
    if (storySceneMargin && storySceneMargin.length > 0) {
        sceneBackgroundMargin.top = (storySceneMargin[0]);
        sceneBackgroundMargin.right = (storySceneMargin.length > 1 ? storySceneMargin[1] : sceneBackgroundMargin.top);
        sceneBackgroundMargin.bottom = (storySceneMargin.length > 2 ? storySceneMargin[2] : sceneBackgroundMargin.top);
        sceneBackgroundMargin.left = (storySceneMargin.length > 3 ? storySceneMargin[3] : sceneBackgroundMargin.right);
        const scaleWidth = currentSceneWidth / (defaultSceneWidth);
        const scaleHeight = currentSceneHeight / (defaultSceneHeight);
        sceneBackgroundMargin.top *= scaleHeight;
        sceneBackgroundMargin.bottom *= scaleHeight;
        sceneBackgroundMargin.left *= scaleWidth;
        sceneBackgroundMargin.right *= scaleWidth;
    }
    return (
        <div className="storySceneOuter" style={{
            margin: "0 auto",
            position: "relative",
            width: params.previewMode ? `${currentSceneWidth * magnifiedRatio}${widthUnit}` : `${currentSceneWidth}${widthUnit}`,
            height: params.previewMode ? `${currentSceneHeight * magnifiedRatio}${heightUnit}` : `${currentSceneHeight}${heightUnit}`,
            // transform: previewScale !== 1 ? `translate(-50%, -50%) scale(${previewScale}, ${previewScale})` : undefined
        }}>
            <div className="storySceneFrame" style={{
                left: !params.previewMode ? undefined : `${offsetLeft}${widthUnit}`,
                top: !params.previewMode ? undefined : `${offsetTop}${heightUnit}`,
                width: `${sceneWidth}${widthUnit}`,
                height: `${sceneHeight}${heightUnit}`,
                backgroundColor: eventScene?.bgColor,
                transform: !magnifiedRect ? `scale(1, 1) translate(0, 0)` : `scale(${magnifiedRatio}, ${magnifiedRatio}) translate(${params.previewMode ? 0 : offsetLeft / magnifiedRatio}${widthUnit}, ${params.previewMode ? 0 : offsetTop / magnifiedRatio}${heightUnit})`,
                transitionProperty: "transform",
                transitionDuration: "1s",
            }}>
                {!storyScene ? null : <Image className="storyScene" src={storyResourceUrl(storyScene?.background)} alt={storyScene?.headline}
                    style={{
                        width: `calc(100% - ${sceneBackgroundMargin.left}px - ${sceneBackgroundMargin.right}px)`,
                        height: `calc(100% - ${sceneBackgroundMargin.top}px - ${sceneBackgroundMargin.bottom}px)`,
                        marginTop: sceneBackgroundMargin.top + "px",
                        marginRight: sceneBackgroundMargin.right + "px",
                        marginBottom: sceneBackgroundMargin.bottom + "px",
                        marginLeft: sceneBackgroundMargin.left + "px",
                    }}
                />}
                {!storyEvent?.board ? null : (
                    storyBoard
                )}
                {storyEvent?.objects?.map((object, objectIndex) => {
                    if (!storyEvent?.isObjectPresent(object, currentMoment)) {
                        return null;
                    }
                    const playList = playingSounds.state.waiting.length > 0 ? [...playingSounds.state.waiting] : [...playingSounds.state.playing];
                    let interactionsEffective =
                        interactions.filter((interaction, interactionIndex) => {
                            if (interaction.actor !== objectIndex) {
                                return false;
                            }
                            return storyEvent?.isInteractionPresent(interaction, currentMoment);
                        });
                    const isNobody = object.name === "";
                    const character = currentStoryResources.state.characters[object.name];
                    if (!isNobody && (character?.type ?? "person") === "person" && interactionsEffective.length === 0) {
                        interactionsEffective.push({
                            start: "", duration: "", actor: objectIndex
                        });
                    }
                    return (
                        <div key={`storyCharacter.${objectIndex}`}>
                            {interactionsEffective.map((interaction, interactionIndex) => {
                                const objectTalk = interaction?.content ?? null;
                                const motions = character?.motion ?? [undefined];
                                const motion = (typeof (interaction?.motion) === "number" ? motions[interaction?.motion ?? 0] : interaction?.motion as StoryData.StoryCharacterMotionType);
                                const figures = {
                                    always: {
                                        list: [...motion?.always ?? [], { index: interaction?.figure ?? 0 }],
                                        length: motion?.always?.length ?? 0,
                                    },
                                    mouseoverfigure: {
                                        list: motion?.mouseoverfigure ?? [],
                                        length: motion?.mouseoverfigure?.length ?? 0,
                                    },
                                    mouseoversubtitle: {
                                        list: motion?.mouseoversubtitle ?? [],
                                        length: motion?.mouseoversubtitle?.length ?? 0,
                                    }
                                };
                                const figure = character?.figure[figures.always.list[0].index];
                                const fullSize = (isNobody ? [sceneWidth, sceneHeight] : figure?.size ?? [0, 0]).map((x, i) => x * [currentSceneWidth / defaultSceneWidth, currentSceneHeight / defaultSceneHeight][i]);
                                const halfSize = fullSize.map(x => x * 0.5);
                                let position = interaction?.position ?? object.position;
                                if (!position) {
                                    const positionsList = storyScene?.positions ?? [];
                                    const positionIndex = object.location ?? [];
                                    position = [StoryData.getPosition(positionsList, positionIndex, 0), StoryData.getPosition(positionsList, positionIndex, 1)];
                                }
                                const targetLeft = isNobody ? 0 : position[0] * sceneWidth - halfSize[0];
                                const targetBottom = isNobody ? 0 : position[1] * sceneHeight;

                                const voiceIndex = objectTalk?.voice ?? -1;
                                const isPlaying = playList.includes(voiceIndex);

                                const motionTransitionDuration = 20;
                                var timeout: NodeJS.Timeout | undefined;
                                const updateFigure = (target: HTMLElement, list: {
                                    index: number;
                                    duration?: number | undefined;
                                    randomOffset?: number | undefined;
                                }[], current: number) => {
                                    target.setAttribute("data-current", (current).toString());
                                    let img = target.querySelector("img") as HTMLImageElement;
                                    img.style.opacity = "0";
                                    let img2 = img.cloneNode() as HTMLImageElement;
                                    img2.onload = (evt) => { onLoadFigure(target); };
                                    img.parentNode?.prepend(img2);
                                    const currentFigure = character?.figure[list[current].index];
                                    const offsetCenter = currentFigure?.offsetCenter ?? [0, 0];
                                    img2.src = currentFigure.data ?? "";
                                    img2.style.opacity = "1";
                                    img2.style.transform = `translateX(${-offsetCenter * currentSceneWidth / defaultSceneWidth}px)`;
                                    setTimeout(() => {
                                        img.remove();
                                    }, motionTransitionDuration);
                                };
                                const onLoadFigure = (target: HTMLElement | null) => {
                                    if (params.previewMode === true) {
                                        return;
                                    }
                                    // const target = evt.currentTarget.parentElement;
                                    if (!target) return;
                                    if (target.getAttribute("data-changing")) {
                                        figureChange(target);
                                    } else if (figures.always.length > 1) {
                                        target.setAttribute("data-changing", "always")
                                        figureChange(target);
                                    }
                                };
                                const figureChange = (target: HTMLElement) => {
                                    const a = (function (changing: string | null) {
                                        switch (changing) {
                                            case "mouseoversubtitle": return figures.mouseoversubtitle;
                                            case "mouseoverfigure": return figures.mouseoverfigure;
                                            default: return figures.always;
                                        }
                                    })(target.getAttribute("data-changing"));
                                    let current = parseInt(target.getAttribute("data-current") ?? "0");
                                    const currentFigure = a.list[current++];
                                    let duration = currentFigure?.duration ?? -1;
                                    if (duration < 0) {
                                        return;
                                    }
                                    duration += (Math.random() * 2 - 1) * (currentFigure?.randomOffset ?? 0)
                                    if (current >= a.length) {
                                        current = 0;
                                    }
                                    timeout && clearTimeout(timeout);
                                    timeout = setTimeout(() => {
                                        updateFigure(target, a.list, current);
                                    }, duration * 1000);
                                };
                                return (
                                    <div key={`storyInteraction.${interactionIndex}`} className={objectTalk && isNobody ? "" : "storyCharacter"}
                                        style={{
                                            left: targetLeft + currentStoryPreference.state.effectiveSceneWidthUnit,
                                            bottom: targetBottom + currentStoryPreference.state.effectiveSceneHeightUnit,
                                        }}>
                                        <StorySubtitle
                                            actionTalk={objectTalk}
                                            targetLeft={targetLeft}
                                            targetBottom={targetBottom}
                                            sceneWidth={sceneWidth}
                                            sceneHeight={sceneHeight}
                                            followTarget={false}
                                            targetSize={fullSize}
                                            start={interaction?.start ?? ""}
                                            duration={interaction?.duration ?? ""}
                                            emoticon={interaction?.emoticon}
                                            previewMode={params.previewMode}
                                            onClose={() => {
                                                if (eventResultIsRight) {
                                                    dispatchStoryProgress({ type: StoryProgressActionType.NEXT_EVENT });
                                                }
                                            }}
                                            closeButton={eventResult === undefined ? undefined : eventResultIsRight ? "继续" : "重试"}
                                            isSoundPlaying={voiceIndex < 0 ? undefined : isPlaying}
                                            onPlayerClicked={() => {
                                                toggleSound(voiceIndex, !isPlaying);
                                            }}
                                            onMouseOut={(evt) => {
                                                if (params.previewMode === true || figures.mouseoversubtitle.length === 0) {
                                                    return;
                                                }
                                                timeout && clearTimeout(timeout);
                                                const target = evt.currentTarget.parentElement?.querySelector(".characterFigure") as HTMLElement;
                                                if (figures.always.length > 1) {
                                                    target.setAttribute("data-changing", "always");
                                                } else {
                                                    target.removeAttribute("data-changing");
                                                }
                                                updateFigure(target, figures.always.list, 0);
                                            }}
                                            onMouseOver={(evt) => {
                                                if (params.previewMode === true || figures.mouseoversubtitle.length === 0) {
                                                    return;
                                                }
                                                timeout && clearTimeout(timeout);
                                                const target = evt.currentTarget.parentElement?.querySelector(".characterFigure") as HTMLElement;
                                                if (!target) return;
                                                target.setAttribute("data-changing", "mouseoversubtitle");
                                                updateFigure(target, figures.mouseoversubtitle.list, 0);
                                            }}
                                        />{isNobody || (params.previewMode !== true && eventIndex !== storyProgress.state.eventIndex) ? null :
                                            <div className="characterFigure"
                                                style={{
                                                    width: fullSize[0] + currentStoryPreference.state.effectiveSceneWidthUnit,
                                                    height: fullSize[1] + currentStoryPreference.state.effectiveSceneHeightUnit,
                                                    transform: interaction?.transform ?? object.transform ?? "none",
                                                }}
                                                onMouseOut={(evt) => {
                                                    if (params.previewMode === true || figures.mouseoverfigure.length === 0) {
                                                        return;
                                                    }
                                                    timeout && clearTimeout(timeout);
                                                    const target = evt.currentTarget;
                                                    if (figures.always.length > 1) {
                                                        target.setAttribute("data-changing", "always");
                                                    } else {
                                                        target.removeAttribute("data-changing");
                                                    }
                                                    updateFigure(target, figures.always.list, 0);
                                                }}
                                                onMouseOver={(evt) => {
                                                    if (params.previewMode === true || figures.mouseoverfigure.length === 0) {
                                                        return;
                                                    }
                                                    timeout && clearTimeout(timeout);
                                                    const target = evt.currentTarget;
                                                    target.setAttribute("data-changing", "mouseoverfigure");
                                                    updateFigure(target, figures.mouseoverfigure.list, 0);
                                                }}
                                            ><Image alt={object.name} src={figure?.data ?? storyResourceUrl(figure?.source)}
                                                style={{
                                                    transitionProperty: "opacity",
                                                    transitionDuration: `${motionTransitionDuration}ms`,
                                                    transform: `translateX(${-(figure?.offsetCenter ?? [0, 0])[0] * currentSceneWidth / defaultSceneWidth}px)`
                                                }}
                                                onLoad={(evt) => { onLoadFigure(evt.currentTarget.parentElement) }} />
                                            </div>}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div >
    );
}

export default StoryEvent;