export const defaultScenes = {
    "0bd6c33e-31eb-4083-8dd8-ee07837bc975": {
        "background": "/story/scenes/backgrounds/concentrak.svg"
    },
    "5cdab8ba-c028-45ea-87cc-b0d75dd81e10": {
        "background": "/story/scenes/backgrounds/single-pale.png"
    },
    "6b375a24-1e62-4c09-a89d-948f6674ab1b": {
        "background": "/story/scenes/backgrounds/single-purple.png"
    },
    "55271010-c8a0-4223-81ac-3a4e789a1527": {
        "background": "/story/scenes/backgrounds/split-horizon-blue-green.png"
    },
    "6adf10ab-85be-4baa-a64c-55a36e1a8b75": {
        "background": "/story/scenes/backgrounds/split-horizon-green-pale.png"
    },
    "616ef2c6-538c-4f73-a605-ace24a95fe35": {
        "background": "/story/scenes/backgrounds/whiteboard.svg",
        "margin": [20, 50]
    },
    "f33caeeb-2737-47fc-9b9b-8ad73c968934": {
        "background": "/story/scenes/backgrounds/storyboard-backgroud-indoor-green.svg",
        "positions": [[0.2, 0.8], [0.1, 0.5]],
        "headline": "Default 1"
    },
    "294d4919-28d9-4c19-a2b4-e6e374b99edb": {
        "background": "/story/scenes/backgrounds/storyboard-backgroud-blackboard-green.svg",
        "positions": [[0.15, 0.85], [0, 1]],
        "headline": "Default 2"
    }
};

export const defaultPopups = [
    {
        "padding": [
            25
        ],
        "tailSize": 50,
        "maxWidth": 480,
        "fontSize": "32px",
        "textAlign": "left",
        "direction": "right",
        "bgImage": "/story/popups/storyboard-dialog-square-white.svg"
    },
    {
        "padding": [
            5
        ],
        "fullSize": true,
        "fontSize": "24px",
        "fontColor": "white",
        "bgColor": "rgba(0, 0, 58, 1)"
    },
    {
        "padding": [
            5
        ],
        "fullSize": true,
        "fontSize": "24px",
        "fontColor": "white",
        "emoticon": {
            "image": "/story/emoticons/emoticon_2.svg",
            "sound": "/story/audios/97fbbe9f-96b5-47c9-a39c-e3c57710da7f.wav",
            "direction": "up",
            "transform": ""
        },
        "bgColor": "transparent"
    },
    {
        "padding": [
            5
        ],
        "fullSize": true,
        "fontSize": "24px",
        "fontColor": "white",
        "emoticon": {
            "image": "/story/emoticons/emoticon_1.svg",
            "sound": "/story/audios/0322f34b-2e82-4e95-8dc0-6da8f2f39221.wav",
            "direction": "down",
            "transform": "rotate(180deg)"
        },
        "bgColor": "rgba(0, 0, 58, 1)"
    },
    {
        "direction": "FIXED",
        "rect": [
            0,
            -1,
            1,
            -1
        ],
        "fontSize": "24px",
        "fontColor": "white",
        "bgColor": "rgba(0, 0, 0, 0.25)"
    },
    {
        "padding": [
            50
        ],
        "tailSize": 50,
        "maxWidth": 480,
        "fontSize": "96px",
        "textAlign": "center",
        "direction": "right",
        "bgImage": "/story/popups/storyboard-dialog-square-white.svg"
    },
    {
        "padding": [
            5
        ],
        "direction": "FIXED",
        "rect": [
            0.15,
            0.25,
            0.7,
            0.5
        ],
        "fontSize": "72px",
        "fontColor": "black",
        "textAlign": "center",
        "bgColor": "transparent"
    },
];

export const defaultCharacters = {
    "wrinkly-man": {
        "figure": [
            {
                "source": "/story/characters/figures/wrinkly-man-stand-hand1onhip-smile.svg",
                "size": [163.656148, 400],
                "type": "svg",
                "offsetCenter": [-6.528835691, 0]
            },
            {
                "source": "/story/characters/figures/wrinkly-man-stand-hand1onhip-blink.svg",
                "size": [164.0130862, 400],
                "type": "svg",
                "offsetCenter": [-6.543075245, 0]
            },
            {
                "source": "/story/characters/figures/wrinkly-man-stand-fist2up-blink.svg",
                "size": [187.888707, 400],
                "type": "svg",
                "offsetCenter": [2.073104201, 0]
            },
            {
                "source": "/story/characters/figures/wrinkly-man-stand-hand1onhip-morose.svg",
                "size": [164.1025641, 400],
                "type": "svg",
                "offsetCenter": [-6.546644845, 0]
            },
            {
                "source": "/story/characters/figures/wrinkly-man-stand-hand1onhip-morosesay.svg",
                "size": [164.1025641, 400],
                "type": "svg",
                "offsetCenter": [-6.546644845, 0]
            }
        ]
    },
    "youth-man": {
        "figure": [
            {
                "source": "/story/characters/figures/youth-man-stand-finger1up-smile.svg",
                "size": [167.0, 400],
                "type": "svg",
                "offsetCenter": [-7.754859611, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-half-skirtORANGE-thumbup-smilesay.svg",
                "size": [400.0, 400],
                "type": "svg"
            },
            {
                "source": "/story/characters/figures/youth-man-stand-thumbup-blink.svg",
                "size": [200.1, 400],
                "type": "svg",
                "offsetCenter": [23.33779698, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-fist1up-smilesay.svg",
                "size": [161.6335304, 400],
                "type": "svg",
                "offsetCenter": [7.092960774, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-fist1up-blinksay.svg",
                "size": [162.0689655, 400],
                "type": "svg",
                "offsetCenter": [7.112068966, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-finger1up-blinksay.svg",
                "size": [164.0086207, 400],
                "type": "svg",
                "offsetCenter": [7.004310345, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-legcrossing-finger1up-smilesay.svg",
                "size": [163.5679742, 400],
                "type": "svg",
                "offsetCenter": [6.985491671, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-half-skirtORANGE-thumbup-blinksay.svg",
                "size": [400, 400],
                "type": "svg"
            },
            {
                "source": "/story/characters/figures/youth-man-stand-armed-hottentotsmask.svg",
                "size": [186.746087, 442.8],
                "type": "svg",
                "offsetCenter": [6.095017182, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-armed-fist1up-hottentotsmask.svg",
                "size": [185.0347826, 442.8],
                "type": "svg",
                "offsetCenter": [4.598265896, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-normal-smilesay.svg",
                "size": [164.7058824, 400],
                "type": "svg",
                "offsetCenter": [-5.561298701, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-normal-groan.svg",
                "size": [163.9169771, 400],
                "type": "svg",
                "offsetCenter": [-5.534285714, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-half-normal-blinklaugh.svg",
                "size": [167.9538165, 240],
                "type": "svg"
            },
            {
                "source": "/story/characters/figures/youth-man-stand-fist1up-groan.svg",
                "size": [161.3660619, 400],
                "type": "svg",
                "offsetCenter": [-7.258730159, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-normal-complain.svg",
                "size": [164.7058824, 400],
                "type": "svg",
                "offsetCenter": [-5.347402597, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-thumbup-blinksay.svg",
                "size": [199.6782842, 400],
                "type": "svg",
                "offsetCenter": [-23.05880773, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-normal-blinkgrin.svg",
                "size": [164.7058824, 400],
                "type": "svg",
                "offsetCenter": [-5.347402597, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-thumbup-smilesay.svg",
                "size": [197.6520811, 400],
                "type": "svg",
                "offsetCenter": [-23.05788337, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-ontiptoe-fist1up-groan.svg",
                "size": [160.0851517, 400],
                "type": "svg",
                "offsetCenter": [-6.812765957, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-ontiptoe-fist1up-laugh.svg",
                "size": [160.0851517, 400],
                "type": "svg",
                "offsetCenter": [-6.812765957, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-fist1up-complain.svg",
                "size": [165.1474531, 400],
                "type": "svg",
                "offsetCenter": [-7.29012987, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-legcrossing-skirtORANGE-finger1up-laugh.svg",
                "size": [160.855615, 400],
                "type": "svg",
                "offsetCenter": [-6.846808511, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-skirtORANGE-fist1up-laugh.svg",
                "size": [161.0723861, 400],
                "type": "svg",
                "offsetCenter": [-7.186218375, 200]
            },
            {
                "source": "/story/characters/figures/youth-man-stand-skirtORANGE-fist1up-smilesay.svg",
                "size": [164.7058824, 400],
                "type": "svg",
                "offsetCenter": [-5.561298701, 200]
            }
        ]
    },
    "youth-woman": {
        "figure": [
            {
                "source": "/story/characters/figures/youth-woman-stand-handsonhip-grin.svg",
                "size": [125.8, 380],
                "type": "svg",
                "offsetCenter": [-8, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-finger1up-grin-captain.svg",
                "size": [126.6633317, 400],
                "type": "svg",
                "offsetCenter": [2.101050525, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-normal-grin-captain.svg",
                "size": [138.4692346, 400],
                "type": "svg",
                "offsetCenter": [0.60030015, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-handsonhip-groan-captain.svg",
                "size": [114.940239, 400],
                "type": "svg",
                "offsetCenter": [-5.278884462, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-finger1up-blink-captain.svg",
                "size": [126.4103844, 400],
                "type": "svg",
                "offsetCenter": [2.096854718, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-tshirtBLACKtrousersDARK-finger1up-blinksay.svg",
                "size": [111.9, 380],
                "type": "svg",
                "offsetCenter": [9.710221286, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-tshirtBLACKtrousersDARK-finger1up-say.svg",
                "size": [111.4, 380],
                "type": "svg",
                "offsetCenter": [9.664394337, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-armed-hottentotsmask.svg",
                "size": [191.9852245, 414.6],
                "type": "svg",
                "offsetCenter": [-28.32732979, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-armed-finger1up-hottentotsmask.svg",
                "size": [191.7850314, 414.6],
                "type": "svg",
                "offsetCenter": [-30.62955094, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-normal-smilesay.svg",
                "size": [139.5, 380],
                "type": "svg",
                "offsetCenter": [-1.301369863, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-finger1up-smilesay.svg",
                "size": [111.9, 380],
                "type": "svg",
                "offsetCenter": [9.710221286, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-finger1up-groan.svg",
                "size": [111.9, 380],
                "type": "svg",
                "offsetCenter": [9.710221286, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-normal-laugh.svg",
                "size": [141.9, 380],
                "type": "svg",
                "offsetCenter": [-1.323687031, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-handsonhip-blinksmile.svg",
                "size": [119.3, 380],
                "type": "svg",
                "offsetCenter": [-6.807486631, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-handsonhip-complain.svg",
                "size": [118.4, 380],
                "type": "svg",
                "offsetCenter": [-6.756900212, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-finger1up-blinksmile.svg",
                "size": [111.4, 380],
                "type": "svg",
                "offsetCenter": [9.664394337, 0]
            },
            {
                "source": "/story/characters/figures/youth-woman-stand-normal-bemused.svg",
                "size": [139.5, 380],
                "type": "svg",
                "offsetCenter": [-1.301369863, 0]
            }
        ]
    },
    "beard-man": {
        "figure": [
            {
                "source": "/story/characters/figures/beard-man-stand-fist1up-smile.svg",
                "size": [158.7, 400],
                "type": "svg",
                "offsetCenter": [7.213706041, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-thumbup-smile.svg",
                "size": [198.2, 400],
                "type": "svg",
                "offsetCenter": [-22.88451304, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-half-painting-cry.svg",
                "size": [350.5, 400],
                "type": "svg",
                "offsetCenter": [-41.03844234, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-fist1up-complain.svg",
                "size": [158.7, 400],
                "type": "svg",
                "offsetCenter": [-7.078711041, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-normal-grimace.svg",
                "size": [174.9, 400],
                "type": "svg",
                "offsetCenter": [0.213333333, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-fist1up-blink.svg",
                "size": [161.7, 400],
                "type": "svg",
                "offsetCenter": [-7.272727273, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-thumbup-forcedsmile.svg",
                "size": [199.0, 400],
                "type": "svg",
                "offsetCenter": [-22.99838796, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-skirtBLACKtrousersGRAY-normal-blinksay.svg",
                "size": [162.6, 400],
                "type": "svg",
                "offsetCenter": [-4.883227176, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-half-skirtBLACK-hand1up-blinksay.svg",
                "size": [376.7, 400],
                "type": "svg",
                "offsetCenter": [-50.9964231, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-skirtBLACKtrousersGRAY-normal-smile.svg",
                "size": [162.6, 400],
                "type": "svg",
                "offsetCenter": [-4.883227176, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-half-skirtBLACK-thumbup-blinksay.svg",
                "size": [358.7, 400],
                "type": "svg",
                "offsetCenter": [-42.00306592, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-skirtBLACKtrousersGRAY-fist1up-smile.svg",
                "size": [159.4, 400],
                "type": "svg",
                "offsetCenter": [-7.112526539, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-skirtBLACKtrousersGRAY-normal-crysay.svg",
                "size": [161.9, 400],
                "type": "svg",
                "offsetCenter": [-5.257623554, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-stand-skirtBLACKtrousersGRAY-fist1up-blinksay.svg",
                "size": [160.9, 400],
                "type": "svg",
                "offsetCenter": [-7.237892496, 0]
            },
            {
                "source": "/story/characters/figures/beard-man-half-skirtBLACK-fist1up-blinksay.svg",
                "size": [369.1, 400],
                "type": "svg",
                "offsetCenter": [-47.01073071, 0]
            }
        ]
    },
    "Luke Skywalker": {
        "figure": [
            {
                "source": "/story/characters/figures/skywalker-luke-stand-normal-smile.svg",
                "size": [164.7, 400],
                "type": "svg"
            }
        ]
    },
    "Obiwan Kenobi": {
        "figure": [
            {
                "source": "/story/characters/figures/obiwan-kenobi-stand-normal-smile.svg",
                "size": [261.2, 400],
                "type": "svg"
            }
        ]
    },
    "Darth Vader": {
        "figure": [
            {
                "source": "/story/characters/figures/darth-vader-stand-normal-sword.svg",
                "size": [461.1, 400],
                "type": "svg"
            }
        ]
    },
    "CrystalBall": {
        "figure": [
            {
                "source": "/story/characters/figures/property-crystal-ball-skyblue.svg",
                "size": [90, 100],
                "type": "svg"
            }
        ]
    },
    "PackageToyota": {
        "figure": [
            {
                "source": "/story/characters/figures/property-package-toyota-barcode.svg",
                "size": [300, 150],
                "type": "svg"
            },
            {
                "source": "/story/characters/figures/property-package-toyota-grid.svg",
                "size": [300, 150],
                "type": "svg"
            },
            {
                "source": "/story/characters/figures/property-package-toyota-QR.svg",
                "size": [300, 150],
                "type": "svg"
            },
            {
                "source": "/story/characters/figures/property-package-toyota-QR-smeared.svg",
                "size": [300, 150],
                "type": "svg"
            }
        ]
    },
    "ePay": {
        "figure": [
            {
                "source": "/story/characters/figures/property-e-pay-wechat.svg",
                "size": [200, 400],
                "type": "svg"
            },
            {
                "source": "/story/characters/figures/property-e-pay.svg",
                "size": [300, 150],
                "type": "svg"
            },
            {
                "source": "/story/characters/figures/property-e-pay-mix-picture.svg",
                "size": [150, 300],
                "type": "svg"
            }
        ]
    },
    "balls": {
        "figure": [
            {
                "source": "/story/posters/balls-black1white1.svg",
                "size": [50, 50],
                "type": "svg"
            }
        ]
    },
    "none": {}
};
