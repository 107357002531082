import { FunctionComponent, useContext, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsQuestion } from "react-icons/bs";
import useSound from "use-sound";
import { AssistantContext, DifficultyLevel } from "../assistant";
import { MarkdownCell } from "../markdown";
import { PassportContext, RoleName } from "../passport";
import { RecipeContext } from "../recipe";

interface Params {
    source: Array<string>;
}

export const ExplainCommand: FunctionComponent<Params> = ({ source }) => {
    const [play] = useSound("https://resource.codemage.cn/pop.mp3");

    const { assistant } = useContext(AssistantContext);
    const { passport } = useContext(PassportContext);
    const { recipe } = useContext(RecipeContext);

    const [show, setShow] = useState(false);

    const executable = passport.roles.includes(RoleName.CODER);

    const visible = (): boolean => {
        if (assistant.explanation && source?.length > 0) {
            // tutor can always check the hints

            if (passport.roles.includes(RoleName.TUTOR)) {
                return true;
            }

            // student can not check the hint in hard mode

            switch (assistant.difficulty) {
                case DifficultyLevel.EASY:
                    return true;
                case DifficultyLevel.NORMAL:
                    return true;
                case DifficultyLevel.HARD:
                    return recipe.achieved; // user can check the answers only when the recipe is achieved
            }
        } else {
            return false;
        }
    }

    return (
        visible() ?
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="explanation">提示答案</Tooltip>}
                >
                    <Button
                        variant="light"
                        type="button"
                        size="sm"
                        onClick={() => setShow(true)}
                        className="ms-1"
                        disabled={!executable}
                        onMouseDown={() => assistant.sound && play()}
                    >
                        <BsQuestion size="20" />
                    </Button>
                </OverlayTrigger>

                <Modal show={show} onHide={() => setShow(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>提示</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MarkdownCell
                            source={source}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)}>
                            关闭
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            :
            <></>
    );
}

