import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { materialService, StudyInfo } from "../service";

export const StudyList: FunctionComponent = () => {
    const [studies, setStudies] = useState<StudyInfo[]>();

    useEffect(() => {
        console.log("Loading studies");

        // fetch studies from backend
        const getStudies = async () => {
            const response = await materialService().get("/study");

            switch (response.status) {
                case 200:
                    const result = response.data as StudyInfo[];
                    setStudies(result);
                    break;

                default:
                    console.warn("unexpected behavior, please contact site admin");
                    break;
            }
        }
        getStudies();

    }, []);

    return (
        <Container>
            <CardGroup>
                <Row className="row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3">
                    {
                        studies?.map((study, index) =>
                            <Col key={study.id}>
                                <Card
                                    key={index}
                                    className="mt-4"
                                    style={{ minWidth: "18rem", maxWidth: "22rem" }}>
                                    <Card.Img variant="top" src={study.thumbnail} style={{ objectFit: "cover" }} />
                                    <Card.Body>
                                        <Card.Title>
                                            <Link to={`/study/${study.id}`} style={{ textDecoration: "none" }}>
                                                {study.title}
                                            </Link>
                                        </Card.Title>
                                        <Card.Text>
                                            {study.summary}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }
                </Row>
            </CardGroup>
        </Container>
    )
}