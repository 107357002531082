import { FunctionComponent, useContext } from "react";
import { Card, CardGroup, Col, Container, OverlayTrigger, Placeholder, Row, Tooltip } from "react-bootstrap";
import { ExecutionContext } from "./executioncontext";

enum ExamIcon {
    UNKNOWN = "https://resource.codemage.cn/grader-unknown-flat.svg",
    PASS = "https://resource.codemage.cn/grader-pass-flat.svg",
    FAIL = "https://resource.codemage.cn/grader-fail-flat.svg",
}

export const ExamDetail: FunctionComponent = () => {
    const { execution } = useContext(ExecutionContext);

    const unknown = () => {
        const size = execution.grader?.length || 10;

        return [...Array(size)].map((n, i) =>
            <Col key={i} className="mt-2 px-1">
                <Card key={i}>
                    <Card.Img variant="top" src={ExamIcon.UNKNOWN} />
                    <Card.Body>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={6} />
                        </Placeholder>
                        <Placeholder as={Card.Text} animation="glow">
                            <Placeholder xs={7} />
                            <Placeholder xs={4} />
                        </Placeholder>
                    </Card.Body>
                </Card>
            </Col>
        );
    };

    const result = () => {
        const output = execution.outputs[0].text;
        const cases = JSON.parse(output) as [pass: boolean, time: number][];

        return cases.map(([pass, time], i) =>
            <Col key={i} className="mt-2 px-1">
                <Card key={i}>
                    <Card.Img variant="top" src={pass ? ExamIcon.PASS : ExamIcon.FAIL} />
                    <Card.Body>
                        <Card.Title className="d-flex justify-content-center">
                            {i + 1}
                        </Card.Title>
                        <Card.Text className="d-flex justify-content-center">
                            <OverlayTrigger
                                key={i}
                                placement={"top"}
                                overlay={
                                    <Tooltip>
                                        {time === -1 ? "超时" : `耗时 ${time} 毫秒`}
                                    </Tooltip>
                                }
                            >
                                <span>{time === -1 ? "♾️" : time}</span>
                            </OverlayTrigger>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        );
    };

    return (
        <Container>
            <CardGroup className="px-0 mx-0 mt-3">
                <Row className="row-cols-12 row-cols-xs-6 row-cols-sm-12 row-cols-md-12">
                    {
                        execution.outputs.length > 0 ? result() : unknown()
                    }
                </Row>
            </CardGroup>
        </Container>
    );
};
